import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Badge, Button, Container, Form, Col, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as G from '../components/global'
import '../styles/glob.css';
import MessageBox from '../components/MessageBox';
import history from '../components/history';
import Autocomplete from '../components/otherComponents/Autocomplete';
import ReactPaginate from 'react-paginate';
import { translateScreen as ts, translateScreen2 as ts2, translateDate as td } from '../components/dictionary'

class profileManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allList: [],
            originalList: [],
            displayedList: [],
            allCompanies: [],
            activeCompany: [],
            fields: {},
            errors: {},
            result: [],
            activeScreen: 0,
            birthdate: this.addDays(new Date(), +365 * 28 * -1),
            offset: 0,
            perPage: 9,
            currentPage: 0,
            pageCount: 0,
            editProfile: -1,
            deleteProfile: -1,
            modalShow: false,
            modalBody: '',
            modalSubject: '',
            loadCmp: true,
            loadprofiles: false,
            loadSave: false,
            //filters
            CountryList: [],
            CityList: [],
            categoriesList:[],
            availableGender: [],
            availableSubs:[],
            filteredCountryList: [],
            filteredCategoriesList: [],
            filteredCityList: [],
            profileStats:{}
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleUpdateAutoComplete = this.handleUpdateAutoComplete.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
    }
    //#region Didmount & Get Data & Filter
    componentDidMount() {
        this.refreshDataClients()
        let date = new Date();
        date.setFullYear(date.getFullYear - 18)
        history.listen((location, action) => {
            if(location.pathname == "/ProfileManagement" || location.pathname == "/ProfileManagement/")
                this.setState({ activeScreen: 0 });
            else if(location.pathname == "/ProfileManagement/customers" || location.pathname == "/ProfileManagement/customers/")
            this.setState({ activeScreen: 1 });
          });
    }
    languageArray = () => { let lang = G.getLanguageList(); return lang }
    dateFormat = require("dateformat");
    addDays = (date, days) => {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }
    renderGrid = (name, Data) => {
    

        return (
    
            <div className='fullWidth '>
                {/* {typeID == 0 && <p className='  ssstitle bold ml-3 mt-3 mb-2   '>{name} </p>} */}
                <div>
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th >{name}</th>
                                <th colSpan="2" className='cunlayer' >{ts('Reached',this.props.userLogin.language  )}</th>
    
                                <th colSpan="2" >{ts('Not Reached',this.props.userLogin.language  )}</th>
    
                                <th colSpan="2" className='cunlayer'>{ts('Opened',this.props.userLogin.language  )}</th>
    
                                <th colSpan="2">{ts('Not Opened',this.props.userLogin.language  )}</th>
    
    
                            </tr>
                        </thead>
                        <tbody>
                            
                                
                                    <tr>
    
                                        <td className='bold'>{Data.total}</td>
                                        <td className='cunlayer'>
                                        {Data.reached}
                                        </td >
                                        <OverlayTrigger overlay={<Tooltip >Percentage from Total (Reached/Unreached) <p className='sremarks'>{Math.floor(Data.reached / Data.total * 100)}</p></Tooltip>}>
                                            <td className='cunlayer'><Badge variant='info'>
                                                
                                            {Math.floor(Data.reached / Data.total * 100)} %</Badge></td>
                                        </OverlayTrigger>
    
    
                                        <td>
                                           {Data.total - Data.reached}
                                        </td>
                                        <OverlayTrigger overlay={<Tooltip >Percentage from Total (Reached/Unreached) <p className='sremarks'>{Math.ceil((Data.total - Data.reached) / Data.total * 100)}</p></Tooltip>}>
                                            <td className='cunlayer'><Badge variant='danger'>
                                                
                                            {Math.ceil((Data.total - Data.reached) / Data.total * 100)} %</Badge></td>
                                        </OverlayTrigger>
    
    
    
                                        <td className='cunlayer'>
                                            {Data.opened}
                                        </td >
                                        <OverlayTrigger overlay={<Tooltip >Percentage from Total (Opened/Unopened) <p className='sremarks'>{Math.floor(Data.opened / Data.total * 100)}</p></Tooltip>}>
                                            <td className='cunlayer'><Badge variant='success'>
                                                {Math.floor(Data.opened / Data.total * 100)}
                                                %</Badge></td>
                                        </OverlayTrigger>
    
    
    
                                        <td>
                                            {Data.total - Data.opened}
                                        </td>
                                        <OverlayTrigger overlay={<Tooltip >Percentage from Total (Opened/Unopened) <p className='sremarks'>{Math.ceil((Data.total - Data.opened) / Data.total * 100)}</p></Tooltip>}>
                                            <td className='cunlayer'><Badge variant='warning'>
                                                {Math.ceil((Data.total - Data.opened) / Data.total * 100)}
                                                %</Badge></td>
                                        </OverlayTrigger>
                                    </tr>
                                
                            
    
                        </tbody>
                    </table>
                </div>
                {/* /.card-body */}
            </div>
    
    
        )
    }
    getDistinctValues = async () => {

        let result = await G.getData('GetDistinctProfilesTypes', [['clientID', this.state.activeCompany.id]], true);
        result = await result.data

        let co = []; let ci = []; let ge = []; let subs = [];
        result.forEach(element => {
            if (element.type === 'co') { co.push(element.name) }
            if (element.type === 'ci') { ci.push(element.name) }
            if (element.type === 'ge') { ge.push(ts2(element.name == 0 ? 'Only Males' : 'Only Females', this.props.userLogin.language)) }
        })
        let array = []
        array = await G.getData("hotspotCategory/query", [['filter','(hide is null or hide=0) and clientID=' + this.state.activeCompany.id]])
        array = await array.data
        this.setState({ hotspotCategory: array })
        let arrayNames = [];
        array.map(element => {
          arrayNames.push(element.name)
        })
        subs.push("subscribed");
        subs.push("unsubscribed");
        this.setState({ CountryList: co, CityList: ci, availableGender: ge,categoriesList:arrayNames, availableSubs:subs })

    }

    refreshDataClients = async () => {
        this.setState({ loadCmp: true })
        let paramArr = [['addColumns', ' (select count(id) from profile where clientid = a.id) Count']]
        if (this.props.userLogin.clientID > 0) {

            paramArr.push(['filter', " a.id = " + this.props.userLogin.clientID])
        }
        let result = await G.getData('client/query', paramArr);
        result = await result.data;

        this.setState({ allCompanies: result })
        if (result.length > 0) {
            this.setState({ activeCompany: result[0] }
            )
            if (this.props.userLogin.clientID > 0) {
                this.setState({ activeScreen: 1 })
                this.refreshData(result[0].id)
            }

        }
        this.setState({ loadCmp: false })
    }
    refreshData = async (company) => {
        this.setState({ loadprofiles: true })
        let result = await G.getData('getprofileswithsubscriptionstatus', [['filter', 'clientID = ' + company]]);
        result = await result.data;
        let pageCounts = result.length / this.state.perPage
        this.setState({ allList: result, pageCount: pageCounts, originalList: result })

        this.setdisplayList(0)
        this.setState({ loadprofiles: false })

    }
    listProfiles = (companyId, companyDetails) => {

        this.setState({ allList: [], displayedList: [], activeScreen: 1, activeCompany: companyDetails })
        this.refreshData(companyId);
        this.getDistinctValues();



    }
    setdisplayList(startnumber) {
        let sliceArray = [...this.state.allList]

        if (this.state.allList.length > this.state.perPage) {
            sliceArray = sliceArray.slice(startnumber * this.state.perPage, startnumber * this.state.perPage + this.state.perPage)

        }
        
        this.setState({ displayedList: sliceArray })
    }
    importFromCSV = () => {

        history.push('/ImportProfiles')
    }
    editProfile = async(item) => {
        history.push("/ProfileManagement/customers/edit")
        window.scrollTo(0, 0)
        var userlanguage = item.language;
                                    if(!["fr","en","nl","de"].includes(userlanguage))
                                        userlanguage = "";
        this.setState({
            fields: {
                'firstname': item.firstName,
                'lastname': item.lastName,
                'email': item.emailAddress,
                'phone': item.mobile,
                'gender': ts((item.gender === 0 ? 'Male' : (item.gender===1?'Female':'not Defined')), this.props.userLogin.language),
                'language':userlanguage,
                'country': item.country,
                'city': item.city,
                'subscriptionstatus':(item.subscriptionstatus!=undefined && item.subscriptionstatus!=null?item.subscriptionstatus:"subscribed"),
                'companyname': (item.companyname!=undefined && item.companyname!=null?item.companyname:"")
            },
            errors:{},
            subscriptionstatus:item.subscriptionstatus,
            birthdate: item.age!=null?new Date(Date.parse(item.age)):null
            
        }

        )//Tue Jul 06 1993 15:54:17 GMT+0300
        var stats = await G.getData("profileStats",[['profileId',item.id]]);
        var results = await stats.data;
        this.setState({ editProfile: item.id, activeScreen: 2, profileStats:results })
    }

    deleteProfile = (item) => {
        this.setState({
            modalBody: ts("Are you sure you want to delete profile", this.props.userLogin.language) +" " + item.firstName + " " + item.lastName + ".",
            modalSubject: <span className='warningColor'>{ts2('Delete Profile', this.props.userLogin.language)} </span>,
            modalShow: true,
            deleteProfile: item.id
        })
    }
    confirmDeletePRofile = async () => {

        let parameters = [['filter', ' id = ' + this.state.deleteProfile]]
        var result = await G.getData('profile/delete', parameters, true)
        result = await result.status;
        if (result === 200) {
            this.setState({ modalShow: false });
            this.refreshData(this.state.activeCompany.id)
        }

    }


    //#endregion

    //#region Form Validation
    handleChange(e) {
        let { fields, errors } = this.state;
        fields[e.target.name] = e.target.value;
        delete errors[e.target.name];
        this.setState({ fields });
        if (e.target.name === 'filter' && e.target.value === '') {
            this.filterGrid()
        }
    }
    onKeyDown = e => {

        if (e.keyCode === 13) {
            e.preventDefault();
            this.filterGrid()
        }
    }
    filterGrid = () => {

        let filter = this.state.fields['filter']

        if (filter === "" || filter == null) {
            this.setState({ allList: [...this.state.originalList] }, () => { this.setdisplayList(0) })

        } else {
            this.setState({
                allList: [...this.state.originalList.filter(element =>{
                    var returnF = element.firstName.toLowerCase().includes(filter.trim().toLowerCase())
                    || element.lastName.toLowerCase().includes(filter.trim().toLowerCase())
                    || element.emailAddress.toLowerCase().includes(filter.trim().toLowerCase())
                    || element.city.toLowerCase().includes(filter.trim().toLowerCase())
                    || element.country.toLowerCase().includes(filter.trim().toLowerCase())
                    || element.mobile.toLowerCase().includes(filter.trim().toLowerCase())
                    if("male".includes(filter.trim().toLowerCase())||"mâle".includes(filter.trim().toLowerCase())||"female".includes(filter.trim().toLowerCase())||"femelle".includes(filter.trim().toLowerCase()))
                    {
                        var f = ("male".includes(filter.toLowerCase())||"mâle".includes(filter.toLowerCase())?0:1);
                        
                        returnF = returnF || element.gender == f;
                    }
                    return returnF;
                }
                    //|| element.gender.toLowerCase().includes(filter.toLowerCase()=="male"||filter.toLowerCase()=="mâle"?"0":(filter.toLowerCase()=="female"||filter.toLowerCase()=="femelle"?"1")))
                )]
            }, () => { this.setdisplayList(0) })

        }
    }

    handleUpdateAutoComplete(e, name, value) {
        let { fields } = this.state;
        fields[name] = value;
        if (name === 'searchcountry') {
            let countryArray = this.state.filteredCountryList;
            if (countryArray.filter(element => element === value).length === 0) countryArray.push(value)
            this.setState({ filteredCountryList: countryArray })
        }
        if (name === 'category') {
            let categoryArray = this.state.filteredCategoriesList;
            if (categoryArray.filter(element => element === value).length === 0) categoryArray.push(value)
            this.setState({ filteredCategoriesList: categoryArray })
        }
        if (name === 'searchcity') {
            let cityArray = this.state.filteredCityList;
            if (cityArray.filter(element => element === value).length === 0) cityArray.push(value)
            this.setState({ filteredCityList: cityArray })
        }
        if(name!=='country')
            fields[name] = ''
        this.setState({ fields })
    }
    handleValidation = async () => {


        const { firstname, lastname, email, phone, gender, country, city,language, subscriptionstatus, companyname } = this.state.fields;
        console.log("language",language)
        let errors = {};
        let formIsValid = true;

        if (!firstname) { formIsValid = false; errors["firstname"] = ts2("First name cannot be empty", this.props.userLogin.language); }
        if (!lastname) { formIsValid = false; errors["lastname"] = ts2("Last name cannot be empty", this.props.userLogin.language); }
        if (!email) { formIsValid = false; errors["email"] = ts2("Email cannot be empty", this.props.userLogin.language); }
        if (language=='') { formIsValid = false; errors["language"] = ts2("Language cannot be empty", this.props.userLogin.language); }
        //if (!phone) { formIsValid = false; errors["phone"] = ts2("Phone cannot be empty", this.props.userLogin.language); }
        //if (!city) { formIsValid = false; errors["city"] = ts2("City cannot be empty", this.props.userLogin.language); }
        /*if (!gender) { formIsValid = false; errors["gender"] = ts2("Gender must be selected", this.props.userLogin.language); } else {
            if (gender.toLowerCase().startsWith("s")) {
                formIsValid = false;
                errors['Gender'] = ts2("Gender must be selected", this.props.userLogin.language)
            }
        }*/
        if (!country) { formIsValid = false; errors["country"] = ts2("Country cannot be empty", this.props.userLogin.language); }
        //if (this.state.birthdate == null) { formIsValid = false; errors['birthdate'] = ts2("Birth date cannot be empty", this.props.userLogin.language); }


        if (this.state.editProfile < 0) {
            let checkEmail = await G.getData("CountEmails", [['email', email],['clientID', this.state.activeCompany.id]])
            checkEmail = await checkEmail.data

            if (checkEmail[0].count > 0) { formIsValid = false; errors["email"] = ts2("Email already exist", this.props.userLogin.language); }
            
        }
        this.setState({ errors: errors });
        console.log("formIsValid:" , formIsValid)
        if (formIsValid) {
            let parameters = [];
            let postCommand = ''
            var genderparam = gender
            if(gender == undefined)
            {
                genderparam = "Male";
            }
            if (this.state.editProfile < 0) {
                parameters = [
                    ['clientID', this.state.activeCompany.id],
                    ['firstName', firstname],
                    ['lastName', lastname],
                    ['emailAddress', email],
                    ['mobile', phone],
                    ['gender', genderparam?(genderparam.toLowerCase().startsWith("m") ? 0 : 1):2],
                    ['age', this.state.birthdate?(this.state.birthdate.toISOString().slice(0, 19).split('T')[0]):''],
                    ['country', country],
                    ['city', city],
                    ['Street', ''],
                    ['creationUser', this.props.userLogin.id],
                    ['language',language],
                    ['companyname', companyname]
                ]
                postCommand = 'profile/insert'
            } else {
                parameters = [
                    ['firstName', firstname],
                    ['lastName', lastname],
                    ['emailAddress', email],
                    ['mobile', phone],
                    ['gender', genderparam?(genderparam.toLowerCase().startsWith("m") ? 0 : 1):2],
                    ['age', this.state.birthdate?(this.state.birthdate.toISOString().slice(0, 19).split('T')[0]):''],
                    ['country', country],
                    ['city', city],
                    ['Street', ''],
                    ['lastEditUser', this.props.userLogin.id],
                    ['language',language],                    
                    ['companyname', companyname],
                    ['id', this.state.editProfile]

                ]
                postCommand = 'profile/update'
            }
            var substatus = subscriptionstatus;
            if(substatus == undefined || substatus == null || substatus == "" )
            {
                substatus = "subscribed"; 
            }
            console.log("Subscription Status", substatus)
            G.log(postCommand, parameters)
            console.log(postCommand, parameters)
            var result = await G.getData(postCommand, parameters, true)
            result = await result.status;
            await G.getData("updatesubscription",[['email',email],['subscriptionstatus',substatus]],true)
            

            

            return result === 200

        }
        else {
            return formIsValid;
        }
    }
    submitForm = async (e) => {
        e.preventDefault();
        this.setState({ loadSave: true })
        var a = await this.handleValidation();
        console.log("A:",a)
        if (a === true) {
            this.refreshData(this.state.activeCompany.id)
            this.setState({ activeScreen: 1 })

        } else { G.log('Form Is Not Valid') }
        this.setState({ loadSave: false })
    }
    //#endregion


    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.setdisplayList(selectedPage)
        });

    };
    QueryProfiles = async () => {
        this.setState({ loadProfiles: true })
        this.setState({ profileList: [] })
        let { fields, filteredCountryList, filteredCityList, filteredCategoriesList } = this.state
        console.log(fields);
        let { age } = this.state.fields
        let errors = {};
        let filter = "  clientID =  " + this.state.activeCompany.id
        let filterDescr = ''
        let filterage = ''
        if (age != null) {
            if (age !== '') {
                let varAge = age.replace(/ /g, '')
                varAge = varAge.split(",")
                let Error = '';

                varAge.map(element => {
                    if (isNaN(element.replace(/-/g, ''))) { Error = "Invalid Age Fitler. Please Enter Age Or Age Ranges seperated by comma ','" }
                    else {
                        if (element !== '') {
                            if (element.includes("-")) {
                                element.split("-").map(subElement => {
                                    if (isNaN(subElement)) { Error = "Invalid Age Fitler. Please Enter Age Or Age Ranges seperated by comma ','" }
                                })
                            }


                            if (Error === '') {

                                if (element.includes("-")) {
                                    filterage += ' or TIMESTAMPDIFF(YEAR, age, CURDATE()) between ' + element.split("-")[0] + ' and ' + element.split("-")[1]
                                } else {
                                    filterage += ' or TIMESTAMPDIFF(YEAR, age, CURDATE()) = ' + element
                                }
                            }
                        }

                    }
                })

                if (Error !== '') {
                    errors['age'] = Error
                    this.setState({ errors })
                    return
                }
                filterDescr = ", Age Ranges (" + age + ") "
            }
        }
        if (filterage.trim() !== '') filter += ' and ( ' + filterage.trim().substring(2) + ')'

        if (fields['gender'] === (this.props.userLogin.language === 0 ? 'Only Males' : 'Seulement des mâles')) { filter += " and gender=0"; filterDescr += ', Only Males' }
        if (fields['gender'] === (this.props.userLogin.language === 0 ? 'Only Females' : "Seulement des femelle")) { filter += " and gender=1"; filterDescr += ', Only Females' }
        if (fields['gender'] === (this.props.userLogin.language === 0 ? 'not Defined' : "not Defined")) { filter += " and gender=2"; filterDescr += ', not Defined' }
        if (fields['subscription'] === "subscribed") { filter += " and emailAddress not in (select emailAddress from unsubscribe)"; filterDescr += ', Subscribed' }
        if (fields['subscription'] === "unsubscribed") { filter += " and emailAddress in (select emailAddress from unsubscribe)"; filterDescr += ', Unsubscribed' }
        filter += filteredCountryList.length === 0 ? '' : " and country in (" + filteredCountryList.map(element => "'" + element.replace("'","''") + "'") + ")"
        filterDescr += filteredCountryList.length === 0 ? '' : ", countries in (" + filteredCountryList.map(element => "'" + element.replace("'","''") + "'") + ")"
        filter += filteredCategoriesList.length === 0 ? '' : " and  id in (select customerID from campaigncustomerhotspot where hotspotID in(select hotspotId from hotspotcategoryrelation where categoryId in (select id from hotspotcategory where name in (" + filteredCategoriesList.map(element => "'" + element.replace("'","''") + "'") + "))))" 
        filter += filteredCityList.length === 0 ? '' : " and city in (" + filteredCityList.map(element => "'" + element.replace("'","''") + "'") + ")"
        filterDescr += filteredCityList.length === 0 ? '' : ", cities in (" + filteredCityList.map(element => "'" + element.replace("'","''") + "'") + ")"
        this.setState({ filterString: filter, filterDescr: filterDescr })
        //let result = await G.getData('getprofiles/profile', filter === '' ? [] : [['filter', filter + subscribedfilter]]);
        //let unsubscribedresult = await G.getData('getprofiles/profile', filter === '' ? [] : [['filter', filter + unsubscribedfilter]]);
        //result = await result.data;
        //unsubscribedresult = await unsubscribedresult.data;
        //let pageCounts = result.length / this.state.perPage
        //this.setState({ profileList: result, pageCount: pageCounts, originalList: result, unsubscribedprofileList: unsubscribedresult, originalsunsubscribedList: unsubscribedresult })
        //this.setdisplayList(0)
        //this.setdisplayUnsubscribedList(0);
        //this.setState({ loadProfiles: false, didMakeQuery: true })
        let result = await G.getData('getprofileswithsubscriptionstatus', filter === '' ? [] : [['filter', filter]]);
        result = await result.data;
        let pageCounts = result.length / this.state.perPage
        this.setState({ allList: result, pageCount: pageCounts, originalList: result })

        this.setdisplayList(0)
        this.setState({ loadProfiles: false, didMakeQuery: true })
        
    }
    render() {
        const { errors, fields } = this.state;
        
        return (

            <Container fluid className='fullWidth '>
                {this.state.activeScreen === 0 &&
                    <div>

                        <h4 className='mb-5 mt-5'>{ts2('Select a client in order to manage their customer', this.props.userLogin.language)}</h4>
                        <div className='p-3 mr-3 '>
                            <div className="table-responsive p-0" style={{ maxHeight: 900 }}>
                                <table className="table table-head-fixed text-nowrap table-hover ">
                                    <thead>
                                        <tr>
                                            <th>Action</th>
                                            <th>ID</th>
                                            <th>{ts('Client Name', this.props.userLogin.language)}</th>
                                            <th>{ts('Contact Person', this.props.userLogin.language)}</th>
                                            <th>{ts('Phone', this.props.userLogin.language)}</th>
                                            <th>{ts('Email Address', this.props.userLogin.language)}</th>
                                            <th>{ts('Created In', this.props.userLogin.language)}</th>

                                            <th>{ts2('Number of profiles', this.props.userLogin.language)}</th>

                                        </tr>
                                    </thead>


                                    {!this.state.loadCmp && <tbody>
                                        {
                                            (this.state.allCompanies).map((item) => {

                                                return (
                                                    <tr key={item.id}>
                                                        <td className='xxxtitle shiteColor handCursor'
                                                            onClick={() => { this.listProfiles(item.id, item);history.push("/ProfileManagement/customers") }}>
                                                            <Badge variant='info'>{ts2('Manage Customers', this.props.userLogin.language)}</Badge>
                                                        </td>
                                                        <td>{item.id}</td>
                                                        <td>{item.companyName}</td>
                                                        <td>{item.contactPerson}</td>
                                                        <td>{item.contactPersonNo}</td>
                                                        <td>{item.email}</td>
                                                        <td>{td(this.dateFormat(item.creationDate, 'dddd, mmm dd yyyy h:MM tt'), this.props.userLogin.language)}</td>
                                                        <td>{item.Count}</td>


                                                    </tr>)
                                            })
                                        }

                                    </tbody>
                                    }
                                </table>
                                {this.state.loadCmp &&
                                    <div className='flexcolumn center verticalcenter p-5 fullWidth hint '>
                                        {ts2('Loading Available Companies', this.props.userLogin.language)}
                                        <Spinner animation="border" className='mt-3' variant="warning" />

                                    </div>
                                }
                            </div>
                        </div>

                    </div>

                }
                {this.state.activeScreen === 1 &&
                    <div className='fullWidth '>
                        <h4 className='mt-5'>{ts2('Customers of ', this.props.userLogin.language) + (this.state.activeCompany.companyName ? this.state.activeCompany.companyName : 'No Company Selected')}</h4>
                        {!this.state.loadprofiles && <div>
                            <h6 className='mb-3'>{G.countItems(this.state.allList.length, 'Profile') + ' ' + ts2('created', this.props.userLogin.language)}</h6>

                            <div className='flexrow pt-2 pb-5 '>
                                <Button variant='success' onClick={() => { this.setState({ activeScreen: 2, fields: {}, errors: {}, editProfile: -1 });history.push("/ProfileManagement/customers/create") }} className='mr-3 '>{ts2('Add New Profile', this.props.userLogin.language)}</Button>
                                <Button variant='success' onClick={this.importFromCSV} className='mr-3 '>{ts2('Import Profiles from CSV file', this.props.userLogin.language)}</Button>
                                {this.props.userLogin.accountType === 0 && <Button variant='warning' onClick={() => { this.setState({ activeScreen: 0 });history.go(-1) }}>{ts('Cancel / Return', this.props.userLogin.language)}</Button>}
                            </div>

                            <div className='p-3 mr-3 '>
                                <Form.Label  >{ts2('Search for a customer', this.props.userLogin.language)}</Form.Label>
                                <div className='flexrow col-8'>
                                    <Form.Control onKeyDown={this.onKeyDown} name='filter' type={'text'} placeholder={ts2('Search for a customer', this.props.userLogin.language)} onChange={this.handleChange} value={fields['filter'] || ''} />
                                    {this.state.fields['filter'] && this.state.fields['filter'] !== '' && <Button variant='warning ml-2' onClick={this.filterGrid}>{ts2('Apply', this.props.userLogin.language)}</Button>}
                                    {this.state.fields['filter'] && this.state.fields['filter'] !== '' && <Button variant='info ml-2' onClick={() => {
                                        let { fields } = this.state;
                                        delete fields['filter'];
                                        this.setState({ fields });

                                        this.filterGrid()
                                    }}>{ts2('Clear', this.props.userLogin.language)}</Button>}
                                </div>
                                <h6 className='bold mb-4 mt-5 cunlayer p-2 '> {ts('Filter Based on Customers Definition', this.props.userLogin.language)}</h6>
                                <div className='flexrow spacebetween pl-2 mb-5'>
                                    <div className='col-12' >

                                        <div className='flexrow '>
                                        <div className='col-6'>
                                            {G.renderHotspotDropDown("Gender", this.state.availableGender, this.handleChange, this.state.fields, this.state.errors, this.state.availableGender[0], false, this.props.userLogin.language)}
                                            </div>
                                            <div className='col-6'>
                                                {G.renderTextField("Age", 'text', "All Ages", this.handleChange, this.state.fields, this.state.errors, ts('Filter Example 18-24,35, 50-54', this.props.userLogin.language), '', false, this.props.userLogin.language,true)}
                                                <p className='sremarks hint  ml-3'>{ts('Filter Example 18-24,35,50-54 is equal for (all ages between 18 and 24) or (age equal 35) or (age between 50 and 54)', this.props.userLogin.language)}    </p>
                                            </div>
                                        </div>
                                        <div className='flexrow '>
                                        <div className='col-6'>
                                            {G.renderMultiSelectionBox('Countries', this.state.filteredCountryList, ts('All Countries Selected', this.props.userLogin.language),
                                                (removedItem) => { this.setState({ filteredCountryList: [...this.state.filteredCountryList].filter(element => element !== removedItem) }) }
                                                , <Autocomplete showborders={true} reset={true} value={this.state.fields['searchcountry'] || ''} error={this.state.errors['searchcountry']} name='searchcountry' updateFromList={this.handleUpdateAutoComplete} suggestions={this.state.CountryList}
                                                    placeholder={ts('Add Countries Filter Here', this.props.userLogin.language) + ' (' + (this.props.userLogin.language === 0 ? G.countItems(this.state.CountryList.length, "Country", 'Countries') : this.state.CountryList.length + ' Pays') + ' ' + ts('Available', this.props.userLogin.language) + ')'} classNames='fullWidth' onChange={this.handleChange} />
                                                , ''


                                                , this.props.userLogin.language)}
                                                </div>
                                                <div className='col-6'>
                                            {G.renderMultiSelectionBox('Cities', this.state.filteredCityList, ts('All Cities Selected', this.props.userLogin.language),
                                                (removedItem) => { this.setState({ filteredCityList: [...this.state.filteredCityList].filter(element => element !== removedItem) }) }
                                                , <Autocomplete showborders={true} reset={true} value={this.state.fields['searchcity'] || ''} error={this.state.errors['searchcity']} name='searchcity' updateFromList={this.handleUpdateAutoComplete} suggestions={this.state.CityList}
                                                    placeholder={ts('Add Cities Filter Here', this.props.userLogin.language) + ' (' + (this.props.userLogin.language === 0 ? G.countItems(this.state.CityList.length, "City", 'Cities') : G.countItems(this.state.CityList.length, 'Ville')) + ' ' + ts('Available', this.props.userLogin.language) + ')'} classNames='fullWidth' onChange={this.handleChange} />
                                                , ''
                                                , this.props.userLogin.language)}
                                                </div>
                                        </div>
                                        <div className='flexrow'>
                                        <div className='col-6'>
                                        {G.renderMultiSelectionBox('Interactions categories', this.state.filteredCategoriesList, ts('All Categories Selected', this.props.userLogin.language),
                                                (removedItem) => { this.setState({ filteredCategoriesList: [...this.state.filteredCategoriesList].filter(element => element !== removedItem) }) }
                                                , <Autocomplete showborders={true} reset={true} value={this.state.fields['category'] || ''} error={this.state.errors['category']} name='category' updateFromList={this.handleUpdateAutoComplete} suggestions={this.state.categoriesList}
                                                    placeholder={ts('Add Categories Filter Here', this.props.userLogin.language) + ' (' + (this.props.userLogin.language === 0 ? G.countItems(this.state.categoriesList.length, "Category", 'Categories') : this.state.categoriesList.length + ' Categories') + ' ' + ts('Available', this.props.userLogin.language) + ')'} classNames='fullWidth' onChange={this.handleChange} />
                                                , ''


                                                , this.props.userLogin.language)}
                                                </div>
                                                <div className='col-6'>
                                                {G.renderDropDown("Subscription", this.state.availableSubs, this.handleChange, this.state.fields, this.state.errors,  this.state.availableSubs[0], false, this.props.userLogin.language)}
                                                </div>
                                            </div>
                                    </div>
                                    
                                </div>
<div className='flexrow spacebetween pl-2 mb-5'>
<div className='col-12'>
                                    <Button variant='success' className=' ' onClick={this.QueryProfiles}>
            {this.state.loadProfiles && <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className='mr-2'
                disabled={this.state.successfullySentCampaign}
            />}
            {ts('Query Profiles', this.props.userLogin.language)}</Button>
                                        </div>

</div>
{this.state.displayedList.length > 0 && <h6 className='mb-3 pl-2'>{G.countItems(this.state.displayedList.length, ts('Profile', this.props.userLogin.language)) + '  ' + ts('Found', this.props.userLogin.language)} </h6>}
                            </div>
                            <div className='p-3 mr-3 '>
                                <div className="table-responsive p-0" style={{ maxHeight: 900 }}>
                                    <table className="table table-head-fixed text-nowrap table-hover table-hover ">
                                        <thead>
                                            <tr>
                                                <th>Action</th>
                                                <th>{ts2('Delete', this.props.userLogin.language)}</th>
                                                <th>ID</th>
                                                <th>Source</th>
                                                <th>Language</th>
                                                <th>{ts2('First Name', this.props.userLogin.language)}</th>
                                                <th>{ts2('Last Name', this.props.userLogin.language)}</th>
                                                <th>{ts2('Subscription status', this.props.userLogin.language)}</th>
                                                <th>{ts('Email', this.props.userLogin.language)}</th>
                                                <th>{ts('Mobile', this.props.userLogin.language)}</th>
                                                <th>{ts('Gender', this.props.userLogin.language)}</th>
                                                <th>Age</th>
                                                <th>{ts('Country', this.props.userLogin.language)}</th>
                                                <th>{ts('City', this.props.userLogin.language)}</th>
                                                <th>{ts('Created In', this.props.userLogin.language)}</th>
                                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                (this.state.displayedList).map((item) => {
                                                    var userlanguage1 = item.language;
                                    if(!["fr","en","nl","de"].includes(userlanguage1))
                                        userlanguage1 = "";
                                                    return (
                                                        <tr key={item.id}>
                                                            
                                                            <td className='xxxtitle shiteColor handCursor'
                                                                onClick={() => { this.editProfile(item) }}>
                                                                <Badge variant='info'>{ts2('View details', this.props.userLogin.language)}</Badge>
                                                            </td>
                                                            <td className='xxxtitle shiteColor handCursor'
                                                                onClick={() => { this.deleteProfile(item) }}>
                                                                <Badge variant='danger'>{ts2('Delete Profile', this.props.userLogin.language)}</Badge>
                                                            </td>
                                                            <td>{item.id}</td>
                                                            <td>{item.source}</td>
                                                            <td>{userlanguage1}</td>
                                                            <td>{item.firstName}</td>
                                                            <td>{item.lastName}</td>
                                                            <td>{item.subscriptionstatus}</td>
                                                            <td>{item.emailAddress}</td>
                                                            <td>{item.mobile}</td>
                                                            <td>{ts(item.gender === 0 ? 'Male' : item.gender === 1 ? 'Female' : 'not Defined', this.props.userLogin.language)}</td>
                                                            <td>{item.age!==null?G.countItems(G.getAge(item.age), 'year') + ' old':'not Defined'} </td>
                                                            <td>{item.country}</td>
                                                            <td>{item.city}</td>
                                                            <td>{td(this.dateFormat(item.creationDate, 'dddd, mmm dd yyyy h:MM tt'), this.props.userLogin.language)}</td>
                                                            


                                                        </tr>)
                                                })
                                            }

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>}
                        {!this.state.loadprofiles && this.state.allList.length > this.state.perPage && <ReactPaginate
                            previousLabel={ts("Previous", this.props.userLogin.language)}
                            nextLabel={ts("Next", this.props.userLogin.language)}
                            breakLabel={"..."}
                            pageCount={this.state.pageCount}
                            marginPagesDisplayed={this.state.perPage}
                            pageRangeDisplayed={2}
                            onPageChange={this.handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"subPagination"}
                            activeClassName={"activePage "}
                            breakClassName={'eachPage'}
                            previousClassName={'eachPagePrevNex'}
                            nextClassName={'eachPagePrevNex'}
                            pageClassName={'eachPage'}
                        />}
                        {this.state.loadprofiles &&
                            <div className='flexcolumn center verticalcenter p-5 fullWidth hint '>
                                {ts2('Loading Available Profiles', this.props.userLogin.language)}
                                <Spinner animation="border" className='mt-3' variant="warning" />

                            </div>
                        }
                        {!this.state.loadprofiles && this.state.allList.length === 0 &&
                            <h4 className='text-center hint pt-5 mt-5 lowalpha'>{ts2('No Profiles Found', this.props.userLogin.language)}
                                <p onClick={() => { this.setState({ activeScreen: 2, fields: {}, errors: {} }) }} >{this.state.originalList.length === 0 && <Badge variant='info' className='mt-2 handCursor'> {ts2('Click Here to Create your first Profile', this.props.userLogin.language)}</Badge>}</p>
                            </h4>}


                    </div>

                }
                {this.state.activeScreen === 2 &&
                    <div className='fullWidth'>

                        <Form name="form" className='pt-5' onSubmit={this.submitForm.bind(this)} >

                            <h4 className='mb-5 mt-5'> {ts2(this.state.editProfile < 0 ? 'Create New Profile In' : 'Edit Profile In', this.props.userLogin.language)} <span className='themeColor bold'>{(this.state.activeCompany?.companyName ? this.state.activeCompany.companyName : '(No Company Selected')}</span></h4>
                            <Form.Row>
                                <Col>
                                    {G.renderTextField("First Name", 'text', ts2("Enter first name", this.props.userLogin.language), this.handleChange, fields, errors, '', '', false, this.props.userLogin.language)}
                                </Col>
                                <Col>
                                    {G.renderTextField("Last Name", 'text', ts2("Enter last name", this.props.userLogin.language), this.handleChange, fields, errors, '', '', false, this.props.userLogin.language)}
                                </Col>

                            </Form.Row>
                            <Form.Row>
                                <Col>
                                    {G.renderTextField("Email", 'email', ts2("Enter email address", this.props.userLogin.language), this.handleChange, fields, errors, '', '', /*this.state.editProfile > 0*/false , this.props.userLogin.language)}
                                </Col>
                                <Col>
                                    {G.renderTextField("Phone", 'text', ts2("Enter profile primary phone", this.props.userLogin.language), this.handleChange, fields, errors, '', '', false, this.props.userLogin.language)}
                                </Col>

                            </Form.Row>

                            <Form.Row>
                                <Col>
                                    {G.renderDropDown('Gender', [ts('Male', this.props.userLogin.language), ts('Female', this.props.userLogin.language), ts('not Defined', this.props.userLogin.language)], this.handleChange, fields, errors, ts2('Select Gender', this.props.userLogin.language), '', false, this.props.userLogin.language)}
                                </Col>

                                <Col>
                                    {G.renderDatePicker('Birthdate', this.state.birthdate, (date) => { this.setState({ birthdate: date }) }, errors, ts2("Enter the birthdate", this.props.userLogin.language), "dd MMMM yyyy", false, null, this.props.userLogin.language)}
                                </Col>
                            </Form.Row>


                            <Form.Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label htmlFor={'country'} >{ts('Country', this.props.userLogin.language)}</Form.Label>
                                        <Autocomplete showborders="true" value={this.state.fields['country'] || ''} error={this.state.errors['country']} name='country' updateFromList={this.handleUpdateAutoComplete} suggestions={G.country_list} placeholder={ts2('Enter the profile country', this.props.userLogin.language)} classNames='fullWidth' onChange={this.handleChange} />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    {G.renderTextField("City", 'text', ts2("Enter profile city", this.props.userLogin.language), this.handleChange, fields, errors, '', '', false, this.props.userLogin.language)}
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col>
                                    {
                                    
                                    G.renderDropDown('Language', this.languageArray()/*[ts('fr', this.props.userLogin.language), ts('en', this.props.userLogin.language)]*/, this.handleChange, fields, errors, '', '', false, this.props.userLogin.language)}
                                </Col>
                                <Col>
                                {G.renderDropDown('Subscription Status', [ts('subscribed', this.props.userLogin.language), ts('unsubscribed', this.props.userLogin.language)], this.handleChange, fields, errors, '', '', false, this.props.userLogin.language)}
                                {/*<Form.Label  >{ts('Subscription', this.props.userLogin.language)}</Form.Label>
                                <Form.Control disabled="true" className={' p-1 '}  name="subscriptionstatus" type="text"  value={this.state.subscriptionstatus} />*/}
                                </Col>
                                </Form.Row>
                                <Form.Row>
                                <Col>
                                    {G.renderTextField("Company name", 'text', ts2("Enter company name", this.props.userLogin.language), this.handleChange, fields, errors, '', '', false, this.props.userLogin.language)}
                                </Col>
                                <Col></Col>
                                    </Form.Row>
                            <div className='flexrow pt-5 '>
                                <Button variant='success' type="submit" className='mr-3'>
                                    {this.state.loadSave && <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />}
                                    {ts2(this.state.editProfile < 0 ? 'Register Client' : 'Save Changes', this.props.userLogin.language)}</Button>

                                <Button variant='warning' onClick={() => {
                                    this.setState({ activeScreen: 1, errors:[] })
                                    history.push("/ProfileManagement/customers")
                                }}>{ts('Cancel / Return', this.props.userLogin.language)}</Button>
                            </div>
                            {this.state.editProfile>=0 && 
                            <div className='flexrow  spacebetween fullWidth col-12  '  >
                            <div className=' mr-2 fullWidth ' >
                                <p className='  ssstitle bold boxTopRadius5 cunlayer   p-3   '> {ts('Statistics',this.props.userLogin.language)} </p>
                                <div style={{ maxHeight: 500, overflow: 'auto' }}>
                                    {this.renderGrid(ts2('Total sent campaigns',this.props.userLogin.language), this.state.profileStats)}

                                </div>
                                <p className='  ssstitle boxTopRadius5 cunlayer   p-3   '> {ts2('Number of categories customer interacted with',this.props.userLogin.language)}: <span style={{fontSize:'30px',fontWeight:'bold' }}>{this.state.profileStats.categoriesNbr}</span></p>
                                
                                <p className='  ssstitle boxTopRadius5 cunlayer   p-3   '> {ts2('List of categories customer interacted with',this.props.userLogin.language)}: </p>
                                <ul>
                                {this.state.profileStats.categories.map(element=>{
                                    return(<li>{element.name}</li>)
                                })}
                                </ul>
                            </div>
                            

                        </div>
                            }
                        </Form>




                    </div>}
                <MessageBox
                    show={this.state.modalShow}
                    title={this.state.modalSubject}
                    body={this.state.modalBody}
                    middle={{ action: () => { this.confirmDeletePRofile() }, text: ts2('Delete Profile',this.props.userLogin.language), variant: 'danger' }}

                    right={{ action: () => { }, text: ts('Cancel',this.props.userLogin.language), variant: 'success' }}
                    onHide={() => { this.setState({ modalShow: false }) }}
                />
            </Container>
        )
    }
}

//#region Redux & Export
const mapStateToProps = state => {

    return {
        userLogin: state.SaveLogin.userData,
    };
};
export default connect(
    mapStateToProps
)(profileManagement);
//#endregion