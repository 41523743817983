import React, { Component } from 'react';
import { Badge, Container, Spinner } from 'react-bootstrap'
import Chart from 'react-apexcharts'
import '../styles/glob.css';
import * as G from '../components/global';
import { connect } from 'react-redux';
import history from '../components/history';
import totCities from '../components/assets/total-cities.png'
import totCountries from '../components/assets/total-countries.png'
import totCustomers from '../components/assets/total-customers-created.png'
import totFemale from '../components/assets/total-females.png'
import totMales from '../components/assets/total-males.png'
import addContacts from '../components/assets/add-contact.png'
import analyseStatistic from '../components/assets/Analyse-statistics.png'
import createTemplate from '../components/assets/create-template.png'
import sendCampaign from '../components/assets/send-campaign.png'
import { translateScreen as ts , translateScreen2 as ts2 , translateDate as td} from '../components/dictionary'


class mainContent extends Component {
    
    constructor(props) {
        super(props);

        this.state = {
            allList: [],
            otherDashboard: [],
            profileStatics: [],
            optionHistory: {
                series: [{
                    name:  'Loading',
                    type: 'column',
                    data: []
                }, {
                    name:  'Loading',
                    type: 'line',
                    data: []
                }],
                chart: {
                    height: 350,
                    type: 'line',

                },
                stroke: {
                    width: [3, 2],

                    curve: 'smooth',

                },

                title: {
                    text:  'Traffic Sources' 
                },
                dataLabels: {
                    enabled: false,
                    enabledOnSeries: [1, 0]
                },
                labels: ['01 Jan 2001', '02 Jan 2001', '03 Jan 2001', '04 Jan 2001', '05 Jan 2001', '06 Jan 2001', '07 Jan 2001', '08 Jan 2001', '09 Jan 2001', '10 Jan 2001', '11 Jan 2001', '12 Jan 2001'],
                xaxis: {
                    type: 'date'
                },
                yaxis: [{
                    title: {
                        text:  'Number of Users',
                    },

                }, {
                    opposite: true,
                    title: {
                        text:  'Number of LOG IN' 
                    }
                }]
            },
            optionsReach: { series: [] },
            optionsOpen: { series: [] },
            historyData: [],
            doneLoading: false,
            loadprofiles: true,
            DashboardCampaigns: {}
        }

    }
    componentDidMount() {
        this.getData();
    }

    getData = async () => {
        try {

            this.setState({ loadprofiles: true })
            let result
            if (this.props.userLogin) {
                this.setState({ doneLoading: true })
                if (this.props.userLogin.accountType < 2) {
                    result = await G.getData('getDashboard', [['clientID', this.props.userLogin.clientID]]);
                    this.setState({ allList: await result.data })
                }
                //#region History Grid
                if (this.props.userLogin.accountType < 2) {
                    result = await G.getData('getLogsChart', [['clientID', this.props.userLogin.clientID], ['AccountType', this.props.userLogin.accountType], ['userID', this.props.userLogin.id]]);
                    this.setState({ historyData: await result.data })
                }

                if (this.props.userLogin.accountType < 2) {
                    //#region History Grid
                    result = await G.getData('getDashboardOther', [['clientID', this.props.userLogin.clientID]]);
                    this.setState({ otherDashboard: await result.data })


                    let optionHistory = { ...this.state.optionHistory }

                    let groupLabels = []
                    let loginCounts = [];
                    let userCounts = []


                    this.state.historyData.map(element => {
                        groupLabels.push(!isNaN(element.groups) ? 'Week ' + element.groups : element.groups)
                        loginCounts.push(element.counts)
                        userCounts.push(element.userCount)
                    })

                    optionHistory.series = [{
                        name: ts2('Number of Users Logged in',this.props.userLogin.language),
                        type: ts2('line',this.props.userLogin.language),
                        data: userCounts
                    }, {
                        name: ts2('Number of LOG IN',this.props.userLogin.language),
                        type: ts2('line',this.props.userLogin.language),
                        data: loginCounts
                    }]

                    optionHistory.labels = groupLabels

                    this.setState({ optionHistory })
                    //#endregion
                }

                if (this.props.userLogin.accountType < 2) {
                    //#region History Grid
                    result = await G.getData('getDashboardCampaigns', [['clientID', this.props.userLogin.clientID]]);

                    this.setState({ DashboardCampaigns: await result.data })
                    //#endregion
                }

                result = await G.getData('getDashboardProfilesStatitics', [['clientID', this.props.userLogin.clientID]]);
                this.setState({ profileStatics: await result.data })

                this.setState({ loadprofiles: false })
            }
        } catch (error) {

        }


    }
    getProfileTypes = () => {
        let type = 'Total Profiles'; let value = 0;
        if (this.state.profileStatics.length < 1) {
            return <div></div>
        }
        let i = -1;
        return (
            <div >
                <p className='ssstitle bold mb-5 mt-5 '> {ts2('Your Customers Statistics',this.props.userLogin.language)}</p>
                <div className="row pt-3">
                    {['A', 'CO', 'C', 'M', 'F'].map(
                        element => {
                            i += 1
                            return (
                                <div className="col-lg-2 col-6" onClick={() => { history.push("/ProfileManagement") }}>
                                    {/* small box */}
                                    <div className={"small-box " + (i == 0 ? ' bg-warning ' : i == 1 ? ' bg-success ' : i == 2 ? ' bg-info ' : i == 3 ? ' bg-danger ' : ' bg-warning ')}>
                                        <div className="inner">
                                            <h3>{this.state.profileStatics[0][element]}</h3>
                                            <p>{(i == 0 ? ts2('Total Customers Created',this.props.userLogin.language) : i == 1 ? ts2('Total Countries',this.props.userLogin.language) : i == 2 ? ts2('Total Cities' ,this.props.userLogin.language): i == 3 ? ts2('Total Males',this.props.userLogin.language) : ts2('Total Females',this.props.userLogin.language))}</p>
                                        </div>
                                        <div className="icon">
                                            <img src={(i == 0 ? totCustomers : i == 1 ? totCountries : i == 2 ? totCities : i == 3 ? totMales : totFemale)} width={60} className="graysvg xxlowalpha" style={{position:'absolute', top: 10, right:10}}></img>
                                            {/* <i className="ion ion-person-add" /> */}
                                        </div>
                                        <a href="#" className="small-box-footer">{ts2('More info',this.props.userLogin.language)} <i className="fas fa-arrow-circle-right" /></a>
                                    </div>
                                </div>
                            )
                        }
                    )}
                </div>
            </div>


        )
    }
    renderCard = (title, image, variant, text, action) => {
        let accountType = 10;
        if (this.props.userLogin) accountType = this.props.userLogin.accountType;
        return (

            <div className={'flexColumn text-center mainScreenCard ' + (accountType < 2 ? 'col-12 col-sm-10 col-md-4 col-lg-3 ' : ' col-10 ')} onClick={action}>
                <p className='sstitle'> {image}</p>
                <Badge variant={variant} className='p-2'>{title}</Badge>
                <p className='p-2 hint midremarks pt-2'> {text}</p>
                <p className='sremarks   pt-2' >{ts2('Click To Open',this.props.userLogin.language)} </p>
            </div>
        )
    }

    renderCampaignCard = (name, value, text) => {
        return (
            <div className='flexcolumn spacebetween  col-12'>

                <div className={'mainCampaignheader'}>
                    {name}
                    <p className='sremarks hint'>{text}</p>
                </div>
                <div className='titles bold'>
                    {value}
                </div>

            </div>
        )
    }

    getCampaignsStatistics = () => {
        const values = this.state.DashboardCampaigns
        return (

            Object.keys(values).length > 0 &&
            <div className='flexrow spacearround  mt-5 wrap '>
                < div className='flexrow col-sm-5 col-md-3  spacebetween'>  {values.templateCount ? this.renderCampaignCard( ts2('Total Templates Created',this.props.userLogin.language), values.templateCount.toLocaleString(),  ts2('Total Templates Created',this.props.userLogin.language)):this.renderCampaignCard( ts2('Total Templates Created',this.props.userLogin.language), values.templateCount.toLocaleString(),  ts2('Total Templates Created',this.props.userLogin.language))}</div >
                <div className='cSignInBack' style={{ width: 3, borderRadius: 2 }}></div>
                < div className='flexrow col-sm-5 col-md-3   spacebetween'>  {values.campaigns ? this.renderCampaignCard( ts2('Total Campaigns Sent',this.props.userLogin.language), values.campaigns.toLocaleString(),  ts2('Total Campaigns for the Customers',this.props.userLogin.language)) : this.renderCampaignCard( ts2('Total Campaigns Sent',this.props.userLogin.language), values.campaigns.toLocaleString(),  ts2('Total Campaigns for the Customers',this.props.userLogin.language))}</div >
                <div className='cSignInBack' style={{ width: 3, borderRadius: 2 }}></div>
                < div className='flexrow col-sm-5 col-md-3   spacebetween'>   {values.reach ? this.renderCampaignCard( ts2('Total Campaigns Reached',this.props.userLogin.language), values.reach.toLocaleString(),  ts2('Total Campaigns Reached to the Customers',this.props.userLogin.language)) : this.renderCampaignCard( ts2('Total Campaigns Reached',this.props.userLogin.language), 0,  ts2('Total Campaigns Reached to the Customers',this.props.userLogin.language))}</div >
                <div className='cSignInBack' style={{ width: 3, borderRadius: 2 }}></div>
                < div className='flexrow col-sm-5 col-md-2  spacebetween'>   {values.open ? this.renderCampaignCard( ts2('Total Campaigns Opened',this.props.userLogin.language), values.open.toLocaleString(),  ts2('Total Campaigns Opened by Customers',this.props.userLogin.language)) : this.renderCampaignCard( ts2('Total Campaigns Opened',this.props.userLogin.language), 0,  ts2('Total Campaigns Reached by the Customers',this.props.userLogin.language))}</div >
            </div >

        )

    }
    getHotSpotStatistics = () => {
        const values = this.state.DashboardCampaigns
        console.log(values)
        return (

            Object.keys(values).length > 0 &&
            <div className='flexrow spacebetween  mt-5 wrap '>
                <div className='flexrow col-sm-5 col-md-2  spacebetween ' > {values.totalHS ? this.renderCampaignCard( ts2('HotSpots',this.props.userLogin.language), values.totalHS.toLocaleString(),  ts2('Total Numbers of Hotspots created',this.props.userLogin.language)) : this.renderCampaignCard( ts2('HotSpots',this.props.userLogin.language), 0,  ts2('Total Numbers of Hotspots created',this.props.userLogin.language))}</div >
                <div className='cSignInBack' style={{ width: 3, borderRadius: 2 }}></div>
                < div className='flexrow col-sm-5 col-md-2  spacebetween' >  {values.activeHS ? this.renderCampaignCard( ts2('Hotspot Visited',this.props.userLogin.language), values.activeHS.toLocaleString(),  ts2('Total Hotspots that clients visits',this.props.userLogin.language)) : this.renderCampaignCard( ts2('Hotspot Visited',this.props.userLogin.language), 0,  ts2('Total Hotspots that clients visits',this.props.userLogin.language))}</div >
                <div className='cSignInBack' style={{ width: 3, borderRadius: 2 }}></div>
                < div className='flexrow col-sm-5 col-md-2  spacebetween'> {values.totalVisits ? this.renderCampaignCard( ts2('Total Visit Number',this.props.userLogin.language), values.totalVisits.toLocaleString(),  ts2('Total Hotspots visits number',this.props.userLogin.language)) : this.renderCampaignCard( ts2('Total Visit Number',this.props.userLogin.language), 0,  ts2('Total Hotspots visits number',this.props.userLogin.language))} </div >
                <div className='cSignInBack' style={{ width: 3, borderRadius: 2 }}></div>
                < div className='flexrow col-sm-5 col-md-2  spacebetween'>   {values.unsubscribe ? this.renderCampaignCard( ts2('Total Unsubscribe Customers',this.props.userLogin.language), values.unsubscribe.toLocaleString(),  ts2('Total email unsubscribed',this.props.userLogin.language)) : this.renderCampaignCard( ts2('Total Unsubscribe Customers',this.props.userLogin.language), 0,  ts2('Total email unsubscribed',this.props.userLogin.language))}</div >
            </div >

        )

    }


    render() {
        let language=0;
		if(this.props.userLogin!=null)language=this.props.userLogin.language
        if (this.props.userLogin && !this.state.doneLoading) {

            this.getData();
        }

        let i = 0;
        let accountType = 10;
        if (this.props.userLogin) accountType = this.props.userLogin.accountType;
        return (
            this.state.loadprofiles ?
                <div className='flexcolumn verticalcenter  text-center col-12' style={{ marginTop: '25%' }}>
                    <div className='flexrow center fullWidth '>
                        <Spinner animation="grow" className='m-3' variant="warning" />
                        <Spinner animation="grow" className='m-3' variant="info" />
                        <Spinner animation="grow" className='m-3' variant="danger" />

                    </div>
                    <div className='hint remarks xlowalpha'>{ts2('Loading Data',language)}</div>
                </div> :

                <div className='fullWidth   pl-2 pr-5 mt-3  '>


                    {this.props.isLoggedIn &&
                        <div>
                            <div className='ssstitle mt-5 mb-5 bold'>{ts2('Hello',this.props.userLogin.language)} {this.props.userLogin.fullName}</div>

                            {accountType == 0 && <div className="container-fluid text-center mt-4">
                                {/* Small boxes (Stat box) */}
                                <div className="row">
                                    <div className="col-lg-3 col-6 handCursor" onClick={() => { history.push('/ManageUserRegistration') }}>
                                        {/* small box */}
                                        <div className="small-box bg-info">
                                            <div className="inner">
                                                <h3>{this.state.allList.length > 0 && this.state.allList[0]['trr']}</h3>
                                                <p> {ts2('Total Registration Request',this.props.userLogin.language)}</p>
                                            </div>
                                             
                                            <a className="small-box-footer"> {ts2('More info',this.props.userLogin.language)} <i className="fas fa-arrow-circle-right" /></a>
                                        </div>
                                    </div>
                                    {/* ./col */}
                                    <div className="col-lg-3 col-6 handCursor" onClick={() => { history.push('/ManageUserRegistration') }}>
                                        {/* small box */}
                                        <div className="small-box bg-success ">
                                            <div className="inner ">
                                                <h3>{this.state.allList.length > 0 && this.state.allList[0]['trnr']}</h3>
                                                <p>{ts2('Still Not Registered',this.props.userLogin.language)}</p>
                                            </div>
                                             <a href="#" className="small-box-footer">{ts2('More info',this.props.userLogin.language)} <i className="fas fa-arrow-circle-right" /></a>
                                        </div>
                                    </div>
                                    {/* ./col */}
                                    <div className="col-lg-3 col-6 handCursor " onClick={() => { history.push('/ProfileManagement') }}>
                                        {/* small box */}
                                        <div className="small-box bg-warning">
                                            <div className="inner">
                                                <h3>{this.state.allList.length > 0 && this.state.allList[0]['tc']}</h3>
                                                <p>{ts2('Available Clients',this.props.userLogin.language)}</p>
                                            </div>
                                             
                                            <a className="small-box-footer">{ts2('More info',this.props.userLogin.language)} <i className="fas fa-arrow-circle-right" /></a>
                                        </div>
                                    </div>
                                    {/* ./col */}
                                    <div className="col-lg-3 col-6 handCursor " onClick={() => { history.push('/ProfileManagement') }}>
                                        {/* small box */}
                                        <div className="small-box bg-danger">
                                            <div className="inner">
                                                <h3>{this.state.allList.length > 0 && this.state.allList[0]['tp']}</h3>
                                                <p>{ts2('Customer Profiles Created',this.props.userLogin.language)}</p>
                                            </div>
                                             <a href="#" className="small-box-footer">{ts2('More info',this.props.userLogin.language)} <i className="fas fa-arrow-circle-right" /></a>
                                        </div>
                                    </div>
                                    {/* ./col */}
                                </div>
                            </div>}
                            {accountType != 0 && (accountType == 1 || accountType == 4) && this.getProfileTypes()}


                            <p className='ssstitle bold mb-5 mt-5 '> {ts2('Let Us Start',this.props.userLogin.language)}</p>
                            <Container className='flexrow spacearround  fullWidth mb-5 mt-5 wrap' >
                                {(accountType < 2 || accountType == 4) && this.renderCard(ts2('Add Contacts',this.props.userLogin.language),<img src={addContacts} width={35} className="letUsStartSVG xxxlowalpha"/>, 'success', ts2('Start Uploading your contacts from a CSV file or you can add them on the screen',this.props.userLogin.language), () => { history.push('/ImportProfiles') })}
                                {accountType < 3 && this.renderCard(ts2('Create Templates',this.props.userLogin.language), <img src={createTemplate} width={35} className=" "/>, 'info', ts2('Create your email template and save it to start your first campaign',this.props.userLogin.language), () => { history.push('/CampaignsTemplates') })}
                                {(accountType < 2 || accountType == 3) && this.renderCard(ts2('Send Campaings',this.props.userLogin.language), <img src={sendCampaign} width={35} className="letUsStartSVG xxxlowalpha"/>, 'warning', ts2('Send your campaigns based on your created templates and customer selection',this.props.userLogin.language), () => { history.push('/CustomerCampaigns') })}
                                {accountType < 2 && this.renderCard(ts2('Analyse Statitics',this.props.userLogin.language), <img src={analyseStatistic} width={35} className="letUsStartSVG xxxlowalpha"/>, 'danger', ts2('Start Anyalysing your result by customers area, gender, visits and others',this.props.userLogin.language), () => { history.push('/CampaignStatistics') })}

                            </Container>
                            <p className='ssstitle bold mb-5 mt-5 '>{ts2('Campaigns Statistics',this.props.userLogin.language)}</p>
                            {this.getCampaignsStatistics()}
                            
                            <p className='ssstitle bold mb-5 mt-5 '>{ts2('Hotspots And Email Unsubsription Statistics',this.props.userLogin.language)}</p>
                            {this.getHotSpotStatistics()}
                            {accountType < 2 &&
                                <div className='flexrow spacebetween fullWidth pt-5 pb-5 mt-3 wrap' >
                                    <div className=' col-md-12 col-lg-7 '>
                                        <p className='ssstitle bold mb-3  '>{ts2('Website Visits',this.props.userLogin.language)}</p>
                                        <div   >
                                            <Chart options={this.state.optionHistory} series={this.state.optionHistory.series} type="line" width='100%' />
                                        </div>
                                    </div>
                                    <div className='col-md-10 col-lg-5 '>
                                        <p className='ssstitle bold mb-3  '>{ts2('Top 5 Login Users This Week',this.props.userLogin.language)}</p>

                                        {this.state.otherDashboard.map(record => {
                                            i += 1
                                            return (
                                                <div className={(i === 1 ? ' boxTopRadius5' : ' ') + ' pt-3 pb-3  sboxShadow text-center'}>
                                                    <Badge variant={i == 1 ? 'success' : i == 2 ? 'info' : i == 3 ? 'warning' : i == 4 ? 'primary' : 'dark'} className='col-1'> {i} </Badge>
                                                    <div className='bold'>{record.FullName}</div>
                                                    <div className='flexrow col-12 mt-1 mb-1'>
                                                        <div className='col-6  sremarks'><span className='bold'>{ts2('Company',this.props.userLogin.language)}:   </span>{record.Company}</div>
                                                        <div className='col-6  sremarks'><span className='bold'>{ts2('Account Type',this.props.userLogin.language)}:   </span>{G.getUserType(record.accountTYpe)}</div>
                                                    </div>
                                                </div>
                                            )
                                        })}

                                    </div>
                                </div>
                            }

                            {accountType == 0 && this.getProfileTypes()}

                        </div>
                    }



                </div >
        )
    }
}
//#region "Redux"
const mapStateToProps = state => {

    return {
        userLogin: state.SaveLogin.userData,
    };
};
export default connect(
    mapStateToProps
)(mainContent)
//#endregion


