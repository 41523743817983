import React, { Component } from 'react'
import history from '../components/history'
import Editor from '../components/unlayer/editor'
import { renderPageHeader, renderDivider } from '../components/global';
import { connect } from 'react-redux';
import {Button} from 'react-bootstrap';
import { translateScreen as ts } from '../components/dictionary'
class campaignEditor extends Component {

    //let { _id, name } = (props.location && props.location.state) || {};
    render() {
        let { data } = (this.props.history.location && this.props.history.location.state) || {};
        if (data == null) {
            
            history.push("Campaigns")

            return (<div></div>)
        }
        else {
            return (
                <div className='fullWidth'>
                    {console.log('helloss', data)}
                    {renderPageHeader(data.templateID<0? ts("Create New Template",this.props.userLogin.language  )  :  ts('Change Template',this.props.userLogin.language  ) )}
                   
                    
                    <div className='fullWidth flex fluid' >
                        <Editor data={data} />
                    </div>


                </div>
            )
        }
    }
}
//#region Redux & Export
const mapStateToProps = state => {
    return {
        userLogin: state.SaveLogin.userData,
    };
};
export default connect(
    mapStateToProps
)(campaignEditor);