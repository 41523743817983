import React, { Component } from 'react'
import { Container, Spinner, Badge, Button, Form } from 'react-bootstrap'
import * as G from '../components/global'
import MessageBox from '../components/MessageBox';
import { connect } from 'react-redux';
import { translateScreen as ts, translateScreen2 as ts2, translateDate as td } from '../components/dictionary'
import history from '../components/history';
  class HotspotCategories extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            loadList: true,
            updateMode: false,
            fields: {},
            modalShow: false,
            modalSubject: 'Delete Category Hotspot',
            modalBody: 'Are you sure you want to delete this hotspot Category',
            justinfo: true,
            errors: {},
            deleteID: -1,
            clientsList:[]

        }
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(e) {
        let { fields, errors } = this.state;
        fields[e.target.name] = e.target.value;
        delete errors[e.target.name];

        this.setState({ fields });
    }

    dateFormat = require("dateformat");
    refreshData = async () => {
        this.setState({ loadList: true })
        var params = [];
        if(this.props.userLogin.accountType!==0)
        {
            params.push(['clientID', this.props.userLogin.clientID])
        }
        let result = await G.getData('getHotspotCategories', params);
        result = await result.data;
        let result1 = await G.getData('client/query', []);
        let records = await result1.data;
        var clients = [];
        records.map(element=>{
            clients.push({key:element.id,value:element.companyName,clientslug:element.slug});    
        })
        this.setState({ list: result, loadList: false, clientsList:clients })
        

    }
    componentDidMount() {
        this.refreshData()
        history.listen((location, action) => {
            if(location.pathname == "/HotspotCategories" || location.pathname == "/HotspotCategories/")
            this.setState({ updateMode: false });
            if(location.pathname == "/HotspotCategories/create" || location.pathname == "/HotspotCategories/create/")
            this.setState({ updateMode: true, fields: {} });
          });
    }

    updateHotspot = async (recordID) => {
        console.log("recordID=", recordID)
        const { name,clientID } = this.state.fields;
        let errors = {};
        let formIsValid = true;
        if (!name) { formIsValid = false; errors["name"] = "Category Name cannot be empty"; }

        if (formIsValid) {
            let parameters = [['name', name]]
            if (recordID !== -1) parameters.push(['id', recordID])
            if(this.props.userLogin.accountType!==0)parameters.push(['clientID', this.props.userLogin.clientID])
            else
            parameters.push(['clientID', clientID])
            let result = await G.getData('hotspotCategory/' + (recordID === -1 ? 'insert' : 'update'), parameters)
            console.log(result)
            let success = (result.status === 200)
            if (success) {
                this.setState({ updateMode: false, fields: {} })
                this.refreshData()
                history.push("/HotspotCategories")
            }
        }
    }


    deleteHotspot =async () => {
        let parameters = [['id', this.state.deleteID]]
        var result = await G.getData('hideHotspotCategory', parameters, true)
        result = await result.status;
        if (result === 200) {
            this.setState({ deleteid: -1, modalShow:false })
            this.refreshData()
        }else{
            this.setState({
                modalShow: true,
                modalSubject: 'Error Appeared',
                modalBody: 'Problem Occured while hiding Record. Please Try Again',
                justinfo: true
            })
        }
    }
    ShowCategory = async (id)=>{
        let parameters = [['id', id]]
        var result = await G.getData('showHotspotCategory', parameters, true)
        result = await result.status;
        if (result === 200) {
            this.setState({ deleteid: -1, modalShow:false })
            this.refreshData()
        }else{
            this.setState({
                modalShow: true,
                modalSubject: 'Error Appeared',
                modalBody: 'Problem Occured while updating Record. Please Try Again',
                justinfo: true
            })
        }
    }
    render() {
        const { errors, fields } = this.state;
        
        return (
            <Container fluid className=' '>
                {!this.state.updateMode && <div>
                    <h4 className='mb-5 mt-5'>{ts2('List of Hotspot Categories',this.props.userLogin.language)} </h4>
                    <Button variant='success mt-3 mb-5 ml-3' onClick={() => { this.setState({ updateMode: true, fields: {} });history.push("/HotspotCategories/create") }}>{ts2('Create New Category',this.props.userLogin.language)}</Button>
                    <div className='p-3 mr-3 '>
                        <div className="table-responsive p-0" style={{ maxHeight: 900 }}>
                            <table className="table table-head-fixed text-nowrap table-hover ">
                                <thead>
                                    <tr>
                                        <th>{ts2('Edit',this.props.userLogin.language)}</th>
                                        <th>{ts2('Hide',this.props.userLogin.language)}</th>
                                        <th>{ts2('Registration form',this.props.userLogin.language)}</th>
                                        <th> UID</th>
                                        <th>{ts2('Category Name',this.props.userLogin.language)}</th>
                                        <th>{ts('Client Name',this.props.userLogin.language)}</th>
                                        <th>{ts('Created In',this.props.userLogin.language)}</th>
                                        <th>{ts2('Hotspots Numbers',this.props.userLogin.language)}</th>

                                    </tr>
                                </thead>


                                {!this.state.loadList && <tbody>
                                    {
                                        (this.state.list).map((item) => {
                                            var clientslug = "";
                                            this.state.clientsList.filter(val => val.key == item.clientID).map(filteredName => (
                                                clientslug = filteredName.clientslug 
                                            ))
                                            return (
                                                <tr key={item.id}>
                                                    <td className='xxxtitle whiteColor handCursor'
                                                        onClick={() => {
                                                            let record = { ...item }
                                                            record.creationdate = this.dateFormat(item.creationDate, "dddd, mmm dd yyyy h:MM tt")
                                                            this.setState({ updateMode: true, fields: { ...record } });
                                                            history.push("/HotspotCategories/edit")
                                                        }}>
                                                        <Badge variant='info'>{ts2('Edit category',this.props.userLogin.language)}</Badge>
                                                    </td>
                                                    <td className='xxxtitle whiteColor handCursor'
                                                        onClick={() => {
                                                                
                                                                if (item.hide !== 1) {
                                                                    this.setState({
                                                                        modalShow: true,
                                                                        modalSubject: ts2('Hide category',this.props.userLogin.language),
                                                                        modalBody: ts2('Are you sure you want to delete this hotspot Category?',this.props.userLogin.language),
                                                                        justinfo: false,
                                                                        deleteID: item.id
                                                                    })
                                                                } else {
                                                                    this.ShowCategory(item.id)
                                                                }


                                                        }}>
                                                            {item.hide!==1 &&
                                                        <Badge variant='danger'>{ts2('Hide category',this.props.userLogin.language)}</Badge>}
                                                        {item.hide===1 && <Badge variant='danger'>{ts2('Show category',this.props.userLogin.language)}</Badge>}
                                                    </td>
                                                    <td className='xxxtitle whiteColor handCursor' onClick={()=>{navigator.clipboard.writeText(G.nodeServerSite+"registercustomer?mode=ver&lang=fr&clientID=" +clientslug +"&categoryID=" + item.uid+"&btncolor=0000ff")}}><Badge variant='info'>{ts2('Copy',this.props.userLogin.language)}</Badge></td>
                                                    <td>{item.uid}</td>
                                                    <td>{item.name}</td>
                                                    <td>
                                                    {this.state.clientsList.filter(val => val.key == item.clientID).map(filteredName => (
                                                <span>
                                                  { filteredName.value }
                                                </span>
                                              ))}</td>
                                                    <td>{td(this.dateFormat(item.creationDate, 'dddd, mmm dd yyyy h:MM tt'),this.props.userLogin.language)}</td>
                                                    <td className='  '>{item.uses === 0 ? "-" : G.countItems(item.uses, 'Hotspot') + ' ' + ts2('Created',this.props.userLogin.language)}</td>


                                                </tr>)
                                        })
                                    }

                                </tbody>
                                }
                            </table>
                            {this.state.loadList &&
                                <div className='flexcolumn center verticalcenter p-5 fullWidth hint '>
                                   {ts2('Loading Categories',this.props.userLogin.language)} 
                                    <Spinner animation="border" className='mt-3' variant="warning" />

                                </div>
                            }
                        </div>
                    </div>


                </div>}
                {
                    this.state.updateMode && <div>
                        <h4 className='mb-5 mt-5'>{ts2('Edit Hotspot Name',this.props.userLogin.language)}</h4>
                        <Form>
                            <div className='col-sm-12 col-md-8 '>
                                {G.renderTextField("UID", 'text', "Category ID will be generated Automatically", this.handleChange, fields, errors, null, '', true,this.props.userLogin.language)}
                                {G.renderTextField("Creation Date", 'text', "", this.handleChange, fields, errors, null, '', true,this.props.userLogin.language)}
                                {G.renderTextField("Name", 'text', "Add the Category Name Here", this.handleChange, fields, errors, null, '',false, this.props.userLogin.language)}
                                {this.props.userLogin.accountType===0 && G.renderDropDownWithId("Client Selection","clientID",this.state.clientsList,this.handleChange, fields, errors,null,false,this.props.userLogin.language)}
                            </div>
                        </Form>
                        <Button variant='success ml-3 mt-5' onClick={() => {   fields.id == null ? this.updateHotspot(-1) : this.updateHotspot(fields.id) }}>{ts2('Save',this.props.userLogin.language)}</Button>
                        <Button variant='warning ml-3 mt-5' onClick={() => { this.setState({ updateMode: false });history.go(-1) }}>{ts('Cancel',this.props.userLogin.language)}</Button>
                    </div>
                }
                <MessageBox
                    show={this.state.modalShow}
                    title={this.state.modalSubject}
                    body={this.state.modalBody}
                    onHide={() => { this.setState({ modalShow: false }) }}
                    middle={!this.state.justinfo && {
                        action: () => {
                            this.deleteHotspot()


                        }, text: ts2('Hide category',this.props.userLogin.language), variant: 'danger'
                    }}
                    right={{ action: () => { this.setState({ modalShow: false }) }, text: this.state.justinfo ? 'OK' : ts('Cancel',this.props.userLogin.language), variant: 'success' }}


                />
            </Container>
        )
    }
}
const mapStateToProps = state => {

    return {
        userLogin: state.SaveLogin.userData,
    };
};
export default connect(
    mapStateToProps
)(HotspotCategories)
