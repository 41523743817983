import React, { Component } from 'react'
import { Image, Button, Badge, Modal, Form, Spinner } from 'react-bootstrap';
import * as G from '../components/global'
import history from '../components/history';
import { connect } from 'react-redux';
//import template from '../components/assets/Template_13.html'
import Autocomplete from '../components/otherComponents/Autocomplete';
import ReactPaginate from 'react-paginate';
import emailtempl from '../components/assets/emailtempl.png'
import { translateScreen as ts, translateScreen2 as ts2, translateDate as td } from '../components/dictionary'
import {DateRangePicker} from 'rsuite';
class CustomerCampaigns extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeScreen: 0,
            allCompanies: [],
            activeCompany: {},
            ClientTemplates: [],
            activeTemplate: {},
            modalShow: false,
            modalShow3: false,
            TestMessageSent:false,
            TestMessageSentError:false,
            fields: {},
            errors: {},
            CountryList: [],
            CityList: [],
            categoriesList:[],
            languagesList:[],
            availableGender: [],
            filteredCountryList: [],
            filteredCategoriesList: [],
            filteredLanguagesList:[],
            filteredCityList: [],
            profileList: [],
            unsubscribedprofileList:[],
            offset: 0,
            perPage: 9,
            currentPage: 0,
            pageCount: 0,
            ShowProfilesList: false,
            ShowUnsubscribedProfilesList: false,
            filterString: '',
            filterDescr: '',
            loadTemplates: true,
            loadProfiles: false,
            scheduleCampaign: false
            , sendTest: false
            , scheduledate: new Date()
            , successfullySentCampaign: false
            , filterCampaingList: []
            , filterCampaingListDetails: []
            , filteredCampaignDataList: []
            , filterShowProfiles: false
            , filterCampaignIsLoading: false
            , didMakeQuery: false
            , startCampaignLoading: false
            , allEmailsskippedMessage: ''
            , filterShowHotspot: false,
            filterHSList: []
            , filterHSIsLoading: false
            ,sortvalue:"newest"
            ,startdate:''
            ,enddate:''
            , filteredHSDataListHS: []
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleUpdateAutoComplete = this.handleUpdateAutoComplete.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
    }
    //#region Handle Change, Autocomplete GetData
    componentDidMount() {

        history.listen((location, action) => {
            if(location.pathname == "/CustomerCampaigns" || location.pathname == "/CustomerCampaigns/")
                this.setState({ activeScreen: 0 });
            else if(location.pathname == "/CustomerCampaigns/list" || location.pathname == "/CustomerCampaigns/list/")
            this.setState({ activeScreen: 1, successfullySentCampaign: false });
          });
    }
        handleUpdateAutoComplete(e, name, value) {
        let { fields } = this.state;
        fields[name] = value;
        if (name === 'country') {
            let countryArray = this.state.filteredCountryList;
            if (countryArray.filter(element => element === value).length === 0) countryArray.push(value)
            this.setState({ filteredCountryList: countryArray })
        }
        if (name === 'category') {
            let categoryArray = this.state.filteredCategoriesList;
            if (categoryArray.filter(element => element === value).length === 0) categoryArray.push(value)
            this.setState({ filteredCategoriesList: categoryArray })
        }
        if (name === 'language') {
            let lanaugeArray = this.state.filteredLanguagesList;
            if (lanaugeArray.filter(element => element === value).length === 0) lanaugeArray.push(value)
            this.setState({ filteredLanguagesList: lanaugeArray })
        }
        if (name === 'city') {
            let cityArray = this.state.filteredCityList;
            if (cityArray.filter(element => element === value).length === 0) cityArray.push(value)
            this.setState({ filteredCityList: cityArray })
        }
        fields[name] = ''

        this.setState({ fields })
    }
    handleChange(e) {
        let { fields, errors } = this.state;
        fields[e.target.name] = e.target.value;
        delete errors[e.target.name];

        if (e.target.name === 'availablecampaigns') { this.searchHS() }
        this.setState({ fields });
    }
    dateFormat = require("dateformat");

    refreshDataClients = async () => {

        if (this.props.userLogin == null || this.state.allCompanies.length > 0) return;
        let paramArr = [['addColumns', ' (select count(id) from campaigntemplate where clientid = a.id) Count']]

        if (this.props.userLogin.clientID > 0) paramArr.push(['filter', " a.id = " + this.props.userLogin.clientID])

        let result = await G.getData('client/query', paramArr);
        result = await result.data;
        this.setState({ allCompanies: result })
        if (result.length > 0) {
            this.setState({ activeCompany: result[0] })
            this.refreshData(result[0].id)
        }


    }
    getDistinctValues = async () => {

        let result = await G.getData('GetDistinctProfilesTypes', [['clientID', this.state.activeCompany.id]], true);
        result = await result.data

        let co = []; let ci = []; let ge = []
        result.forEach(element => {
            if (element.type === 'co') { co.push(element.name) }
            if (element.type === 'ci') { ci.push(element.name) }
            if (element.type === 'ge') { ge.push(ts2(element.name == 0 ? 'Only Males' : (element.name == 1?'Only Females':'not Defined'), this.props.userLogin.language)) }
        })


        this.setState({ CountryList: co, CityList: ci, availableGender: ge })

    }
    searchHS = async () => {

        let resultHS = await G.getData('getHSToFilter', [['clientID', this.state.activeCompany.id], ['campaignID', this.state.fields['availablecampaigns'].split('-')[0].trim()]]);
        resultHS = await resultHS.data

        let array = []
        resultHS.map(element => { array.push(element.hotspotID + ' - ' + element.name) })
        if (resultHS.length > 0) {
            let { fields } = this.state;
            fields['availablehotspots'] = array[0];
            this.setState({ fields });
        }
        this.setState({ filteredHSDataListHS: array })


    }
    //#endregion

    //#region listTemplates,renderTemplateButton
    dateFormat = require("dateformat");
    openCampaignEditor = async(templateID) => {

        this.getDistinctValues();
        this.setState({ activeScreen: 2, activeTemplate: templateID })
        let array = []
    array = await G.getData("hotspotCategory/query", [['filter','(hide is null or hide=0) and clientID=' + this.state.activeCompany.id]])
    array = await array.data
    this.setState({ hotspotCategory: array })
    let arrayNames = [];
    array.map(element => {
      arrayNames.push(element.name)
    })
    this.setState({categoriesList:arrayNames, languagesList:['fr','en','nl','de']});

    }
    listTemplates = (companyId, companyDetails) => {

        this.setState({ ClientTemplates: [], unsubscribeddisplayedList:[], displayedList: [], activeCompany: companyDetails })
        this.refreshData(companyId);
        this.setState({ activeScreen: 1 })

    }
    /*renderTemplateButton = (id, name, subject, creationDate = '') => {
        return (
            <div className='flexrow verticalcenter select'>
                <div className='m-3 handCursor text-center select' style={{ borderRadius: 8 }} onClick={() => { this.openCampaignEditor({ id, subject, creationDate, name });history.push("/CustomerCampaigns/list/send") }}>
                    <img src={emailtempl} width="180" className='' style={{ objectFit: 'contain' }} />
                    <div className='text-center pt-3'>{name}</div>
                    <p className='midremarks hint'>{ts('Subject', this.props.userLogin.language)}: {subject}</p>
                    <Badge variant='light' className=' remarks'>

                        {creationDate !== '' ? td(this.dateFormat(creationDate, 'dddd , dd mmmm yyyy'), this.props.userLogin.language) : ''}
                    </Badge>
                </div>
                <div className='cunlayer ' style={{ height: '70%', width: 5, borderRadius: 5 }}></div>
            </div>
        )
    }*/
    renderTemplateButton = (id, name, subject, creationDate = '') => {
        return (
        <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6 templatecard">
            <div className="info-box handCursor" style={{width:"100%"}}>
              <span className="info-box-icon bg-info" style={{padding:"0 30px"}} onClick={() => { this.openCampaignEditor({ id, subject, creationDate, name });history.push("/CustomerCampaigns/list/send")}}><i className="far fa-envelope"></i></span>
              <div className="info-box-content" style={{width:"100%"}}>
                <span className="info-box-text bold" style={{width:"70%", lineHeight:"1.5"}} onClick={() => { this.openCampaignEditor({ id, subject, creationDate, name });history.push("/CustomerCampaigns/list/send")}}>{name}</span>
                <span className="info-box-text" style={{width:"70%", lineHeight:"1.5"}} onClick={() => { this.openCampaignEditor({ id, subject, creationDate, name });history.push("/CustomerCampaigns/list/send")}}>{ts('Subject',this.props.userLogin.language)}: {subject}</span>
                <span className="info-box-number" style={{fontStyle:"italic", color:"#1998AB",marginTop:"0px"}} onClick={() => { this.openCampaignEditor({ id, subject, creationDate, name });history.push("/CustomerCampaigns/list/send")}}> {creationDate !== '' ? td(this.dateFormat(creationDate, 'dd mmmm yyyy'),this.props.userLogin.language) : ''}</span>
              </div>
            </div>
        </div>
        )
    }
    renderQueryProfiles = (name) => {
        return (<Button variant='success' className=' ' onClick={this.QueryProfiles}>
            {this.state.loadProfiles && <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className='mr-2'
                disabled={this.state.successfullySentCampaign}
            />}
            {name}</Button>)

    }
    //#endregion

    //#region Grid PRofiles
    refreshData = async (company) => {
        this.setState({ loadTemplates: true })
        var filter = 'clientID = ' + company + " and archived<>1";
        if(this.state.sortvalue == "newest")
        {
            
            
            filter += " order by creationdate desc"
            
        }
        else{
            filter += " order by creationdate"
        }
        let result = await G.getData('campaigntemplate/query', [['filter', filter]]);
        result = await result.data;
        this.setState({ ClientTemplates: result })
        
        this.setState({ loadTemplates: false })
    }
    setdisplayList(startnumber) {
        let sliceArray = [...this.state.profileList]

        if (this.state.profileList.length > this.state.perPage) {
            sliceArray = sliceArray.slice(startnumber * this.state.perPage, startnumber * this.state.perPage + this.state.perPage)

        }
        this.setState({ displayedList: sliceArray })
    }
    setdisplayUnsubscribedList(startnumber) {
        let sliceArray = [...this.state.unsubscribedprofileList]

        if (this.state.unsubscribedprofileList.length > this.state.perPage) {
            sliceArray = sliceArray.slice(startnumber * this.state.perPage, startnumber * this.state.perPage + this.state.perPage)

        }
        this.setState({ unsubscribeddisplayedList: sliceArray })
    }
    onKeyDown = e => {

        if (e.keyCode === 13) {
            e.preventDefault();

            this.filterGrid()
        }
    }
    filterGrid = () => {
        let filter = this.state.fields['filter']

        if (filter === "" || filter == null) {
            this.setState({ profileList: [...this.state.originalList] }, () => { this.setdisplayList(0) })

        } else {
            this.setState({
                profileList: [...this.state.originalList.filter(element =>
                    element.firstName.toLowerCase().includes(filter.toLowerCase())
                    || element.lastName.toLowerCase().includes(filter.toLowerCase())
                    || element.emailAddress.toLowerCase().includes(filter.toLowerCase())
                    || element.city.toLowerCase().includes(filter.toLowerCase())
                    || element.country.toLowerCase().includes(filter.toLowerCase())
                    || element.mobile.toLowerCase().includes(filter.toLowerCase())
                )]
            }, () => { this.setdisplayList(0) })

        }
    }
    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.setdisplayList(selectedPage)
        });

    };
    //unsubscribed list
    onKeyDownUnsubscribed = e => {

        if (e.keyCode === 13) {
            e.preventDefault();

            this.filterUnsubscribedGrid()
        }
    }
    filterUnsubscribedGrid = () => {
        let filter = this.state.fields['unsubscribedfilter']

        if (filter === "" || filter == null) {
            this.setState({ unsubscribedprofileList: [...this.state.originalsunsubscribedList] }, () => { this.setdisplayUnsubscribedList(0) })

        } else {
            this.setState({
                unsubscribedprofileList: [...this.state.originalsunsubscribedList.filter(element =>
                    element.firstName.toLowerCase().includes(filter.toLowerCase())
                    || element.lastName.toLowerCase().includes(filter.toLowerCase())
                    || element.emailAddress.toLowerCase().includes(filter.toLowerCase())
                    || element.city.toLowerCase().includes(filter.toLowerCase())
                    || element.country.toLowerCase().includes(filter.toLowerCase())
                    || element.mobile.toLowerCase().includes(filter.toLowerCase())
                )]
            }, () => { this.setdisplayUnsubscribedList(0) })

        }
    }
    handleUnsubscribedPageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.setdisplayUnsubscribedList(selectedPage)
        });

    };
    QueryProfiles = async () => {
        this.setState({ loadProfiles: true })
        this.setState({ profileList: [] })
        let { fields, filteredCountryList, filteredCityList, filterCampaingList, filterHSList,filteredCategoriesList, filteredLanguagesList } = this.state
        console.log(fields);
        let { age } = this.state.fields
        let errors = {};
        let filter = "  clientID =  " + this.state.activeCompany.id
        let filterDescr = ''
        let filterage = ''
        if (age != null) {
            if (age !== '') {
                let varAge = age.replace(/ /g, '')
                varAge = varAge.split(",")
                let Error = '';

                varAge.map(element => {
                    if (isNaN(element.replace(/-/g, ''))) { Error = "Invalid Age Fitler. Please Enter Age Or Age Ranges seperated by comma ','" }
                    else {
                        if (element !== '') {
                            if (element.includes("-")) {
                                element.split("-").map(subElement => {
                                    if (isNaN(subElement)) { Error = "Invalid Age Fitler. Please Enter Age Or Age Ranges seperated by comma ','" }
                                })
                            }


                            if (Error === '') {

                                if (element.includes("-")) {
                                    filterage += ' or TIMESTAMPDIFF(YEAR, age, CURDATE()) between ' + element.split("-")[0] + ' and ' + element.split("-")[1]
                                } else {
                                    filterage += ' or TIMESTAMPDIFF(YEAR, age, CURDATE()) = ' + element
                                }
                            }
                        }

                    }
                })

                if (Error !== '') {
                    errors['age'] = Error
                    this.setState({ errors })
                    return
                }
                filterDescr = ", Age Ranges (" + age + ") "
            }
        }
        if (filterage.trim() !== '') filter += ' and ( ' + filterage.trim().substring(2) + ')'

        if (fields['gender'] === (this.props.userLogin.language === 0 ? 'Only Males' : 'Seulement des mâles')) { filter += " and gender=0"; filterDescr += ', Only Males' }
        if (fields['gender'] === (this.props.userLogin.language === 0 ? 'Only Females' : "Seulement des femelle")) { filter += " and gender=1"; filterDescr += ', Only Females' }
        if (fields['gender'] === (this.props.userLogin.language === 0 ? 'not Defined' : "not Defined")) { filter += " and gender=2"; filterDescr += ', not Defined' }
        filter += filteredCountryList.length === 0 ? '' : " and country in (" + filteredCountryList.map(element => "'" + element.replace("'","''") + "'") + ")"
        filterDescr += filteredCountryList.length === 0 ? '' : ", countries in (" + filteredCountryList.map(element => "'" + element.replace("'","''") + "'") + ")"
        filter += filteredCategoriesList.length === 0 ? '' : " and  id in (select customerID from campaigncustomerhotspot where hotspotID in(select hotspotId from hotspotcategoryrelation where categoryId in (select id from hotspotcategory where name in (" + filteredCategoriesList.map(element => "'" + element.replace("'","''") + "'") + "))))"
        filter += filteredLanguagesList.length === 0?'':" and language in ("+filteredLanguagesList.map(element => "'" + element.replace("'","''") + "'")+")"
        console.log("filter", filter)
        filter += filteredCityList.length === 0 ? '' : " and city in (" + filteredCityList.map(element => "'" + element.replace("'","''") + "'") + ")"
        filterDescr += filteredCityList.length === 0 ? '' : ", cities in (" + filteredCityList.map(element => "'" + element.replace("'","''") + "'") + ")"
        let campFilters = ''; let campFitlersDescr = ''
        if (filterCampaingList.length > 0) filterCampaingList.map(element => campFilters += element.code);
        if (filterCampaingList.length > 0) filterCampaingList.map(element => campFitlersDescr += element.desc);
        filter += campFilters
        filterDescr += campFitlersDescr

        if (filterHSList.length > 0) filterHSList.map(element => campFilters += element.code);
        if (filterHSList.length > 0) filterHSList.map(element => campFitlersDescr += element.desc);
        filter += campFilters
        filterDescr += campFitlersDescr
        let subscribedfilter = " and emailAddress not in (select emailAddress from unsubscribe)"
        let unsubscribedfilter = " and emailAddress in (select emailAddress from unsubscribe)"
        this.setState({ filterString: filter, filterDescr: filterDescr })
        let result = await G.getData('getprofiles/profile', filter === '' ? [] : [['filter', filter + subscribedfilter]]);
        let unsubscribedresult = await G.getData('getprofiles/profile', filter === '' ? [] : [['filter', filter + unsubscribedfilter]]);
        result = await result.data;
        unsubscribedresult = await unsubscribedresult.data;
        let pageCounts = result.length / this.state.perPage
        this.setState({ profileList: result, pageCount: pageCounts, originalList: result, unsubscribedprofileList: unsubscribedresult, originalsunsubscribedList: unsubscribedresult })
        this.setdisplayList(0)
        this.setdisplayUnsubscribedList(0);
        this.setState({ loadProfiles: false, didMakeQuery: true })
        console.log('filteredCategoriesList',filteredCategoriesList);
    }

    //#endregion

    //Region startCampign
    startCampaign = async () => {
        this.setState({ modalShow: false })

        const clientID = this.state.activeCompany.id;
        const campaignTemplateID = this.state.activeTemplate.id;
        const toBeSchedule = '0'
        const scheduleDate = null
        const channel = 'Email'
        const channelID = 0
        const creationUser = this.props.userLogin.id
        const filter = this.state.filterString
        const campaignSubject = this.state.activeTemplate.subject
        this.setState({ allEmailsskippedMessage: '' })
        let parameters = [['clientID', clientID],
        ['campaignTemplateID', campaignTemplateID],
        ['toBeSchedule', toBeSchedule],
        ['scheduleDate', scheduleDate],
        ['channel', channel],
        ['channelID', channelID],
        ['creationUser', creationUser],
        ['filter', filter],
        ['filterDescr', this.state.filterDescr],
        ['campaignSubject', campaignSubject],
        ['fullName', this.props.userLogin.fullName],
        ['username', this.props.userLogin.username],
        ['senderEmail', this.state.fields['senderemail']]
        ]
        this.setState({ startCampaignLoading: false })
        this.setState({ successfullySentCampaign: true })
        
        var result = await G.getData('SendCampaign', parameters)
        if (await result.status === 200) {
           // this.setState({ successfullySentCampaign: true })
        }
        if (await result.status === 601) {
            this.setState({ allEmailsskippedMessage: 'An error occured and the campaign was not sent!' })
        }
        


    }
    sendTestCampaign = async()=>{
        let {fields, errors} = this.state;
        
        const clientID = this.state.activeCompany.id;
        const campaignTemplateID = this.state.activeTemplate.id;
        var b = true;
        if(fields['testemail'] == undefined || fields['testemail']==null || fields['testemail']=='')
        {
            errors['testemail'] = 'This field is required';
            this.setState({fields, errors});
            b = false;
        }
        if(fields['testlanguage'] == undefined || fields['testlanguage']==null || fields['testlanguage']=='')
        {
            errors['testlanguage'] = 'This field is required';
            this.setState({fields, errors});
            b = false;
        }
        if(b){
            
            const channel = 'Email'
        const channelID = 0
        const creationUser = this.props.userLogin.id
        const filter = this.state.filterString
        const campaignSubject = this.state.activeTemplate.subject
        let parameters = [['clientID', clientID],
        ['campaignTemplateID', campaignTemplateID],
        ['channel', channel],
        ['channelID', channelID],
        ['creationUser', creationUser],
        ['filter', filter],
        ['filterDescr', this.state.filterDescr],
        ['campaignSubject', campaignSubject],
        ['fullName', this.props.userLogin.fullName],
        ['username', this.props.userLogin.username],
        ['testemail', this.state.fields['testemail']],
        ['testlanguage', this.state.fields['testlanguage']],
        ['senderEmail', this.state.fields['senderemail']]
        ]
        var result = await G.getData('SendTestCampaign', parameters)
        if (await result.status === 200) {
            this.setState({ modalShow3: false, TestMessageSent:true })
        }
        else{
            this.setState({ modalShow3: false, TestMessageSent:false, TestMessageSentError:true })
        }
        }
    }
    startCampaignLater = async () => {
        this.setState({ modalShow2: false })

        const clientID = this.state.activeCompany.id;
        const campaignTemplateID = this.state.activeTemplate.id;
        const toBeSchedule = '1'
        const scheduleDate = Date.parse(this.state.scheduledate)
        const channel = 'Email'
        const channelID = 0
        const creationUser = this.props.userLogin.id
        const filter = this.state.filterString
        const campaignSubject = this.state.activeTemplate.subject
        this.setState({ allEmailsskippedMessage: '' })
        let parameters = [['clientID', clientID],
        ['campaignTemplateID', campaignTemplateID],
        ['toBeSchedule', toBeSchedule],
        ['scheduleDate', scheduleDate],
        ['channel', channel],
        ['channelID', channelID],
        ['creationUser', creationUser],
        ['filter', filter],
        ['filterDescr', this.state.filterDescr],
        ['campaignSubject', campaignSubject],
        ['fullName', this.props.userLogin.fullName],
        ['username', this.props.userLogin.username],
        ['senderEmail', this.state.fields['senderemail']]
        ]
        this.setState({ successfullySentCampaign: true })
        this.setState({ startCampaignLoading: false })
        var result = await G.getData('SendCampaign', parameters)
        if (await result.status === 200) {
            
        }
        if (await result.status === 601) {
            this.setState({ allEmailsskippedMessage: 'All target emails has been unsubscribed and cannot get your emails' })
        }
        


    }
    handleDateRangeChange = async(range)=>{
        /*var company = this.props.userLogin.site.id;
        let result = await getData('campaigntemplate/query', [['filter', 'clientID = ' + company + ' and creationDate>' + startdate]]);
        result = await result.data;
        this.setState({ ClientTemplates: result })
        this.setState({ loadingTemplates: false })*/
        if(range!=undefined && range!=null && range[0]!=undefined && range[0]!="" && range[1]!=undefined && range[1]!="")
        {
            var startdate = new Date(range[0])
            var enddate = new Date(range[1])
            var starts = td(this.dateFormat(startdate,"yyyy-mm-dd"));
            var ends = td(this.dateFormat(enddate,"yyyy-mm-dd"))
            var filter = 'clientID = ' + this.state.activeCompany.id + ` and DATEDIFF(creationdate,'`+starts + `') >= 0 and DATEDIFF(creationdate,'`+ends + `') <= 0`
            if(starts == ends)
            {
                filter = 'clientID = ' + this.state.activeCompany.id + ` and DATEDIFF(creationdate,'`+starts + `') = 0`
            }
            let result = await G.getData('campaigntemplate/query', [['filter', filter]]);
        result = await result.data;
        this.setState({ ClientTemplates: result, startdate:starts, enddate:ends })
        this.setState({ loadingTemplates: false })
        this.setdisplayList(0)
        }
        else{
            this.setState({ startdate:'', enddate:'' })
            this.refreshData(this.state.activeCompany.id)
        }
           setTimeout(function(){
            

           },500); 
        
    }
    handleSort = async (e)=>{
        var sortval = e.target.value;
        this.setState({ loadingTemplates: true })
        var filter ='clientID = ' + this.state.activeCompany.id + " and archived<>1" ;
        if(this.state.startdate!='' && this.state.enddate!='')
            {
                var starts = this.state.startdate;
                var ends = this.state.ends;
                filter = 'clientID = ' + this.state.activeCompany.id + ` and archived<>1 and DATEDIFF(creationdate,'`+starts + `') >= 0 and DATEDIFF(creationdate,'`+ends + `') <= 0`
                if(starts == ends)
                {
                    filter = 'clientID = ' + this.state.activeCompany.id + ` and archived<>1 and DATEDIFF(creationdate,'`+starts + `') = 0`
                }
            }
        if(sortval == "newest")
        {
            
            
            filter += " order by creationdate desc"
            
        }
        else{
            filter += " order by creationdate"
        }
            let result = await G.getData('campaigntemplate/query', [['filter', filter]]);
            result = await result.data;
                this.setState({ ClientTemplates: result, sortvalue: sortval })
            this.setState({ loadingTemplates: false })
            setTimeout(function(){
                var searchfield = document.getElementById("searchfield");
            if(searchfield!=undefined && searchfield.value !="")
            {
                var nodes = document.getElementsByClassName('templatecard');
           Array.prototype.map.call(nodes, (elem)=>{
            if (elem.innerText.toLowerCase().includes(searchfield.value)) {
                elem.style.display = "block";
            } else {
                elem.style.display = "none";
            }
        });
            }
            },500);
            this.setdisplayList(0)
    }
    handleSearchKeyUp = (e)=>{
       // console.log("Search value",e.target.value)
       var filter = e.target.value
       var nodes = document.getElementsByClassName('templatecard');
       Array.prototype.map.call(nodes, (elem)=>{
        if (elem.innerText.toLowerCase().includes(filter)) {
            elem.style.display = "block";
        } else {
            elem.style.display = "none";
        }
    })
            
            /*for (i = 0; i < nodes.length; i++) {
                if (nodes[i].innerText.toLowerCase().includes(filter)) {
                    nodes[i].style.display = "block";
                } else {
                    nodes[i].style.display = "none";
                }
            }*/
    }
    //endregion
    render() {

        let l = (this.props.userLogin.language === 0)
        if (this.state.allCompanies != null) {

            if (this.state.allCompanies.length === 0) { this.refreshDataClients(); }

        }
        const { fields, errors } = this.state
        //if (!fields['senderemail'])fields['senderemail'] = this.props.userLogin.senderEmail;
        return (
            <div style={{ width: '100%' }}>

                {this.state.activeScreen === 0 &&
                    <div>

                        <h4 className='mb-5 mt-5'>{ts('Available Clients', this.props.userLogin.language)}</h4>
                        <div className='p-3  '>
                            <div className="table-responsive p-0" style={{ maxHeight: 900 }}>
                                <table className="table table-head-fixed text-nowrap table-hover ">
                                    <thead>
                                        <tr>
                                            <th>Action</th>
                                            <th>ID</th>
                                            <th>{ts('Client Name', this.props.userLogin.language)}</th>
                                            <th>{ts('Contact Person', this.props.userLogin.language)}</th>
                                            <th>{ts('Phone', this.props.userLogin.language)}</th>
                                            <th>{ts('Email Address', this.props.userLogin.language)}</th>
                                            <th>{ts('Created In', this.props.userLogin.language)}</th>
                                            <th>{ts('Number of Campaigns', this.props.userLogin.language)} </th>


                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            (this.state.allCompanies).map((item) => {

                                                return (
                                                    <tr key={item.id}>
                                                        <td className='xxxtitle shiteColor handCursor'
                                                            onClick={() => { fields['senderemail'] = item.senderEmail;this.setState({fields});this.listTemplates(item.id, item);history.push("/CustomerCampaigns/list") }}>
                                                            <Badge variant='info'>{ts('Send Campaigns', this.props.userLogin.language)}</Badge>
                                                        </td>
                                                        <td>{item.id}</td>
                                                        <td>{item.companyName}</td>
                                                        <td>{item.contactPerson}</td>
                                                        <td>{item.contactPersonNo}</td>
                                                        <td>{item.email}</td>
                                                        <td>{td(this.dateFormat(item.addDate, 'dddd, mmm dd yyyy h:MM tt'), this.props.userLogin.language)}</td>
                                                        <td classname='text-center'>{item.Count === 0 ? '-' : item.Count}</td>


                                                    </tr>)
                                            })
                                        }

                                    </tbody>
                                </table>
                            </div>

                        </div>

                    </div>

                }

                {this.state.activeScreen === 1 &&
                    G.renderPageContainer(
                        <div>
                             <section className='content-header'>
                                <div className='container-fluid'>
                                    <div className='row mb-2'>
                                        <div className='col-sm-6'>
                            <Button className='mb-4 mt-5' variant='warning' onClick={() => { history.go(-1) }} >{ts('Cancel / Return', this.props.userLogin.language)}</Button>
                                        </div>
                            </div>
                                    
                                    <div className='row'>
                                    <div className='col-sm-6'>
                            <h3>{ts('Select template from the available list', this.props.userLogin.language)}&nbsp;&nbsp;
                            <DateRangePicker onChange={(value)=>{this.handleDateRangeChange(value)}}  placeholder={ts("Filter by date range", this.props.userLogin.language)} />
                            </h3>
                                    </div>
                                    <div className="col-md-12" style={{height:"10px"}}></div>
                                    </div>
                                    <div className="row">
                            <div className="form-group col-md-3">
                            <label for="searchfield">Search</label>
                            <div class="input-group">
                                
                  <input id="searchfield" type="text" class="form-control" onKeyUp={(e)=>this.handleSearchKeyUp(e)}/>
                  <div class="input-group-append">
                    <span class="input-group-text"><i class="fas fa-search"></i></span>
                  </div>
                </div>
                            </div>
                            <div className="col-md-9">
                                <div style={{float:"right"}}>
                                    <label for="sortfield">Sort by</label>
                                <select id="sortfield" name="sortfield" onChange={this.handleSort} className="custom-select">
                                    <option></option>
                                    <option value="newest">{ts("Newest to oldest",this.props.userLogin.language)}</option>
                                    <option value="oldest">{ts("Oldest to newest",this.props.userLogin.language)}</option>
                                </select>
                                </div>
                                <div style={{clear:"both"}}></div>
                            </div></div>
                                </div>
                            </section>            
                                <div className='row'>

                            {!this.state.loadTemplates &&
                                this.state.ClientTemplates.length === 0 ?
                                <div className='flexcolumn center miniContentHeight verticalcenter hint sstitle pb-5 '>
                                    {ts('No Templates Available', this.props.userLogin.language)}
                                    <h6 onClick={() => { history.push("/CampaignsTemplates") }} className='ssstitle link'>{ts('Create Client First Campaign Template', this.props.userLogin.language)}</h6>
                                </div>
                                :
                                <>
                                        {this.state.ClientTemplates.map(element => {
                                            
                                            return (this.renderTemplateButton(element.id, element.name, element.subject, element.creationDate))

                                        })}
                                    {!this.state.ClientTemplates &&
                                    <div className='mt-5 pt-5 text-center'>{ts('Template not found', this.props.userLogin.language)}. <span onClick={() => { history.push("/CampaignsTemplates") }} className='link'>{ts('Create New Template', this.props.userLogin.language)}</span></div>
    }
                                </>
                            }

                            {this.state.loadTemplates &&
                                <div className='flexcolumn center verticalcenter p-5 fullWidth hint '>
                                    {ts('Loading Clients List', this.props.userLogin.language)} {this.state.activeCompany ? ts('for ', this.props.userLogin.language) + this.state.activeCompany.companyName : ''}
                                    <Spinner animation="border" className='m-3' variant="warning" />

                                </div>
                            }
                                    </div>
                            



                        </div>
                    )
                }
                {this.state.activeScreen === 2 &&
                    <div>
                        <Button className='mb-4 mt-5' variant='warning' onClick={() => { this.setState({ activeScreen: 1, successfullySentCampaign: false });history.go(-1) }} >{ts('Cancel / Return', this.props.userLogin.language)}</Button>
                        <div className='flexrow '>
                            <div className='pt-5 pr-5 pb-5 col-2' >
                                <div className='bold '>{ts('Name', this.props.userLogin.language)} :</div>{this.state.activeTemplate.name}
                                <div className='bold mt-3 '>{ts('Subject', this.props.userLogin.language)} :</div>{this.state.activeTemplate.subject}
                                <div className='bold pb-1 mt-3'>{ts('Created In', this.props.userLogin.language)} :</div>{td(this.dateFormat(this.state.activeTemplate.creationDate, 'dddd, mmm dd yyyy h:MM tt'), this.props.userLogin.language)}
                                {/* <div className='bold pt-5 pb-2'>Preview</div>
                                <div className=' colSupLigGra pt-5 center bold' style={{ height: 250, borderRadius: 8 }}  ></div> */}
                            </div>
                            <div className='miniContentHeight colSupLigGra pl-1 mt-5 mb-5'></div>
                            <div className='pt-5 pl-5 col-10'>
                                <h5 className='bold mb-4 p-0 '>{ts('Customers Selection', this.props.userLogin.language)}</h5>
                                <h6 className='bold mb-4 mt-5 cunlayer p-2 '> {ts('Filter Based on Customers Definition', this.props.userLogin.language)}</h6>
                                <div className='flexrow spacebetween pl-2 mb-5'>
                                    <div className='col-12' >

                                        <div className='flexrow '>
                                            {G.renderHotspotDropDown("Gender", this.state.availableGender, this.handleChange, this.state.fields, this.state.errors, ts2("All Genders", this.props.userLogin.language), false, this.props.userLogin.language)}
                                            <div className='col-6'>
                                                {G.renderTextField("Age", 'text', "All Ages", this.handleChange, this.state.fields, this.state.errors, ts('Filter Example 18-24,35, 50-54', this.props.userLogin.language), 'ml-3', false, this.props.userLogin.language,true)}
                                                <p className='sremarks hint  ml-3'>{ts('Filter Example 18-24,35,50-54 is equal for (all ages between 18 and 24) or (age equal 35) or (age between 50 and 54)', this.props.userLogin.language)}    </p>
                                            </div>
                                        </div>
                                        <div className='flexrow '>
                                            {G.renderMultiSelectionBox('Countries', this.state.filteredCountryList, ts('All Countries Selected', this.props.userLogin.language),
                                                (removedItem) => { this.setState({ filteredCountryList: [...this.state.filteredCountryList].filter(element => element !== removedItem) }) }
                                                , <Autocomplete showborders={true} reset={true} value={this.state.fields['country'] || ''} error={this.state.errors['country']} name='country' updateFromList={this.handleUpdateAutoComplete} suggestions={this.state.CountryList}
                                                    placeholder={ts('Add Countries Filter Here', this.props.userLogin.language) + ' (' + (this.props.userLogin.language === 0 ? G.countItems(this.state.CountryList.length, "Country", 'Countries') : this.state.CountryList.length + ' Pays') + ' ' + ts('Available', this.props.userLogin.language) + ')'} classNames='fullWidth' onChange={this.handleChange} />
                                                , ''


                                                , this.props.userLogin.language)}
                                            {G.renderMultiSelectionBox('Cities', this.state.filteredCityList, ts('All Cities Selected', this.props.userLogin.language),
                                                (removedItem) => { this.setState({ filteredCityList: [...this.state.filteredCityList].filter(element => element !== removedItem) }) }
                                                , <Autocomplete showborders={true} reset={true} value={this.state.fields['city'] || ''} error={this.state.errors['city']} name='city' updateFromList={this.handleUpdateAutoComplete} suggestions={this.state.CityList}
                                                    placeholder={ts('Add Cities Filter Here', this.props.userLogin.language) + ' (' + (this.props.userLogin.language === 0 ? G.countItems(this.state.CityList.length, "City", 'Cities') : G.countItems(this.state.CityList.length, 'Ville')) + ' ' + ts('Available', this.props.userLogin.language) + ')'} classNames='fullWidth' onChange={this.handleChange} />
                                                , 'ml-3'
                                                , this.props.userLogin.language)}
                                        </div>
                                        <div className='flexrow'>
                                        {G.renderMultiSelectionBox('Interactions categories', this.state.filteredCategoriesList, ts2('All Categories Selected', this.props.userLogin.language),
                                                (removedItem) => { this.setState({ filteredCategoriesList: [...this.state.filteredCategoriesList].filter(element => element !== removedItem) }) }
                                                , <Autocomplete showborders={true} reset={true} value={this.state.fields['category'] || ''} error={this.state.errors['category']} name='category' updateFromList={this.handleUpdateAutoComplete} suggestions={this.state.categoriesList}
                                                    placeholder={ts('Add Categories Filter Here', this.props.userLogin.language) + ' (' + (this.props.userLogin.language === 0 ? G.countItems(this.state.categoriesList.length, "Category", 'Categories') : this.state.categoriesList.length + ' Categories') + ' ' + ts('Available', this.props.userLogin.language) + ')'} classNames='fullWidth' onChange={this.handleChange} />
                                                , ''


                                                , this.props.userLogin.language)}
                                                {G.renderMultiSelectionBox('Customer language', this.state.filteredLanguagesList, ts('All Languages Selected', this.props.userLogin.language),
                                                (removedItem) => { this.setState({ filteredLanguagesList: [...this.state.filteredLanguagesList].filter(element => element !== removedItem) }) }
                                                , <Autocomplete showborders={true} reset={true} value={this.state.fields['language'] || ''} error={this.state.errors['language']} name='language' updateFromList={this.handleUpdateAutoComplete} suggestions={this.state.languagesList}
                                                    placeholder={ts('Add Languages Filter Here', this.props.userLogin.language) + ' (' + (this.props.userLogin.language === 0 ? G.countItems(this.state.languagesList.length, "Language", 'Languages') : this.state.languagesList.length + ' Languages') + ' ' + ts('Available', this.props.userLogin.language) + ')'} classNames='fullWidth' onChange={this.handleChange} />
                                                , 'ml-3'


                                                , this.props.userLogin.language)}
                                            </div>
                                    </div>


                                </div>

                                <h6 className='bold mb-4 mt-5  cunlayer p-2'> {ts('Filter Based on Previous Campaigns Statistics', this.props.userLogin.language)}  </h6>
                                <div className=' pl-2 '>
                                    <div className=' pl-2 '><Form.Label  >{ts('Previous Campaigns Filters', this.props.userLogin.language)}</Form.Label></div>
                                    {!this.state.filterShowProfiles && <div className=' pl-2 pt-3 pb-3  '>
                                        {this.state.filterCampaingList.length == 0 ?
                                            <span className='themeColor mr-2 mt-2 pl-2 outlineBorderButton pr-2'>
                                                {ts('No Filters Added yet', this.props.userLogin.language)}
                                                <span className='ml-1 handCursor remarks ' ></span>
                                            </span>
                                            :
                                            this.state.filterCampaingList.map(
                                                element => <span className='outlineBorderButton remarks mr-2 pl-2 pr-2  mt-2 pb-1 mb-3 boxRadius5 '>
                                                    {element.desc}
                                                    <span className='ml-1 handCursor remarks  ' onClick={() => { this.setState({ filterCampaingList: [...this.state.filterCampaingList].filter(record => record.desc !== element.desc) }) }}> x </span>
                                                </span>)
                                        }
                                    </div>
                                    }

                                    {!this.state.filterShowProfiles && <Button className='ml-2' variant='warning' onClick={
                                        async () => {
                                            this.setState({ filterCampaignIsLoading: true })
                                            let { fields } = this.state;
                                            delete fields['filteremails reach status'];
                                            delete fields['filteremails open status'];
                                            this.setState({ fields });

                                            if (this.state.filteredCampaignDataList.length === 0) {
                                                let result = await G.getData('getCampaignToFilter', [['clientID', this.state.activeCompany.id]]);
                                                result = await result.data

                                                let array = []
                                                result.map(element => { array.push(element.id + ' - ' + element.name) })
                                                if (result.length > 0) {
                                                    let { fields } = this.state;
                                                    fields['availablecampaigns'] = array[0];
                                                    this.setState({ fields });
                                                }
                                                this.setState({ filteredCampaignDataList: array })
                                            }
                                            this.setState({ filterCampaignIsLoading: false, filterShowProfiles: true })

                                        }

                                    }>{ts('Add A filter from Previous Campaigns', this.props.userLogin.language)}</Button>}
                                    {this.state.filterCampaignIsLoading && <div className='flexcolumn center verticalcenter p-5 fullWidth hint '>
                                        {ts(' Getting Campaigns List', this.props.userLogin.language)}
                                        <Spinner animation="border" className='mt-3' variant="danger" />

                                    </div>}
                                    {this.state.filterShowProfiles && <div>
                                        <div className='col-12 cunlayer pt-3  pb-3 boxRadius5 ml-2'>
                                            <div className='col-8 pl-2'>
                                                {G.renderDropDown("Available Campaigns", this.state.filteredCampaignDataList, this.handleChange, this.state.fields, this.state.errors, '', false, this.props.userLogin.language)}
                                                {G.renderDropDown("Filter Emails Reach Status", [l ? 'Only Reached Emails' : 'Seuls les e-mails atteints', l ? 'Only Not Reached Emails' : 'Uniquement les e-mails non atteints'], this.handleChange, this.state.fields, this.state.errors, ts2("All Reached and Not Reached", this.props.userLogin.language), false, this.props.userLogin.language)}
                                                {G.renderDropDown("Filter Emails Open Status", [l ? 'Only Opened Emails' : 'Uniquement les e-mails ouverts', l ? 'Only Not Opened Emails' : 'Uniquement les e-mails non ouverts'], this.handleChange, this.state.fields, this.state.errors, ts2("All Opened And Not Opened", this.props.userLogin.language), false, this.props.userLogin.language)}
                                                <div className='flexrow pt-2 pb-5 '>
                                                    <Button variant='success' onClick={() => {
                                                        let filterreach = this.state.fields['filteremails reach status']
                                                        let filterOpen = this.state.fields['filteremails open status']
                                                        let selectedCampaign = this.state.fields['availablecampaigns'].split('-')[0].trim()
                                                        if (filterreach != null) {
                                                            if (!filterreach.startsWith('Tous')) {
                                                                if (filterreach.startsWith(l ? 'Only Reached' : 'Seuls les e-mails atteints')) {
                                                                    this.state.filterCampaingList.push({ desc: (l ? "Only Reached Emails in Campaign " : 'Seuls les e-mails atteints dans campagne ') + selectedCampaign, code: " And id in (select customerID from campaignaudience where campaignid = " + selectedCampaign + " and  campaignReach is not null) " })
                                                                } else {
                                                                    this.state.filterCampaingList.push({ desc: l ? "Only Unreached Emails in Campaign " : 'Uniquement les e-mails non atteints dans campagne ' + selectedCampaign, code: " And id in (select customerID from campaignaudience where campaignid = " + selectedCampaign + " and  campaignReach is null) " })
                                                                }
                                                            }
                                                        }

                                                        if (filterOpen != null) {
                                                            if (!filterOpen.startsWith('All')) {
                                                                if (filterOpen.startsWith(l ? 'Only Ope' : 'Uniquement les e-mails ouverts')) {
                                                                    this.state.filterCampaingList.push({ desc: (l ? "Only Opened Emails in Campaign " : "Uniquement les e-mails ouverts dans campagne ") + selectedCampaign, code: " And id in (select customerID from campaignaudience where campaignid = " + selectedCampaign + " and  campaignOpen is not null) " })
                                                                } else {
                                                                    this.state.filterCampaingList.push({ desc: (l ? "Only Unopened Emails in Campaign " : 'Uniquement les e-mails non ouverts dans la campagne ') + selectedCampaign, code: " And id in (select customerID from campaignaudience where campaignid = " + selectedCampaign + " and  campaignOpen is null) " })
                                                                }
                                                            }
                                                        }
                                                        this.setState({ filterShowProfiles: false })

                                                    }} className='mr-3 '>{ts('Add Filter', this.props.userLogin.language)}</Button>
                                                    <Button variant='warning' onClick={() => { this.setState({ filterShowProfiles: false }) }}>{ts('Cancel / Return', this.props.userLogin.language)}</Button>
                                                </div>
                                            </div>
                                        </div>


                                    </div>}
                                </div>

                                <h6 className='bold mb-4 mt-5  cunlayer p-2'>  {ts('Filter Based on Hotspots Statistics', this.props.userLogin.language)}  </h6>
                                <div className=' pl-2 '>
                                    <div className=' pl-2 '><Form.Label  >{ts('Previous Hotspots Filters', this.props.userLogin.language)}</Form.Label></div>
                                    {!this.state.filterShowHotspot && <div className=' pl-2 pt-3 pb-3  '>
                                        {this.state.filterHSList.length == 0 ?
                                            <span className='themeColor mr-2 mt-2 pl-2 outlineBorderButton pr-2'>
                                                {ts('No Filters Added yet', this.props.userLogin.language)}
                                                <span className='ml-1 handCursor remarks ' ></span>
                                            </span>
                                            :
                                            this.state.filterHSList.map(
                                                element => <span className='outlineBorderButton remarks mr-2 pl-2 pr-2  mt-2 pb-1 mb-3 boxRadius5 '>
                                                    {element.desc}
                                                    <span className='ml-1 handCursor remarks  ' onClick={() => { this.setState({ filterHSList: [...this.state.filterHSList].filter(record => record.desc !== element.desc) }) }}> x </span>
                                                </span>)
                                        }
                                    </div>
                                    }

                                    {!this.state.filterShowHotspot && <Button className='ml-2' variant='warning' onClick={
                                        async () => {
                                            this.setState({ filterHSIsLoading: true })
                                            let { fields } = this.state;
                                            delete fields['filteremails reach status'];
                                            delete fields['filteremails open status'];
                                            this.setState({ fields });

                                            if (this.state.filteredCampaignDataList.length === 0) {
                                                let result = await G.getData('getCampaignToFilter', [['clientID', this.state.activeCompany.id]]);
                                                result = await result.data

                                                let array = []
                                                result.map(element => { array.push(element.id + ' - ' + element.name) })
                                                if (result.length > 0) {
                                                    let { fields } = this.state;
                                                    fields['availablecampaigns'] = array[0];
                                                    this.setState({ fields });
                                                }
                                                this.setState({ filteredCampaignDataList: array })
                                            }
                                            //#region GetHotspots

                                            if (this.state.fields['availablecampaigns'] != null) { this.searchHS() }
                                            this.setState({ filterHSIsLoading: false, filterShowHotspot: true })

                                        }

                                    }>{ts('Add A filter from Previous Hotspots', this.props.userLogin.language)}</Button>}
                                    {this.state.filterHSIsLoading && <div className='flexcolumn center verticalcenter p-5 fullWidth hint '>
                                        {ts('Getting Campaigns List', this.props.userLogin.language)}
                                        <Spinner animation="border" className='mt-3' variant="danger" />

                                    </div>}
                                    {this.state.filterShowHotspot && <div>
                                        <div className='col-12 cunlayer pt-3  pb-3 boxRadius5 ml-2'>
                                            <div className='col-8 pl-2'>
                                                {G.renderDropDown("Available Campaigns", this.state.filteredCampaignDataList, this.handleChange, this.state.fields, this.state.errors, '', false, this.props.userLogin.language)}
                                                {G.renderDropDown("Available Hotspots", this.state.filteredHSDataListHS, this.handleChange, this.state.fields, this.state.errors, '', false, this.props.userLogin.language)}
                                                {G.renderDropDown("Filter Hotspots Click Status", [l ? 'Only Clicked' : 'Seulement cliqué', l ? 'Only Not Clicked' : 'Seulement non cliqué'], this.handleChange, this.state.fields, this.state.errors, l ? "All Clicked and Not Clicked" : 'Tous cliqués et non cliqués', false, this.props.userLogin.language)}

                                                <div className='flexrow pt-2 pb-5 '>
                                                    <Button variant='success' onClick={() => {
                                                        let filterreach = this.state.fields['filterhotspots click status']

                                                        let selectedCampaign = this.state.fields['availablecampaigns'].split('-')[0].trim()
                                                        if (this.state.fields['availablehotspots']!=null && this.state.fields['availablehotspots'].split('-').length > 1) {
                                                            let selectedHotspot = this.state.fields['availablehotspots'].split('-')[0].trim()
                                                            if (filterreach != null) {
                                                                if (!filterreach.startsWith('All')) {
                                                                    if (filterreach.startsWith(l ? 'Only Clicked' : 'Seulement cliqué')) {
                                                                        this.state.filterHSList.push({ desc: (l ? "Only Clicked Hotspot " + selectedHotspot : 'Seulement Hotspot ' + selectedHotspot + ' cliqué') + (l ? ' in Campaign ' : ' dans la campagne ') + selectedCampaign, code: " And id in (select distinct customerID from campaigncustomerhotspot where campaignID = " + selectedCampaign + " and  hotspotID = " + selectedHotspot + ") " })
                                                                    } else {
                                                                        this.state.filterHSList.push({ desc: (l ? "Only Not Clicked Of hotspot " + selectedHotspot : 'Seulement Hotspot ' + selectedHotspot + ' non cliqué') + "Only Not Clicked Of hotspot " + selectedHotspot + (l ? ' in Campaign ' : ' dans la campagne ') + selectedCampaign, code: " And id in (select distinct ca.customerID from campaignaudience ca left join campaigncustomerhotspot ccs on ccs.customerID = ca.customerID and ca.campaignID=ccs.campaignID and ifnull(ccs.customerID,0) = 0  and ca.campaignID = " + selectedCampaign + " and  ifnull(ccs.hotspotID,0) = 0  ) " })
                                                                    }
                                                                }
                                                            }
                                                        }


                                                        this.setState({ filterShowHotspot: false })

                                                    }} className='mr-3 '>{ts('Add Filter', this.props.userLogin.language)}</Button>
                                                    <Button variant='warning' onClick={() => { this.setState({ filterShowHotspot: false }) }}>{ts('Cancel / Return', this.props.userLogin.language)}</Button>
                                                </div>
                                            </div>
                                        </div>


                                    </div>}
                                </div>









                                <h5 className='bold mb-1 mt-5 cunlayer p-2 '>{ts('Customers Selection', this.props.userLogin.language)}</h5>
                                {this.state.profileList.length === 0 && <div className='m-5 text-center hint remarks'>

                                    {!this.state.didMakeQuery && <p>{ts('No customers selected for this campaign. Please click below to view filtered customers', this.props.userLogin.language)}</p>}
                                    {this.renderQueryProfiles(ts('Query Profiles', this.props.userLogin.language))}
                                    {this.state.didMakeQuery && <p className='warningColor remarks p-2'>
                                        {ts('No result available with these filters, or no customers available in', this.props.userLogin.language)} {(this.state.activeCompany ? this.state.activeCompany.companyName : '')}</p>}
                                </div>}
                                {this.state.profileList.length > 0 && <div className='mt-3 mb-3'>{this.renderQueryProfiles(ts('Query Profiles Again', this.props.userLogin.language))}</div>}
                                <div>

                                    {this.state.profileList.length > 0 &&
                                        <div className='fullWidth mt-3 mb-3'>
                                            <div className=' pl-2 '><Form.Label>{ts('Total Profiles Found in ', this.props.userLogin.language) + (this.state.activeCompany.companyName ? this.state.activeCompany.companyName : 'No Company Selected')}</Form.Label></div>

                                            <h6 className='mb-3 pl-2'>{G.countItems(this.state.profileList.length, ts('Subscribed Profile', this.props.userLogin.language)) + '  ' + ts('Found', this.props.userLogin.language) + '       |       '} {this.state.profileList.length>0 && <span className='link' onClick={() => { this.setState({ ShowProfilesList: !this.state.ShowProfilesList, ShowUnsubscribedProfilesList:false }); }}>{this.state.ShowProfilesList ? ts('Hide Profiles List', this.props.userLogin.language) : ts('Show Profiles List', this.props.userLogin.language)}</span>}</h6>
                                            <h6 className='mb-3 pl-2'>{G.countItems(this.state.unsubscribedprofileList.length, ts('Unsubscribed Profile', this.props.userLogin.language)) + '  ' + ts('Found', this.props.userLogin.language) + '       |       '} {this.state.unsubscribedprofileList.length>0 && <span className='link' onClick={() => { this.setState({ ShowUnsubscribedProfilesList: !this.state.ShowUnsubscribedProfilesList,ShowProfilesList:false }) }}>{this.state.ShowUnsubscribedProfilesList ? ts('Hide Profiles List', this.props.userLogin.language) : ts('Show Profiles List', this.props.userLogin.language)}</span>}</h6>


                                            {this.state.ShowProfilesList && <div>
                                                <div className='p-3 mr-3 '>
                                                    <Form.Label  >{ts2('Filter', this.props.userLogin.language)}</Form.Label>
                                                    <div className='flexrow col-8'>
                                                        <Form.Control onKeyDown={this.onKeyDown} name='filter' type={'text'} placeholder={ts2('Filter Result', this.props.userLogin.language)} onChange={this.handleChange} value={fields['filter'] || ''} />
                                                        {this.state.fields['filter'] && this.state.fields['filter'] !== '' && <Button variant='warning ml-2' onClick={this.filterGrid}>{ts2('Apply', this.props.userLogin.language)}</Button>}
                                                        {this.state.fields['filter'] && this.state.fields['filter'] !== '' && <Button variant='info ml-2' onClick={() => {
                                                            let { fields } = this.state;
                                                            delete fields['filter'];
                                                            this.setState({ fields });

                                                            this.filterGrid()
                                                        }}>{ts('Clear', this.props.userLogin.language)}</Button>}
                                                    </div>
                                                </div>
                                                <div className='p-3 mr-3 '>
                                                    <div className="table-responsive p-0" style={{ maxHeight: 900 }}>
                                                        <table className="table table-head-fixed text-nowrap table-hover ">
                                                            <thead>
                                                                <tr>

                                                                    <th>ID</th>
                                                                    <th>{ts2('First Name', this.props.userLogin.language)}</th>
                                                                    <th>{ts2('Last Name', this.props.userLogin.language)}</th>
                                                                    <th>{ts2('Email', this.props.userLogin.language)}</th>
                                                                    <th>Mobile</th>
                                                                    <th>{ts('Gender', this.props.userLogin.language)}</th>
                                                                    <th>Age</th>
                                                                    <th>{ts('Country', this.props.userLogin.language)}</th>
                                                                    <th>{ts('City', this.props.userLogin.language)}</th>
                                                                    <th>{ts('Created In', this.props.userLogin.language)}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    (this.state.displayedList).map((item) => {

                                                                        return (
                                                                            <tr key={item.id}>
                                                                                <td>{item.id}</td>
                                                                                <td>{item.firstName}</td>
                                                                                <td>{item.lastName}</td>
                                                                                <td>{item.emailAddress}</td>
                                                                                <td>{item.mobile}</td>
                                                                                <td>{item.gender === 0 ? ts('Male', this.props.userLogin.language) : item.gender === 1 ? ts('Female', this.props.userLogin.language) : 'not Defined'}</td>
                                                                                <td>{this.props.userLogin.language === 0 ? G.countItems(G.getAge(item.age), 'year') + ' old' : G.getAge(item.age) + ' ans'} </td>
                                                                                <td>{item.country}</td>
                                                                                <td>{item.city}</td>
                                                                                <td>{td(this.dateFormat(item.addDate, 'dddd, mmm dd yyyy h:MM tt'), this.props.userLogin.language)}</td>



                                                                            </tr>)
                                                                    })
                                                                }

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            }
                                            {this.state.profileList.length > this.state.perPage && this.state.ShowProfilesList &&
                                                <ReactPaginate
                                                    previousLabel={ts("Previous", this.props.userLogin.language)}
                                                    nextLabel={ts("Next", this.props.userLogin.language)}
                                                    breakLabel={"..."}
                                                    pageCount={this.state.pageCount}
                                                    marginPagesDisplayed={this.state.perPage}
                                                    pageRangeDisplayed={2}
                                                    onPageChange={this.handlePageClick}
                                                    containerClassName={"pagination"}
                                                    subContainerClassName={"subPagination"}
                                                    activeClassName={"activePage "}
                                                    breakClassName={'eachPage'}
                                                    previousClassName={'eachPagePrevNex'}
                                                    nextClassName={'eachPagePrevNex'}
                                                    pageClassName={'eachPage'}
                                                />}
                                            {this.state.ShowUnsubscribedProfilesList && <div>
                                                <div className='p-3 mr-3 '>
                                                    <Form.Label  >{ts2('Filter', this.props.userLogin.language)}</Form.Label>
                                                    <div className='flexrow col-8'>
                                                        <Form.Control onKeyDown={this.onKeyDownUnsubscribed} name='unsubscribedfilter' type={'text'} placeholder={ts2('Filter Result', this.props.userLogin.language)} onChange={this.handleChange} value={fields['unsubscribedfilter'] || ''} />
                                                        {this.state.fields['unsubscribedfilter'] && this.state.fields['unsubscribedfilter'] !== '' && <Button variant='warning ml-2' onClick={this.filterUnsubscribedGrid}>{ts2('Apply', this.props.userLogin.language)}</Button>}
                                                        {this.state.fields['unsubscribedfilter'] && this.state.fields['unsubscribedfilter'] !== '' && <Button variant='info ml-2' onClick={() => {
                                                            let { fields } = this.state;
                                                            delete fields['unsubscribedfilter'];
                                                            this.setState({ fields });

                                                            this.filterUnsubscribedGrid()
                                                        }}>{ts('Clear', this.props.userLogin.language)}</Button>}
                                                    </div>
                                                </div>
                                                <div className='p-3 mr-3 '>
                                                    <div className="table-responsive p-0" style={{ maxHeight: 900 }}>
                                                        <table className="table table-head-fixed text-nowrap table-hover ">
                                                            <thead>
                                                                <tr>

                                                                    <th>ID</th>
                                                                    <th>{ts2('First Name', this.props.userLogin.language)}</th>
                                                                    <th>{ts2('Last Name', this.props.userLogin.language)}</th>
                                                                    <th>{ts2('Email', this.props.userLogin.language)}</th>
                                                                    <th>Mobile</th>
                                                                    <th>{ts('Gender', this.props.userLogin.language)}</th>
                                                                    <th>Age</th>
                                                                    <th>{ts('Country', this.props.userLogin.language)}</th>
                                                                    <th>{ts('City', this.props.userLogin.language)}</th>
                                                                    <th>{ts('Created In', this.props.userLogin.language)}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    (this.state.unsubscribeddisplayedList).map((item) => {

                                                                        return (
                                                                            <tr key={item.id}>
                                                                                <td>{item.id}</td>
                                                                                <td>{item.firstName}</td>
                                                                                <td>{item.lastName}</td>
                                                                                <td>{item.emailAddress}</td>
                                                                                <td>{item.mobile}</td>
                                                                                <td>{item.gender === 0 ? ts('Male', this.props.userLogin.language) : item.gender === 1 ? ts('Female', this.props.userLogin.language) : 'not Defined'}</td>
                                                                                <td>{this.props.userLogin.language === 0 ? G.countItems(G.getAge(item.age), 'year') + ' old' : G.getAge(item.age) + ' ans'} </td>
                                                                                <td>{item.country}</td>
                                                                                <td>{item.city}</td>
                                                                                <td>{td(this.dateFormat(item.addDate, 'dddd, mmm dd yyyy h:MM tt'), this.props.userLogin.language)}</td>



                                                                            </tr>)
                                                                    })
                                                                }

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            }
                                            {this.state.unsubscribedprofileList.length > this.state.perPage && this.state.ShowUnsubscribedProfilesList &&
                                                <ReactPaginate
                                                    previousLabel={ts("Previous", this.props.userLogin.language)}
                                                    nextLabel={ts("Next", this.props.userLogin.language)}
                                                    breakLabel={"..."}
                                                    pageCount={this.state.pageCount}
                                                    marginPagesDisplayed={this.state.perPage}
                                                    pageRangeDisplayed={2}
                                                    onPageChange={this.handleUnsubscribedPageClick}
                                                    containerClassName={"pagination"}
                                                    subContainerClassName={"subPagination"}
                                                    activeClassName={"activePage "}
                                                    breakClassName={'eachPage'}
                                                    previousClassName={'eachPagePrevNex'}
                                                    nextClassName={'eachPagePrevNex'}
                                                    pageClassName={'eachPage'}
                                                    
                                                />}


                                        </div>
                                    }


                                </div>

                                {this.state.profileList.length > 0 && !this.state.scheduleCampaign &&
                                    <div>
                                        <div className='bold mb-2 p-2 mt-5 ctheme whiteColor boxRadius5 '> {ts('Confirm and send campaign', this.props.userLogin.language)}</div>
                                        
                                        {G.renderTextField("Sender Email", 'email', ts("Enter the sender email address", this.props.userLogin.language), this.handleChange, fields, errors, '', 'mt-3', true, this.props.userLogin.language)}
                                        <div className='flexrow '>
                                            <Button disabled={this.state.successfullySentCampaign} variant="success" className='mr-3' onClick={() => {
                                                const { senderemail } = this.state.fields;
                                                let emailIsValid = true;
                                                let errors = {};
                                                if (!senderemail) { emailIsValid = false; errors["senderemail"] = ts2("Email cannot be empty", this.props.userLogin.language); } else {
                                                    if (!G.isValideEmail(senderemail)) { emailIsValid = false; errors["senderemail"] = ts2('This is not a valid Email', this.props.userLogin.language); }
                                                }
                                                this.setState({ errors: errors });
                                                if (!emailIsValid)return;
                                                this.setState({ modalShow: true, startCampaignLoading: true })
                                            }}>
                                                {this.state.startCampaignLoading && <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    className='mr-2'
                                                    disabled={this.state.startCampaignLoading}
                                                />}
                                                {ts('Start Campaign Now', this.props.userLogin.language)}</Button>
                                            <Button disabled={this.state.successfullySentCampaign} variant="warning" className='' onClick={() => {
                                                this.setState({ scheduleCampaign: true })
                                            }}>{ts2("Send later",this.props.userLogin.language)}</Button>
                                            <Button disabled={this.state.successfullySentCampaign} variant="outline-primary" className='' style={{marginLeft:"20px"}} onClick={() => {
                                                this.setState({ sendTest: true, modalShow3:true })
                                            }}>{ts2("Send test",this.props.userLogin.language)}</Button>
                                        </div>
                                    </div>
                                }
                                {this.state.profileList.length > 0 && this.state.scheduleCampaign &&
                                    <div>
                                        <div className='bold mb-2 p-3 mt-5 colSupLigGra '>{ts2("Confirm",this.props.userLogin.language)}</div>
                                        <div className='mt-2 mb-2'>
                                            {ts2("You can schedule your campaign to go on a specific date. Please assign it below",this.props.userLogin.language)}
                                            {G.renderDatePicker('Schedule Date', this.state.scheduledate, (date) => { this.setState({ scheduledate: date }) }, errors, "Enter the schedule Date", "yyyy-MM-dd HH:mm zzz", true, new Date(),this.props.userLogin.language)}
                                        </div>
                                        <div className='flexrow '>
                                            <Button disabled={this.state.successfullySentCampaign} variant="success" className=' mr-3 ' onClick={() => {
                                                this.setState({ modalShow2: true })
                                            }}>{ts2("Confirm",this.props.userLogin.language)}</Button>
                                            <Button disabled={this.state.successfullySentCampaign} variant="warning" className='  ' onClick={() => {
                                                this.setState({ scheduleCampaign: false })
                                            }}>{ts("Cancel",this.props.userLogin.language)}</Button>
                                        </div>

                                    </div>
                                }
                                {this.state.TestMessageSent == true && <p className="warningColor">{ts("Email sent", this.props.userLogin.language)}</p>}
                                {this.state.TestMessageSentError == true && <p className="warningColor">{ts("Email was not sent due to an error", this.props.userLogin.language)}</p>}
                                {this.state.profileList.length > 0 && this.state.successfullySentCampaign &&
                                    <div>
                                        <span className='warningColor'>
                                            {ts("Your campaign is being submitted.", this.props.userLogin.language)}
                                            <p>{ts('Campaign results and analytics will appear in', this.props.userLogin.language)} <span onClick={() => { history.push("/CampaignStatistics") }} className='link'>{ts('Campaigns Statistics',this.props.userLogin.language)}</span> {this.props.userLogin.language === 0 ? ' screen' : ''}</p>
                                            <Button variant='success' onClick={() => { this.setState({ profileList: [], successfullySentCampaign: false, allEmailsskippedMessage: '' }) }}> {ts('Return', this.props.userLogin.language)}</Button>
                                        </span>
                                    </div>}

                                {this.state.allEmailsskippedMessage !== '' && <p className='warningColor '>{this.state.allEmailsskippedMessage}</p>

                                }
                                <SelectionCheckbox
                                    show={this.state.modalShow}
                                    title='Start Campaign' data={[2, 3, 4, 5, 6, 7, 8, 9, 0]}
                                    userLogin = {this.props.userLogin}
                                    onHide={() => { this.setState({ modalShow: false, startCampaignLoading: false }) }}
                                    startCampaign={() => { this.startCampaign() }}
                                />
                                <SendTestBox
                                    show={this.state.modalShow3}
                                    fields={this.state.fields}
                                    errors={this.state.errors}
                                    title='Please enter email address' data={[2, 3, 4, 5, 6, 7, 8, 9, 0]}
                                    userLogin = {this.props.userLogin}
                                    onChange={this.handleChange}
                                    onHide={() => { this.setState({ modalShow3: false, sendTest:false, errors:{} }) }}
                                    sendTestCampaign={() => { this.sendTestCampaign() }}
                                />
                                <SelectionCheckbox2
                                    show={this.state.modalShow2}
                                    title={ts2('Schedule Campaign',this.props.userLogin.language)} data={[2, 3, 4, 5, 6, 7, 8, 9, 0]}
                                    userLogin = {this.props.userLogin}
                                    onHide={() => { this.setState({ modalShow2: false, startCampaignLoading: false }) }}
                                    startCampaign={() => { this.startCampaignLater() }}
                                />
                            </div>
                        </div>

                        {/* <div dangerouslySetInnerHTML={template} />
                        <object type="text/html" data={""} width="800px" height="600px" style="overflow:auto;border:5px ridge blue">
                        </object> */}
                    </div>
                }

            </div>
        )
    }
}
//#region "Redux"
const mapStateToProps = state => {

    return {
        userLogin: state.SaveLogin.userData,
    };
};
export default connect(
    mapStateToProps
)(CustomerCampaigns)
//#endregion



class SelectionCheckbox extends Component {
    render() {
        return (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            //animation={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {this.props.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ width: '100%' }}>
                        {ts2('Are you sure you want to start your campaign now?', this.props.userLogin.language)}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className='flexrow end  '>
                        <Button variant='success' onClick={this.props.startCampaign} className='mr-3'>{ts2('Start Campaign', this.props.userLogin.language)}</Button>
                        <Button variant='warning' onClick={this.props.onHide}>{ts('Cancel', this.props.userLogin.language)}</Button>
                    </div>

                </Modal.Footer>
            </Modal>

        )
    }
}
class SendTestBox extends Component {
    render() {
        return (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            //animation={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {this.props.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ width: '100%' }}>
                        {G.renderTextField("Test Email","email","Please enter email address", this.props.onChange,this.props.fields, this.props.errors, "","",false, this.props.userLogin.language, true)} 
                        {G.renderDropDown("Test Language",["","fr","en","de","nl"],this.props.onChange,this.props.fields, this.props.errors, "",false, this.props.userLogin.language)} 
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className='flexrow end  '>
                        <Button variant='success' onClick={this.props.sendTestCampaign} className='mr-3'>{ts2('Send test', this.props.userLogin.language)}</Button>
                        <Button variant='warning' onClick={this.props.onHide}>{ts('Cancel', this.props.userLogin.language)}</Button>
                    </div>

                </Modal.Footer>
            </Modal>

        )
    }
}
class SelectionCheckbox2 extends Component {
    render() {
        return (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            //animation={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {this.props.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ width: '100%' }}>
                        {ts2('Are you sure you want to schedule your campaign?', this.props.userLogin.language)}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className='flexrow end  '>
                        <Button variant='success' onClick={this.props.startCampaign} className='mr-3'>{ts2('Start Campaign', this.props.userLogin.language)}</Button>
                        <Button variant='warning' onClick={this.props.onHide}>{ts('Cancel', this.props.userLogin.language)}</Button>
                    </div>

                </Modal.Footer>
            </Modal>

        )
    }
}
