import React, { Component } from 'react';
import './App.css';
import { Router, Route, Switch } from 'react-router-dom';

import { Row } from 'react-bootstrap';
import './styles/glob.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import SignInForm from './pages/SignInForm';
import MainContent from './pages/mainContent';
import RegistrationRequest from './pages/regitrationRequest';
import ManageUserRegistration from './pages/ManageUserRegistration';
import StartPage from './pages/StartPage';
import CampaignsTemplates from './pages/CampaignsTemplate';
import UserManagement from './pages/userManagement';
import ProfileManagement from './pages/profile'
import CampaignEditor from './pages/campaignEditor';
import CustomerCampaigns from './pages/CustomerCampaigns';
import LogHistory from './pages/logHistory';

import NotFound from './pages/NotFound';
import ImportProfiles from './pages/importProfiles';
import Header from './components/adminLTE/header';
import Footer from './components/adminLTE/footer';
import Menu from './components/adminLTE/menu';
import ExportProfiles from './pages/exportProfiles';
import CampaignStatistics from './pages/campaignStatistics';
import ResetPassword from './pages/resetPassword';
import HotspotCategories from './pages/HotspotCategories'

import * as G from './components/global'
import history from './components/history'

//#region Redux
import { _reducersActions as _RA } from './components/_redux/constants';
import { connect } from 'react-redux';
import smartlookClient from 'smartlook-client'
//#endregion

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page:'/'

        }
    }
    changePage = (newpage) =>{this.setState({page:newpage});window.scrollTo(0, 0)}
    //#region  componentDidMount,componentWillUnmount,listenToScroll
    async componentDidMount() {
        if(window.location.hostname == "platform.givenmine.com"){
            smartlookClient.init('2b8b6ccf01f95d09f576f9496c79ff412e629bbf')
        }
        if (history.location.pathname.toLocaleLowerCase() === "/registrationrequest" || history.location.pathname.toLocaleLowerCase() === "/signin") {
            this.props.saveUserData({})
            return;
        }
        const result = await G.checkUserAccess();
         
        if (!Array.isArray(result)) { this.logOut(); return }
        if (result.length === 0) { this.logOut(); return }
        this.props.saveUserData(result[0])
        history.listen((location, action) => {
          this.setState({page:location.pathname})
          })
          this.setState({page:history.location.pathname})
    }
    
    
    logOut = () => {
        
        this.props.saveUserData({})
        sessionStorage.removeItem(G.sessionStorageVariables.userData)
        history.push("/SignIn");
    }


    //#endregion


    
    render() {
        let isLoggedIn = false;
        if (this.props.userLogin != null) { if (this.props.userLogin['id'] !== undefined) isLoggedIn = true; }
                
        return (


            <div className='col-12 ' >

                <Router history={history}  >
                    {1 == 2 && <div style={{ textAlign: 'center' }}>{JSON.stringify(this.props.userLogin)}</div>}
                    {isLoggedIn && <Header logOut={this.logOut}/>}
                    {isLoggedIn && <Menu
                        userFullName={isLoggedIn ? this.props.userLogin.fullName : 'No user logged in'}
                        accountType={isLoggedIn ? this.props.userLogin.accountType : 10}
                        currentPage={this.state.page}
                        changePage={this.changePage}
                    />}

                    <div className={isLoggedIn?'content-wrapper miniContentHeight whiteBg pl-5 primColor':"primColor cSignInBack fullHeight"} >
                        {1 === 2 && <div style={{ textAlign: "center", padding: 20 }}>
                            <div>Age:<span>{this.props.age}</span></div>

                            <div>
                                <button onClick={this.props.onAgeUp} >Age Up</button>
                                <button onClick={this.props.onAgeDown} >Age Down</button>
                            </div>
                            <div>Weight:<span>{this.props.weight}</span></div>
                            <div>
                                <button onClick={this.props.onWeightUp} >Weight Up</button>
                                <button onClick={this.props.onWeightDown} >Weight Down</button>
                            </div>
                        </div>
                        }
                        
                        <div className='minheig80 mb-2' >
                            <Row >
                                <Switch>
                                    <Route path="/" exact render={() => <MainContent isLoggedIn={isLoggedIn} />} />
                                    <Route path="/SignIn" exact render={() => <SignInForm history={history} />} />
                                    <Route path="/startPage" exact render={() => <StartPage history={history} />} />
                                    {this.props.userLogin ? (this.props.userLogin.accountType<2 || this.props.userLogin.accountType===2 ) && <Route path="/CampaignsTemplates" exact render={() => <CampaignsTemplates  history={history} />} /> :''}
                                    <Route path="/RegistrationRequest" exact render={() => <RegistrationRequest history={history} />} />
                                    {this.props.userLogin ? this.props.userLogin.accountType===0 && <Route path="/ManageUserRegistration" exact render={() => <ManageUserRegistration />} /> :''}
                                    {this.props.userLogin ? this.props.userLogin.accountType===0 && <Route path="/ManageUserRegistration/edit" exact render={() => <ManageUserRegistration  />} /> :''}
                                    {this.props.userLogin ? this.props.userLogin.accountType===0 && <Route path="/ManageUserRegistration/register" exact render={() => <ManageUserRegistration  />} /> :''}

                                    {this.props.userLogin ? this.props.userLogin.accountType<2 && <Route path="/UserManagement" exact render={() => <UserManagement />} /> :''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<2 && <Route path="/UserManagement/create" exact render={() => <UserManagement />} /> :''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<2 && <Route path="/UserManagement/edit" exact render={() => <UserManagement />} /> :''}
                                    
                                    {this.props.userLogin ? (this.props.userLogin.accountType<2 || this.props.userLogin.accountType===4 ) && <Route path="/ProfileManagement" exact render={() => <ProfileManagement />} /> :''}
                                    {this.props.userLogin ? (this.props.userLogin.accountType<2 || this.props.userLogin.accountType===4 ) && <Route path="/ProfileManagement/customers" exact render={() => <ProfileManagement />} /> :''}
                                    {this.props.userLogin ? (this.props.userLogin.accountType<2 || this.props.userLogin.accountType===4 ) && <Route path="/ProfileManagement/customers/create" exact render={() => <ProfileManagement />} /> :''}
                                    {this.props.userLogin ? (this.props.userLogin.accountType<2 || this.props.userLogin.accountType===4 ) && <Route path="/ProfileManagement/customers/edit" exact render={() => <ProfileManagement />} /> :''}
                                    
                                    
                                    {this.props.userLogin ? (this.props.userLogin.accountType<2 || this.props.userLogin.accountType===2 ) && <Route path="/CampaignEditor" exact render={() => <CampaignEditor history={history} />} />:''}
                                    {this.props.userLogin ? (this.props.userLogin.accountType<2 || this.props.userLogin.accountType===3 ) &&<Route path="/CustomerCampaigns" exact render={() => <CustomerCampaigns history={history} />} /> :''}
                                    {this.props.userLogin ? (this.props.userLogin.accountType<2 || this.props.userLogin.accountType===3 ) &&<Route path="/CustomerCampaigns/list" exact render={() => <CustomerCampaigns history={history} />} /> :''}
                                    {this.props.userLogin ? (this.props.userLogin.accountType<2 || this.props.userLogin.accountType===3 ) &&<Route path="/CustomerCampaigns/list/send" exact render={() => <CustomerCampaigns history={history} />} /> :''}
                                    {this.props.userLogin ? (this.props.userLogin.accountType<2 || this.props.userLogin.accountType===4 ) && <Route path="/ImportProfiles" exact render={() => <ImportProfiles />} />:''}
                                    {this.props.userLogin ? (this.props.userLogin.accountType<2 || this.props.userLogin.accountType===4 ) && <Route path="/ExportProfiles" exact render={() => <ExportProfiles />} />:''}
                                    {this.props.userLogin ? (this.props.userLogin.accountType<2 || this.props.userLogin.accountType===4 ) && <Route path="/ExportProfiles/list" exact render={() => <ExportProfiles />} />:''}
                                    <Route path="/logHistory" exact render={() => <LogHistory />} />
                                    <Route path="/ResetPassword" exact render={() => <ResetPassword history={history} />} />
                                    {this.props.userLogin ? this.props.userLogin.accountType<2 && <Route path="/CampaignStatistics" exact render={() => <CampaignStatistics />} />:''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<2 && <Route path="/CampaignStatistics/details" exact render={() => <CampaignStatistics />} />:''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<2 && <Route path="/HotspotCategories" exact render={() => <HotspotCategories />} /> :''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<2 && <Route path="/HotspotCategories/create" exact render={() => <HotspotCategories />} /> :''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<2 && <Route path="/HotspotCategories/edit" exact render={() => <HotspotCategories />} /> :''}
                                    <Route path='*' exact={true} render={() => <NotFound />} />
                                </Switch>
                            </Row>
                        </div>
                       

                        
                    </div>


                    {isLoggedIn &&<Footer />}
                </Router>

            </div >

        );
    }
}
//#region Redux-Component Link
const mapDispachToProps = dispatch => {
    return {
        onAgeUp: () => dispatch({ type: _RA.counter.onAgeUp, value: 1 }),
        onAgeDown: () => dispatch({ type: _RA.counter.onAgeDown, value: 1 }),
        onWeightUp: () => dispatch({ type: _RA.counterWeight.onWeightUp, value: 1 }),
        onWeightDown: () => dispatch({ type: _RA.counterWeight.onWeightDown, value: 1 }),
        saveUserData: (data) => { G.log('dispatch', data); dispatch({ type: '', payLoad: data }) }
    };
};

const mapStateToProps = state => {
    return {
        age: state.counterAge.age,
        weight: state.counterWeight.weight,
        userLogin: state.SaveLogin.userData,
    };
};

//#endregion
export default connect(
    mapStateToProps,
    mapDispachToProps
)(App);