import React, { Component } from 'react'
import { Image, Button, Badge, Spinner } from 'react-bootstrap';
import { renderPageContainer, renderPageHeader, renderDivider, getData,nodeServerSite } from '../components/global'
import history from '../components/history';
import { connect } from 'react-redux';
import emailtempl from '../components/assets/emailtempl.png'
import { translateScreen as ts , translateDate as td, translateScreen2 as ts2} from '../components/dictionary'
import ReactPaginate from 'react-paginate';
import 'rsuite/dist/rsuite.min.css';
import {DateRangePicker} from 'rsuite';
class CampaignsTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeScreen: 0,
            allCompanies: [],
            activeCompany: {},
            ClientTemplates: [],            
            archivedTemplates:[],
            loadingTemplates: true,
            originalList:[],
            displayedList:[],
            offset:0,
            originalArchiveList:[],
            displayedArchiveList:[],
            offsetArchive:0,
            loadingArchive:true,
            sortvalue:"newest",
            startdate:'',
            enddate:'',            
            perPage: 12,
            currentPage: 0,
            pageCount: 0,
            currentArchivePage: 0,
            pageArchiveCount: 0,
            customtemplates:[]
        }

    }
    dateFormat = require("dateformat");
    async componentDidMount() {
        // if (this.state.allCompanies.length === 0) {
        //     this.refreshDataClients()

        // }
        
    }

    refreshDataClients = async () => {
        if (this.props.userLogin == null || this.state.allCompanies.length > 0) return;
        let paramArr = [['addColumns', ' (select count(id) from campaigntemplate where clientid = a.id) Count']]
        if (this.props.userLogin.clientID > 0) paramArr.push(['filter', " a.id = " + this.props.userLogin.clientID])
        let result = await getData('client/query', paramArr);
        result = await result.data;
        this.setState({ allCompanies: result })
        if (result.length > 0) {
            this.setState({ activeCompany: result[0] })
            this.refreshData(this.state.activeCompany.id)
        }


    }
    refreshData = async (company) => {
        var filter = 'clientID = ' + company + " and archived<>1";
        if(this.state.sortvalue == "newest")
        {
            
            
            filter += " order by creationdate desc"
            
        }
        else{
            filter += " order by creationdate"
        }
        let result = await getData('campaigntemplate/query', [['filter', filter]]);
        result = await result.data;
        this.setState({ ClientTemplates: result })
        this.setState({ loadingTemplates: false })
        let pageCounts = result.length / this.state.perPage
                this.setState({ ClientTemplates: result, pageCount: pageCounts, originalList: result })
                this.setdisplayList(0)
        result = await getData("clienttemplates/query",[["filter","ispublic=1 or clientId=" + company]]);
        result = await result.data;
        this.setState({customtemplates:result})
    }
    setdisplayList(startnumber) {
        let sliceArray = [...this.state.ClientTemplates]

        if (this.state.ClientTemplates.length > this.state.perPage) {
            sliceArray = sliceArray.slice(startnumber * this.state.perPage, startnumber * this.state.perPage + this.state.perPage)
           
        }
        this.setState({ displayedList: sliceArray })
    }
    setdisplayArchiveList(startnumber) {
        let sliceArray = [...this.state.archivedTemplates]

        if (this.state.archivedTemplates.length > this.state.perPage) {
            sliceArray = sliceArray.slice(startnumber * this.state.perPage, startnumber * this.state.perPage + this.state.perPage)
           
        }
        this.setState({ displayedArchiveList: sliceArray })
    }
    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.setdisplayList(selectedPage)
        });

    };
    handleArchivePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentArchivePage: selectedPage,
            offsetArchive: offset
        }, () => {
            this.setdisplayArchiveList(selectedPage)
        });

    };
    openCampaignEditor = (templateID = -1, element = {}, htmlId="") => {
        var userLogin = this.props.userLogin;
        const activeCompany = this.state.activeCompany
        history.push({
            pathname: '/CampaignEditor',
            state: { data: { userLogin, templateID, activeCompany, element, htmlId } },
        });
        window.scrollTo(0, 0)
    }
    listTemplates = async(companyId, companyDetails) => {
        this.setState({ loadingTemplates: true })
        this.setState({ ClientTemplates: [], displayedList: [], activeCompany: companyDetails })
        this.refreshData(companyId);
        

    }
    ArchiveTemplate = async(id)=>{
        await getData('campaigntemplate/archive',[['id',id]]);
        this.refreshArchivedData(this.state.activeCompany.id);
        this.refreshData(this.state.activeCompany.id);
    }
    ActivateTemplate = async(id)=>{
        await getData('campaigntemplate/activate',[['id',id]]);
        this.refreshArchivedData(this.state.activeCompany.id);
        this.refreshData(this.state.activeCompany.id);
    }
    refreshArchivedData = async (company) => {
        let result = await getData('campaigntemplate/query', [['filter', 'clientID = ' + company + " and archived=1"]]);
        result = await result.data;
        this.setState({ archivedTemplates: result })
        this.setState({ loadingArchive: false })
        let pageCounts = result.length / this.state.perPage
                this.setState({ pageArchiveCount: pageCounts, originalArchiveList: result })
                this.setdisplayArchiveList(0)
    }
    renderTemplateButton = (id, element) => {
        return (
            <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6 templatecard">
<div class="info-box handCursor" style={{width:"100%"}}>
<span class="info-box-icon bg-info" style={{padding:"0 30px"}} onClick={() => { this.openCampaignEditor(id, element) }}><i class="far fa-envelope"></i></span>
<div class="info-box-content" style={{width:"100%"}}>
<div className='info-icon' style={{position:"absolute", right:"10px", top:"10px", cursor:"pointer"}}><i className="fa fa-ellipsis-v" data-toggle="dropdown" href="#" aria-expanded="false"></i>
                <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                    <a onClick={()=>{this.openCampaignEditor(-1, element)}} className="dropdown-item">
                    {ts2("Duplicate",this.props.userLogin.language)}
                    </a>
                    <div className="dropdown-divider"></div>
                    <a onClick={()=>{this.ArchiveTemplate(id)}} className="dropdown-item">
                    {ts('Archive', this.props.userLogin.language)}
                    </a>
                </div>
                </div>
<span className="info-box-text bold" style={{width:"70%", lineHeight:"1.5"}}  onClick={() => { this.openCampaignEditor(id, element) }}>{element.name}</span>
                <span className="info-box-text" style={{width:"70%", lineHeight:"1.5"}} onClick={() => { this.openCampaignEditor(id, element) }}>{ts('Subject',this.props.userLogin.language)}: {element.subject}</span>
                <span className="info-box-number" style={{fontStyle:"italic", color:"#1998AB", marginTop:"0px"}} onClick={() => { this.openCampaignEditor(id, element) }}> {element.creationDate !== '' ? td(this.dateFormat(element.creationDate, 'dd mmm yyyy'),this.props.userLogin.language) : ''}</span>
</div>

</div>

</div>
        )
    }
    renderArchivedTemplateButton = (id, element) => {
        return (
            <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6 templatecard">
<div class="info-box handCursor" style={{width:"100%"}}>
<span class="info-box-icon bg-info" style={{padding:"0 30px"}} onClick={() => { this.openCampaignEditor(id, element) }}><i class="far fa-envelope"></i></span>
<div class="info-box-content" style={{width:"100%"}}>
<div className='info-icon' style={{position:"absolute", right:"10px", top:"10px", cursor:"pointer"}}><i className="fa fa-ellipsis-v" data-toggle="dropdown" href="#" aria-expanded="false"></i>
                <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                    <a onClick={()=>{this.openCampaignEditor(-1, element)}} className="dropdown-item">
                    {ts2("Duplicate",this.props.userLogin.language)}
                    </a>
                    <div className="dropdown-divider"></div>
                    <a onClick={()=>{this.ActivateTemplate(id)}} className="dropdown-item">
                    {ts('Activate', this.props.userLogin.language)}
                    </a>
                </div>
                </div>
<span className="info-box-text bold" style={{width:"70%", lineHeight:"1.5"}}  onClick={() => { this.openCampaignEditor(id, element) }}>{element.name}</span>
                <span className="info-box-text" style={{width:"70%", lineHeight:"1.5"}} onClick={() => { this.openCampaignEditor(id, element) }}>{ts('Subject',this.props.userLogin.language)}: {element.subject}</span>
                <span className="info-box-number" style={{fontStyle:"italic", color:"#1998AB", marginTop:"0px"}} onClick={() => { this.openCampaignEditor(id, element) }}> {element.creationDate !== '' ? td(this.dateFormat(element.creationDate, 'dd mmm yyyy'),this.props.userLogin.language) : ''}</span>
</div>

</div>

</div>
        
        )
    }
    handleDateRangeChange = async(range)=>{
        /*var company = this.state.activeCompany.id;
        let result = await getData('campaigntemplate/query', [['filter', 'clientID = ' + company + ' and creationDate>' + startdate]]);
        result = await result.data;
        this.setState({ ClientTemplates: result })
        this.setState({ loadingTemplates: false })*/
        if(range!=undefined && range!=null && range[0]!=undefined && range[0]!="" && range[1]!=undefined && range[1]!="")
        {
            var startdate = new Date(range[0])
            var enddate = new Date(range[1])
            var starts = td(this.dateFormat(startdate,"yyyy-mm-dd"));
            var ends = td(this.dateFormat(enddate,"yyyy-mm-dd"))
            var filter = 'clientID = ' + this.state.activeCompany.id + ` and archived<>1 and DATEDIFF(creationdate,'`+starts + `') >= 0 and DATEDIFF(creationdate,'`+ends + `') <= 0`
            if(starts == ends)
            {
                filter = 'clientID = ' + this.state.activeCompany.id + ` and archived<>1 and DATEDIFF(creationdate,'`+starts + `') = 0`
            }
            let result = await getData('campaigntemplate/query', [['filter', filter]]);
        result = await result.data;
        this.setState({ ClientTemplates: result, startdate:(starts?starts:''), enddate:(ends?ends:'') })
        this.setState({ loadingTemplates: false })
        this.setdisplayList(0)
        }
        else{
            this.setState({ startdate:'', enddate:'' })
            this.refreshData(this.state.activeCompany.id)
        }
           setTimeout(function(){
            

           },500); 
        
    }
    handleSort = async (e)=>{
        var sortval = e.target.value;
        this.setState({ loadingTemplates: true })
        var filter ='clientID = ' + this.state.activeCompany.id + " and archived<>1" ;
        if(this.state.startdate!='' && this.state.enddate!='')
            {
                var starts = this.state.startdate;
                var ends = this.state.ends;
                filter = 'clientID = ' + this.state.activeCompany.id + ` and DATEDIFF(creationdate,'`+starts + `') >= 0 and DATEDIFF(creationdate,'`+ends + `') <= 0`
                if(starts == ends)
                {
                    filter = 'clientID = ' + this.state.activeCompany.id + ` and DATEDIFF(creationdate,'`+starts + `') = 0`
                }
            }
        if(sortval == "newest")
        {
            
            
            filter += " order by creationdate desc"
            
        }
        else{
            filter += " order by creationdate"
        }
        let result = await getData('campaigntemplate/query', [['filter', filter]]);
        result = await result.data;
            this.setState({ ClientTemplates: result, sortvalue: sortval })
            this.setState({ loadingTemplates: false })
            setTimeout(function(){
                var searchfield = document.getElementById("searchfield");
            if(searchfield!=undefined && searchfield.value !="")
            {
                var nodes = document.getElementsByClassName('templatecard');
           Array.prototype.map.call(nodes, (elem)=>{
            if (elem.innerText.toLowerCase().includes(searchfield.value)) {
                elem.style.display = "block";
            } else {
                elem.style.display = "none";
            }
        });
            }
            },500);
            this.setdisplayList(0)
    }
    handleSearchKeyUp = (e)=>{
       // console.log("Search value",e.target.value)
       var filter = e.target.value
       var nodes = document.getElementsByClassName('templatecard');
       Array.prototype.map.call(nodes, (elem)=>{
        if (elem.innerText.toLowerCase().includes(filter)) {
            elem.style.display = "block";
        } else {
            elem.style.display = "none";
        }
    })
            
    }
    render() {
        if (this.state.allCompanies != null) {

            if (this.state.allCompanies.length === 0) { this.refreshDataClients(); }

        }
        return (
            <div style={{ width: '100%' }}>

                {this.state.activeScreen === 0 &&
                    <div>

                        <h4 className='mb-5 mt-5'>{ts('Select a client to view their email campaign templates',this.props.userLogin.language)}</h4>
                        <div className='p-3 mr-3 '>
                            <div className="table-responsive p-0" style={{ maxHeight: 300 }}>
                                <table className="table table-head-fixed text-nowrap table-hover ">
                                    <thead>
                                        <tr>
                                            <th>Action</th>
                                            <th>ID</th>
                                                <th>{ts('Client Name',this.props.userLogin.language)}</th>
                                                <th>{ts('Contact Person',this.props.userLogin.language)}</th>
                                                <th>{ts('Phone',this.props.userLogin.language)}</th>
                                                <th>{ts('Email Address',this.props.userLogin.language)}</th>
                                                <th>{ts('Created In',this.props.userLogin.language)}</th>
                                                <th>{ts('Number of Campaigns',this.props.userLogin.language)} </th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            (this.state.allCompanies).map((item) => {

                                                return (
                                                    <tr key={item.id}>
                                                        <td className='xxxtitle shiteColor handCursor'
                                                            onClick={() => { this.listTemplates(item.id, item) }}>
                                                            <Badge variant='info'>{ts('Select',this.props.userLogin.language)}</Badge>
                                                        </td>
                                                        <td>{item.id}</td>
                                                        <td>{item.companyName}</td>
                                                        <td>{item.contactPerson}</td>
                                                        <td>{item.contactPersonNo}</td>
                                                        <td>{item.email}</td>
                                                        <td>{td(this.dateFormat(item.addDate, 'dddd, mmm dd yyyy h:MM tt'),this.props.userLogin.language)}</td>
                                                        <td classname='text-center'>{item.Count===0 ? '-': item.Count}</td>


                                                    </tr>)
                                            })
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>

                }

                {this.state.activeScreen === 0 &&

                    renderPageContainer(
                        <div>

                            {renderPageHeader(<span>{ts('Saved Campaigns List',this.props.userLogin.language)} <Badge variant='warning ssstitle'>{this.state.activeCompany ? this.state.activeCompany.companyName : ''}</Badge></span>)}
                            {this.state.loadingTemplates &&
                                <div className='flexcolumn center verticalcenter p-5 fullWidth hint '>
                                    {ts('Loading templates for',this.props.userLogin.language)} {this.state.activeCompany ? this.state.activeCompany.companyName : ''}
                                    <Spinner animation="border" className='mt-3' variant="warning" />

                                </div>
                            }
                            {!this.state.loadingTemplates && this.state.ClientTemplates.length > 0 && <Button onClick={() => { this.openCampaignEditor(-1) }} variant='success' className='mt-5 mb-4' >{ts('Create New Template',this.props.userLogin.language)}</Button>}
                            <h3>{ts('Email templates',this.props.userLogin.language)}&nbsp;&nbsp;
                            <DateRangePicker onChange={(value)=>{this.handleDateRangeChange(value)}}  placeholder={ts("Filter by date range", this.props.userLogin.language)} />
                            </h3><div className="col-md-12" style={{height:"10px"}}></div>
                            <div className="row" style={{margin:"0px"}}>
                            <div className="form-group col-md-3">
                            <label for="searchfield">Search</label>
                            <div class="input-group">
                                
                  <input id="searchfield" type="text" class="form-control" onKeyUp={(e)=>this.handleSearchKeyUp(e)}/>
                  <div class="input-group-append">
                    <span class="input-group-text"><i class="fas fa-search"></i></span>
                  </div>
                </div>
                            </div>
                            <div className="col-md-9">
                                <div style={{float:"right"}}>
                                    <label for="sortfield">Sort by</label>
                                <select id="sortfield" name="sortfield" onChange={this.handleSort} className="custom-select">
                                    <option></option>
                                    <option value="newest">{ts("Newest to oldest",this.props.userLogin.language)}</option>
                                    <option value="oldest">{ts("Oldest to newest",this.props.userLogin.language)}</option>
                                </select>
                                </div>
                                <div style={{clear:"both"}}></div>
                            </div></div>
                            <div class="col-md-12"></div>
                            {!this.state.loadingTemplates &&<div className='row' style={{margin:"0px"}}>

                                {this.state.displayedList.map(element => {
                                    return (this.renderTemplateButton(element.id, element))

                                })}

                                {this.state.ClientTemplates.length === 0 &&
                                    <div className='col-md-12'>
                                    <div className='flexcolumn center verticalcenter p-5 fullWidth cSignInBack boxRadius5'>
                                       {ts(' No Templates available for',this.props.userLogin.language)} {this.state.activeCompany ? this.state.activeCompany.companyName : ''}
                                        <Button onClick={() => { this.openCampaignEditor(-1) }} variant='success' className='m-4' >Create First Tempalte</Button>
                                    </div>
                                    </div>
                                }

                            </div>}
                            <div style={{float:"right"}} className="rightpaging">
                            {!this.state.loadingTemplates && this.state.ClientTemplates.length > this.state.perPage && <ReactPaginate
                            previousLabel={ts("Previous",this.props.userLogin.language)}
                            nextLabel={ts("Next",this.props.userLogin.language)}
                            breakLabel={"..."}
                            pageCount={this.state.pageCount}
                            marginPagesDisplayed={this.state.perPage}
                            pageRangeDisplayed={2}
                            onPageChange={this.handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"subPagination"}
                            activeClassName={"activePage "}
                            breakClassName={'eachPage'}
                            previousClassName={'eachPagePrevNex'}
                            nextClassName={'eachPagePrevNex'}
                            pageClassName={'eachPage'}
                        />}</div><div style={{clear:"both"}}></div>
                            
                            <button onClick={()=>{
                                this.refreshArchivedData(this.state.activeCompany.id);
                                if(document.getElementById("archiveddata").style.display == 'none')
                                {
                                    document.getElementById("archiveddata").style.display = 'block';
                                    document.getElementById("plusar").innerText = "-";
                                }
                                else
                                {
                                    document.getElementById("archiveddata").style.display = 'none';
                                    document.getElementById("plusar").innerText = "+";
                            }
                            }}><span id="plusar">+</span> {ts("Archived templates", this.props.userLogin.language)}</button>
                            <div id="archiveddata" style={{display:"none"}}>
                            {!this.state.loadingArchive &&<div className='row' style={{margin:"0px",marginTop:"20px"}}>

                                {this.state.displayedArchiveList.map(element => {
                                    return (this.renderArchivedTemplateButton(element.id, element))

                                })}

                                

                            </div>}</div>
                            <div style={{float:"right"}} className="rightpaging">
                            {!this.state.loadingArchive && this.state.archivedTemplates.length > this.state.perPage && <ReactPaginate
                            previousLabel={ts("Previous",this.props.userLogin.language)}
                            nextLabel={ts("Next",this.props.userLogin.language)}
                            breakLabel={"..."}
                            pageCount={this.state.pageArchiveCount}
                            marginPagesDisplayed={this.state.perPage}
                            pageRangeDisplayed={2}
                            onPageChange={this.handleArchivePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"subPagination"}
                            activeClassName={"activePage "}
                            breakClassName={'eachPage'}
                            previousClassName={'eachPagePrevNex'}
                            nextClassName={'eachPagePrevNex'}
                            pageClassName={'eachPage'}
                        />}</div><div style={{clear:"both"}}></div>
                            {renderDivider()}

                            {this.state.customtemplates && renderPageHeader(ts("Designed Templates",this.props.userLogin.language))}

                            <div className='flexrow  wrap'>
                                {/*[{id:'1',img:'https://cdn.templates.unlayer.com/previews/easter-egg-hunt-sports/1629658913652.png'}, {id:'2',img:'https://cdn.templates.unlayer.com/previews/user-quick-survey/1623672660914.png'}, {id:'3',img:'https://cdn.templates.unlayer.com/previews/women-equality-day/1613543554270.png'}, {id:'4',img:'https://cdn.templates.unlayer.com/previews/herbal-cosmetics/1618315318779.png'}, {id:'5',img:'https://cdn.templates.unlayer.com/previews/gardening/1614346259022.png'}, {id:'6',img: '/dist/img/template5thumb.jpg'}].map(element => {
                                    return (<div className='m-3 handCursor' style={{ borderRadius: 8 }} onClick={() => { this.openCampaignEditor(-1, {},element.id) }}>
                                        <img src={element.img} width="180" className='' style={{ objectFit: 'contain', height:'200px' }} />
                                        <div className='text-center'>template {element.id}   </div>
                                    </div>)
                                })*/
                                this.state.customtemplates && this.state.customtemplates.map(element=>{
                                    return (<div className='m-3 handCursor' style={{ borderRadius: 8 }} onClick={() => { this.openCampaignEditor(-1, {},element.id) }}>
                                        <img src={element.thumb} width="180" className='' style={{ objectFit: 'contain', height:'200px' }} />
                                        <div className='text-center'>{element.name}   </div>
                                    </div>)
                                })
                                }
                            </div>
                        </div>
                    )

                }


            </div>
        )
    }
}
const mapStateToProps = state => {

    return {
        userLogin: state.SaveLogin.userData,
    };
};
export default connect(
    mapStateToProps
)(CampaignsTemplate)