import React, { Component } from 'react'
import { Image, Button, Badge, Modal, Form, Spinner } from 'react-bootstrap';
import * as G from '../components/global'
import history from '../components/history';
import { connect } from 'react-redux';
//import template from '../components/assets/Template_13.html'
import Autocomplete from '../components/otherComponents/Autocomplete';
import ReactPaginate from 'react-paginate';
import { translateScreen as ts, translateScreen2 as ts2, translateDate as td } from '../components/dictionary'

class exportProfiles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeScreen: 0,
            allCompanies: [],
            activeCompany: {},
            ClientTemplates: [],
            activeTemplate: {},
            modalShow: false,
            fields: {},
            errors: {},
            CountryList: [],
            CityList: [],categoriesList:[],
            availableGender: [],
            filteredCountryList: [],
            filteredCategoriesList: [],
            filteredCityList: [],
            profileList: [],
            offset: 0,
            perPage: 9,
            currentPage: 0,
            pageCount: 0,
            ShowProfilesList: false,
            filterString: '',
            loadTemplates: true,
            loadProfiles: false

        }
        this.handleChange = this.handleChange.bind(this);
        this.handleUpdateAutoComplete = this.handleUpdateAutoComplete.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
    }
    //#region Handle Change, Autocomplete GetData
    componentDidMount(){
        history.listen((location, action) => {
            if(location.pathname == "/ExportProfiles" || location.pathname == "/ExportProfiles/")
                this.setState({ activeScreen: 0 });
            
          });
    }
    handleUpdateAutoComplete(e, name, value) {
        let { fields } = this.state;
        fields[name] = value;
        if (name === 'country') {
            let countryArray = this.state.filteredCountryList;
            if (countryArray.filter(element => element === value).length === 0) countryArray.push(value)
            this.setState({ filteredCountryList: countryArray })
        }
        if (name === 'category') {
            let categoryArray = this.state.filteredCategoriesList;
            if (categoryArray.filter(element => element === value).length === 0) categoryArray.push(value)
            this.setState({ filteredCategoriesList: categoryArray })
        }
        if (name === 'city') {
            let cityArray = this.state.filteredCityList;
            if (cityArray.filter(element => element === value).length === 0) cityArray.push(value)
            this.setState({ filteredCityList: cityArray })
        }
        fields[name] = ''

        this.setState({ fields })
    }
    handleChange(e) {
        let { fields, errors } = this.state;
        fields[e.target.name] = e.target.value;
        delete errors[e.target.name];

        this.setState({ fields });
    }
    dateFormat = require("dateformat");

    refreshDataClients = async () => {

        if (this.props.userLogin == null || this.state.allCompanies.length > 0) return;
        let paramArr = [['addColumns', ' (select count(id) from campaigntemplate where clientid = a.id) Count']]

        if (this.props.userLogin.clientID > 0) paramArr.push(['filter', " a.id = " + this.props.userLogin.clientID])

        let result = await G.getData('client/query', paramArr);
        result = await result.data;
        this.setState({ allCompanies: result })
        if (result.length > 0) {
            this.setState({ activeCompany: result[0] })
            this.refreshData(result[0].id)
            this.getDistinctValues();
        }


    }
    getDistinctValues = async () => {

        let result = await G.getData('GetDistinctProfilesTypes', [['clientID', this.state.activeCompany.id]], true);
        result = await result.data

        let co = []; let ci = []; let ge = []; let subs = [];
        result.forEach(element => {
            if (element.type === 'co') { co.push(element.name) }
            if (element.type === 'ci') { ci.push(element.name) }
            if (element.type === 'ge') { ge.push( ts2(element.name == 0 ? 'Only Males' : 'Only Females',this.props.userLogin.language)) }
        })
        
        subs.push("subscribed");
        subs.push("unsubscribed");

        this.setState({ CountryList: co, CityList: ci, availableGender: ge,availableSubs:subs })

    }

    //#endregion

    //#region listTemplates,renderTemplateButton
    dateFormat = require("dateformat");
    openCampaignEditor = (templateID) => {


        this.setState({ activeScreen: 2, activeTemplate: templateID })


    }
    listTemplates = (companyId, companyDetails) => {

        this.setState({ ClientTemplates: [], displayedList: [], activeCompany: companyDetails })
        this.refreshData(companyId);

        this.setState({ activeScreen: 2 })

    }

    //#endregion

    //#region Grid PRofiles
    refreshData = async (company) => {
        this.setState({ loadTemplates: true })
        let result = await G.getData('campaigntemplate/query', [['filter', 'clientID = ' + company]]);
        result = await result.data;
        this.setState({ ClientTemplates: result })
        this.setState({ loadTemplates: false })
        let array = []
        array = await G.getData("hotspotCategory/query", [['filter','(hide is null or hide=0) and clientID=' + company]])
        array = await array.data
        this.setState({ hotspotCategory: array })
        let arrayNames = [];
        array.map(element => {
          arrayNames.push(element.name)
        })
        this.setState({ categoriesList:arrayNames })
    }
    setdisplayList(startnumber) {
        let sliceArray = [...this.state.profileList]

        if (this.state.profileList.length > this.state.perPage) {
            sliceArray = sliceArray.slice(startnumber * this.state.perPage, startnumber * this.state.perPage + this.state.perPage)

        }
        this.setState({ displayedList: sliceArray })
    }
    onKeyDown = e => {

        if (e.keyCode === 13) {
            e.preventDefault();

            this.filterGrid()
        }
    }
    filterGrid = () => {
        let filter = this.state.fields['filter']

        if (filter === "" || filter == null) {
            this.setState({ profileList: [...this.state.originalList] }, () => { this.setdisplayList(0) })

        } else {
            this.setState({
                profileList: [...this.state.originalList.filter(element =>
                    element.firstName.toLowerCase().includes(filter.toLowerCase())
                    || element.lastName.toLowerCase().includes(filter.toLowerCase())
                    || element.emailAddress.toLowerCase().includes(filter.toLowerCase())
                    || element.city.toLowerCase().includes(filter.toLowerCase())
                    || element.country.toLowerCase().includes(filter.toLowerCase())
                    || element.mobile.toLowerCase().includes(filter.toLowerCase())
                )]
            }, () => { this.setdisplayList(0) })

        }
    }
    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.setdisplayList(selectedPage)
        });

    };
    QueryProfiles = async () => {

        this.setState({ loadProfiles: true, profileList: [] })
        let { fields, filteredCountryList, filteredCityList,filteredCategoriesList } = this.state
        let { age } = this.state.fields
        let errors = {};
        let filter = "  clientID =  " + this.state.activeCompany.id
        let filterage = ''
        if (age != undefined && age != null) {
            if (age !== '') {
                let varAge = age.replace(/ /g, '')
                varAge = varAge.split(",")
                let Error = '';

                varAge.map(element => {
                    if (isNaN(element.replace(/-/g, ''))) { Error = "Invalid Age Fitler. Please Enter Age Or Age Ranges seperated by comma ','" }
                    else {
                        if (element !== '') {
                            if (element.includes("-")) {
                                element.split("-").map(subElement => {
                                    if (isNaN(subElement)) { Error = "Invalid Age Fitler. Please Enter Age Or Age Ranges seperated by comma ','" }
                                })
                            }


                            if (Error === '') {

                                if (element.includes("-")) {
                                    filterage += ' or TIMESTAMPDIFF(YEAR, age, CURDATE()) between ' + element.split("-")[0] + ' and ' + element.split("-")[1]
                                } else {
                                    filterage += ' or TIMESTAMPDIFF(YEAR, age, CURDATE()) = ' + element
                                }
                            }
                        }

                    }
                })

                if (Error !== '') {
                    errors['age'] = Error
                    this.setState({ errors })
                    return
                }

            }
        }
        if (filterage.trim() !== '') filter += ' and ( ' + filterage.trim().substring(2) + ')'
        
        if (fields['gender'] === ( this.props.userLogin.language===0 ? 'Only Males' : 'Seulement des mâles')) { filter += " and gender=0" }
        if (fields['gender'] === ( this.props.userLogin.language===0 ? 'Only Females' : "Seulement des femelle")) { filter += " and gender=1" }
        if (fields['subscription'] === "subscribed") { filter += " and emailAddress not in (select emailAddress from unsubscribe)"; }
        if (fields['subscription'] === "unsubscribed") { filter += " and emailAddress in (select emailAddress from unsubscribe)"; }
        filter += filteredCountryList.length === 0 ? '' : " and country in (" + filteredCountryList.map(element => "'" + element + "'") + ")"
        filter += filteredCategoriesList.length === 0 ? '' : " and  id in (select customerID from campaigncustomerhotspot where hotspotID in(select hotspotId from hotspotcategoryrelation where categoryId in (select id from hotspotcategory where name in (" + filteredCategoriesList.map(element => "'" + element + "'") + "))))" 
        filter += filteredCityList.length === 0 ? '' : " and city in (" + filteredCityList.map(element => "'" + element + "'") + ")"
        //if (this.state.activeCompany.id > 0) { filter += " and clientID = " + this.state.activeCompany.id }

        this.setState({ filterString: filter })
        let result = await G.getData('getprofileswithsubscriptionstatus', filter === '' ? [] : [['filter', filter]]);
        result = await result.data;
        
        let pageCounts = result.length / this.state.perPage
        this.setState({ profileList: result, pageCount: pageCounts, originalList: result })
        this.setdisplayList(0)
        
        this.setState({ loadProfiles: false })
    }

    //#endregion
    downloadList(type) {
        let text = '';

        if (type === 'CSV') {
            text = G.makeCSV(this.state.profileList)
            // this.state.profileList.map(element => {
            //     let line = ''
            //     for (var key of Object.keys(element)) {

            //         if (element[key]){line += "," + element[key] }else{line += ",''"}
            //         //text += (element[key] ? element[key] : '') + ',' 
            //         // element ? text+= 'null' : text += element[key] + ","
            //     }
            //     text += line.substring(1) 
            // })

        } else {

            this.state.profileList.map(element => {

                text += JSON.stringify(element)
                text += ","

            })

        }


        G.downloadFile(text, 'Profiles List', type === 'CSV' ? 'csv' : 'txt')
    }

    render() {
        if (this.state.allCompanies != null) {

            if (this.state.allCompanies.length === 0) { this.refreshDataClients(); }

        }
        const { fields } = this.state
        return (
            <div style={{ width: '95%' }}>

                {this.state.activeScreen === 0 &&
                    <div>

                        <h4 className='mb-5 mt-5'>{ts('Available Clients', this.props.userLogin.language)}</h4>
                        <div className='p-3 mr-3 '>
                            <div className="table-responsive p-0 table-hover" style={{ maxHeight: 900 }}>
                                <table className="table table-head-fixed text-nowrap table-hover ">
                                    <thead>
                                        <tr>
                                            <th>Action</th>
                                            <th>ID</th>
                                            <th>{ts('Client Name', this.props.userLogin.language)}</th>
                                            <th>{ts('Contact Person', this.props.userLogin.language)}</th>
                                            <th>{ts('Phone', this.props.userLogin.language)}</th>
                                            <th>{ts('Email Address', this.props.userLogin.language)}</th>
                                            <th>{ts('Created In', this.props.userLogin.language)}</th>
                                            <th>{ts('Number of Campaigns', this.props.userLogin.language)} </th>


                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            (this.state.allCompanies).map((item) => {

                                                return (
                                                    <tr key={item.id}>
                                                        <td className='xxxtitle shiteColor handCursor'
                                                            onClick={() => { this.listTemplates(item.id, item);history.push("/ExportProfiles/list") }}>
                                                            <Badge variant='info'>{ts('View Profiles', this.props.userLogin.language)}</Badge>
                                                        </td>
                                                        <td>{item.id}</td>
                                                        <td>{item.companyName}</td>
                                                        <td>{item.contactPerson}</td>
                                                        <td>{item.contactPersonNo}</td>
                                                        <td>{item.email}</td>
                                                        <td>{td(this.dateFormat(item.addDate, 'dddd, mmm dd yyyy h:MM tt'), this.props.userLogin.language)}</td>
                                                        <td>{item.Count}</td>


                                                    </tr>)
                                            })
                                        }

                                    </tbody>
                                </table>
                            </div>

                        </div>

                    </div>

                }


                {this.state.activeScreen === 2 &&
                    <div>
                        <Button className='mb-4 mt-5' variant='warning' onClick={() => { this.setState({ activeScreen: 0 });history.go(-1) }} >{ts('Cancel / Return', this.props.userLogin.language)}</Button>

                        <div className='flexrow '>
                            {/* {this.state.profileList.length > 0 &&
                                    <div>
                                        <div className='bold mb-2 p-3 mt-5 colSupLigGra '>Export Profiles</div>
                                        <Button variant="success" className='col-12 mt-5' onClick={() => {
                                            this.setState({ modalShow: true })
                                        }}>Download Profiles</Button>
                                        
                                    </div>
                                } */}
                            <div className='pt-5 pl-5 col-12'>
                                <div className='bold mb-4 p-3 colSupLigGra '>Profiles Selection</div>
                                <div className='flexrow spacebetween pl-2 mb-5'>
                                    <div className='col-12' >

                                        <div className='flexrow '>
                                        <div className='col-6'>
                                            {G.renderDropDown("Gender", this.state.availableGender, this.handleChange, this.state.fields, this.state.errors, ts2("All Genders", this.props.userLogin.language), false, this.props.userLogin.language)}
                                            </div>
                                            <div className='col-6'>
                                                {G.renderTextField("Age", 'text', "All Ages", this.handleChange, this.state.fields, this.state.errors, ts('Filter Example 18-24,35, 50-54', this.props.userLogin.language), '', false, this.props.userLogin.language,true)}
                                                <p className='sremarks hint  ml-3'>{ts('Filter Example 18-24,35,50-54 is equal for (all ages between 18 and 24) or (age equal 35) or (age between 50 and 54)', this.props.userLogin.language)}    </p>
                                            </div>
                                        </div>
                                        <div className='flexrow '>
                                        <div className='col-6'>
                                            {G.renderMultiSelectionBox('Countries', this.state.filteredCountryList, ts('All Countries Selected', this.props.userLogin.language),
                                                (removedItem) => { this.setState({ filteredCountryList: [...this.state.filteredCountryList].filter(element => element !== removedItem) }) }
                                                , <Autocomplete showborders={true} reset={true} value={this.state.fields['country'] || ''} error={this.state.errors['country']} name='country' updateFromList={this.handleUpdateAutoComplete} suggestions={this.state.CountryList}
                                                    placeholder={ts('Add Countries Filter Here', this.props.userLogin.language) + ' (' + (this.props.userLogin.language === 0 ? G.countItems(this.state.CountryList.length, "Country", 'Countries') : this.state.CountryList.length + ' Pays') + ' ' + ts('Available', this.props.userLogin.language) + ')'} classNames='fullWidth' onChange={this.handleChange} />
                                                , ''


                                                , this.props.userLogin.language)}
                                                </div>
                                                <div className='col-6'>
                                            {G.renderMultiSelectionBox('Cities', this.state.filteredCityList, ts('All Cities Selected', this.props.userLogin.language),
                                                (removedItem) => { this.setState({ filteredCityList: [...this.state.filteredCityList].filter(element => element !== removedItem) }) }
                                                , <Autocomplete showborders={true} reset={true} value={this.state.fields['city'] || ''} error={this.state.errors['city']} name='city' updateFromList={this.handleUpdateAutoComplete} suggestions={this.state.CityList}
                                                    placeholder={ts('Add Cities Filter Here', this.props.userLogin.language) + ' (' + (this.props.userLogin.language === 0 ? G.countItems(this.state.CityList.length, "City", 'Cities') : G.countItems(this.state.CityList.length, 'Ville')) + ' ' + ts('Available', this.props.userLogin.language) + ')'} classNames='fullWidth' onChange={this.handleChange} />
                                                , ''
                                                , this.props.userLogin.language)}
                                                </div>
                                        </div>
                                        <div className='flexrow'>
                                        <div className='col-6'>
                                        {G.renderMultiSelectionBox('Interactions categories', this.state.filteredCategoriesList, ts('All Categories Selected', this.props.userLogin.language),
                                                (removedItem) => { this.setState({ filteredCategoriesList: [...this.state.filteredCategoriesList].filter(element => element !== removedItem) }) }
                                                , <Autocomplete showborders={true} reset={true} value={this.state.fields['category'] || ''} error={this.state.errors['category']} name='category' updateFromList={this.handleUpdateAutoComplete} suggestions={this.state.categoriesList}
                                                    placeholder={ts('Add Categories Filter Here', this.props.userLogin.language) + ' (' + (this.props.userLogin.language === 0 ? G.countItems(this.state.categoriesList.length, "Category", 'Categories') : this.state.categoriesList.length + ' Categories') + ' ' + ts('Available', this.props.userLogin.language) + ')'} classNames='fullWidth' onChange={this.handleChange} />
                                                , ''


                                                , this.props.userLogin.language)}
                                                </div>
                                                <div className='col-6'>
                                                {G.renderDropDown("Subscription", this.state.availableSubs, this.handleChange, this.state.fields, this.state.errors, ts2("Subscription status", this.props.userLogin.language), false, this.props.userLogin.language)}
                                                </div>
                                            </div>
                                    </div>
                                    
                                </div>
                                {/*<div className='flexrow spacebetween'>
                                    <div className='col-12' >

                                        <div className='flexrow '>
                                            {G.renderDropDown("Gender", this.state.availableGender, this.handleChange, this.state.fields, this.state.errors, ts2("All Genders",this.props.userLogin.language), false, this.props.userLogin.language)}
                                            <div className='col-6'>
                                                {G.renderTextField("Age", 'text', "All Ages", this.handleChange, this.state.fields, this.state.errors, ts('Filter Example 18-24,35, 50-54', this.props.userLogin.language), 'ml-3', false, this.props.userLogin.language)}
                                                <p className='sremarks hint  ml-3'>{ts('Filter Example 18-24,35,50-54 is equal for (all ages between 18 and 24) or (age equal 35) or (age between 50 and 54)', this.props.userLogin.language)}    </p>
                                            </div>
                                        </div>
                                        <div className='flexrow'>
                                            {G.renderMultiSelectionBox('Country', this.state.filteredCountryList, ts('All Countries Selected', this.props.userLogin.language),
                                                (removedItem) => { this.setState({ filteredCountryList: [...this.state.filteredCountryList].filter(element => element !== removedItem) }) }
                                                , <Autocomplete value={this.state.fields['country'] || ''} error={this.state.errors['country']} name='country' updateFromList={this.handleUpdateAutoComplete} suggestions={this.state.CountryList} placeholder={ts('Add Countries Filter Here', this.props.userLogin.language) + ' (' + (this.props.userLogin.language === 0 ? G.countItems(this.state.CountryList.length, "Country", 'Countries') : this.state.CountryList.length + ' Pays') + ' ' + ts('Available', this.props.userLogin.language) + ')'}
                                                    classNames='fullWidth' onChange={this.handleChange} />

                                                , ''


                                                , this.props.userLogin.language)}
                                            {G.renderMultiSelectionBox('Cities', this.state.filteredCityList, ts('All Cities Selected', this.props.userLogin.language),
                                                (removedItem) => { this.setState({ filteredCityList: [...this.state.filteredCityList].filter(element => element !== removedItem) }) }
                                                , <Autocomplete value={this.state.fields['city'] || ''} error={this.state.errors['city']} name='city' updateFromList={this.handleUpdateAutoComplete} suggestions={this.state.CityList}
                                                    placeholder={ts('Add Cities Filter Here', this.props.userLogin.language) + ' (' + (this.props.userLogin.language === 0 ? G.countItems(this.state.CityList.length, "City", 'Cities') : G.countItems(this.state.CityList.length, 'Ville')) + ' ' + ts('Available', this.props.userLogin.language) + ')'}
                                                    classNames='fullWidth' onChange={this.handleChange} />
                                                , 'ml-3'
                                            )}
                                        </div>

                                    </div>


                                </div>*/}
                                <div className='flexrow'>
                                    <Button className='ml-2 mb-3' onClick={this.QueryProfiles}> {ts('Query Profiles', this.props.userLogin.language)}</Button>
                                    {/* {this.state.profileList.length > 0 &&
                                        <Button variant="success" className='ml-2 mb-3' onClick={() => { this.downloadList('CSV') }}>Download Profiles CSV</Button>} */}
                                    {this.state.profileList.length > 0 &&
                                        <Button variant="success" className='ml-2 mb-3' onClick={() => { this.downloadList('JSON') }}>{ts2('Download Profiles JSON', this.props.userLogin.language)}</Button>}
                                </div>


                                {this.state.loadProfiles && <div className='flexcolumn center verticalcenter p-5 fullWidth hint '>
                                    {ts('Query For Profiles', this.props.userLogin.language)}
                                    <Spinner animation="border" className='mt-3' variant="warning" />

                                </div>}


                                <div>

                                    {this.state.profileList.length > 0 &&
                                        <div className='fullWidth mt-3 mb-3'>
                                            <div className='bold mb-4 p-3 colSupLigGra '>{ts('Total Profiles Found in ', this.props.userLogin.language) + (this.state.activeCompany.companyName ? this.state.activeCompany.companyName : 'No Company Selected')}</div>
                                            <h6 className='mb-3'>{G.countItems(this.state.profileList.length, ts('Profile', this.props.userLogin.language)) + '  ' + ts('Found', this.props.userLogin.language) + '      |       '}  {<span className='link' onClick={() => { this.setState({ ShowProfilesList: !this.state.ShowProfilesList }) }}>{this.state.ShowProfilesList ? ts('Hide Profiles List', this.props.userLogin.language) : ts('Show Profiles List', this.props.userLogin.language)}</span>}</h6>
                                            {/* <div className='flexrow end pb-3 '>
                                            <Button onClick={() => { this.setState({ activeScreen: 2 }) }} className='mr-3'>Create Profile</Button>
                                            <Button onClick={() => { this.setState({ activeScreen: 0 }) }}>Cancel</Button>
                                        </div> */}


                                            {this.state.ShowProfilesList && <div>
                                                <div className='p-3 mr-3 '>
                                                    <Form.Label  >{ts2('Filter', this.props.userLogin.language)}</Form.Label>
                                                    <div className='flexrow col-8'>
                                                        <Form.Control onKeyDown={this.onKeyDown} name='filter' type={'text'} placeholder={ts2('Filter Result', this.props.userLogin.language)} onChange={this.handleChange} value={fields['filter'] || ''} />
                                                        {this.state.fields['filter'] && this.state.fields['filter'] !== '' && <Button variant='warning ml-2' onClick={this.filterGrid}>{ts2('Apply',this.props.userLogin.language)}</Button>}
                                                        {this.state.fields['filter'] && this.state.fields['filter'] !== '' && <Button variant='info ml-2' onClick={() => {
                                                            let { fields } = this.state;
                                                            delete fields['filter'];
                                                            this.setState({ fields });

                                                            this.filterGrid()
                                                        }}>{ts('Clear',this.props.userLogin.language)}</Button>}
                                                    </div>
                                                </div>
                                                <div className='p-3 mr-3 '>
                                                    <div className="table-responsive p-0" style={{ maxHeight: 900 }}>
                                                        <table className="table table-head-fixed text-nowrap table-hover ">
                                                            <thead>
                                                                <tr>

                                                                    <th>ID</th>
                                                                    <th>{ts2('First Name', this.props.userLogin.language)}</th>
                                                                    <th>{ts2('Last Name', this.props.userLogin.language)}</th>
                                                                    <th>{ts2('Email', this.props.userLogin.language)}</th>
                                                                    <th>Mobile</th>
                                                                    <th>{ts('Gender', this.props.userLogin.language)}</th>
                                                                    <th>Age</th>
                                                                    <th>{ts('Country', this.props.userLogin.language)}</th>
                                                                    <th>{ts('City', this.props.userLogin.language)}</th>
                                                                    <th>{ts('Created In', this.props.userLogin.language)}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    (this.state.displayedList).map((item) => {

                                                                        return (
                                                                            <tr key={item.id}>
                                                                                <td>{item.id}</td>
                                                                                <td>{item.firstName}</td>
                                                                                <td>{item.lastName}</td>
                                                                                <td>{item.emailAddress}</td>
                                                                                <td>{item.mobile}</td>
                                                                                <td>{item.gender === 0 ? ts('Male',this.props.userLogin.language) : item.gender === 1 ? ts('Female',this.props.userLogin.language)  : 'not Defined'}</td>
                                                                                <td>{this.props.userLogin.language === 0? G.countItems(G.getAge(item.age), 'year') + ' old': G.getAge(item.age) + ' ans'} </td>
                                                                                <td>{item.country}</td>
                                                                                <td>{item.city}</td>
                                                                                <td>{td(this.dateFormat(item.addDate, 'dddd, mmm dd yyyy h:MM tt'), this.props.userLogin.language)}</td>



                                                                            </tr>)
                                                                    })
                                                                }

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            }
                                            {this.state.profileList.length > this.state.perPage && this.state.ShowProfilesList &&
                                                <ReactPaginate
                                                    previousLabel={ts("Previous", this.props.userLogin.language)}
                                                    nextLabel={ts("Next", this.props.userLogin.language)}
                                                    breakLabel={"..."}
                                                    pageCount={this.state.pageCount}
                                                    marginPagesDisplayed={this.state.perPage}
                                                    pageRangeDisplayed={2}
                                                    onPageChange={this.handlePageClick}
                                                    containerClassName={"pagination"}
                                                    subContainerClassName={"subPagination"}
                                                    activeClassName={"activePage "}
                                                    breakClassName={'eachPage'}
                                                    previousClassName={'eachPagePrevNex'}
                                                    nextClassName={'eachPagePrevNex'}
                                                    pageClassName={'eachPage'}
                                                />}



                                        </div>
                                    }


                                </div>




                            </div>
                        </div>

                    </div>
                }

            </div>
        )
    }
}
//#region "Redux"
const mapStateToProps = state => {

    return {
        userLogin: state.SaveLogin.userData,
    };
};
export default connect(
    mapStateToProps
)(exportProfiles)
//#endregion



class SelectionCheckbox extends Component {
    render() {
        return (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            //animation={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {this.props.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ width: '100%' }}>
                    {ts2('Are you sure you want to start your campaign now?', this.props.userLogin.language)}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className='flexrow end  '>
                        <Button onClick={this.props.startCampaign} className='mr-3'>{ts2('Start Campaign', this.props.userLogin.language)}</Button>
                        <Button onClick={this.props.onHide}>{ts('Cancel', this.props.userLogin.language)}</Button>
                    </div>

                </Modal.Footer>
            </Modal>

        )
    }
}
