import React, { Component } from 'react'
import logo from '../assets/logo.png'
import { connect } from 'react-redux'
import { translateScreen as ts, generateFrenchLanguage as gn } from '../dictionary'
import france from '../assets/france.png'
import english from '../assets/english.png'
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import * as G from '../global';

class Header extends Component {
    logOut = () => { this.props.logOut() }
    render() {
        return (
            <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                {/* Left navbar links */}
                <ul className="navbar-nav">
                    <div className='flexrow '>
                        {/* <li className="nav-item"> */}
                        <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars" /></a>
                        <div className='flexrow   verticalcenter  '>{<img src={logo} alt="SOW Logo" className="   ml-2 " width={130} />}</div>
                        {/* </li> */}
                    </div>
                </ul>
                {/* <Button onClick={()=>{gn()}}>generate dictionary</Button> */}
                {/* Right navbar links */}
                <ul className="navbar-nav ml-auto">
                    <div className='flexrow verticalcenter pr-2'><img width={16} height={16} src={this.props.userLogin.language === 0 ? english : france} /></div>
                    <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip id="button-tooltip" > {this.props.userLogin.language === 0 ? 'Cliquez pour passer en français' : 'Click to switch to English'}</Tooltip>}
                    >
                        <div className='flexrow verticalcenter pr-4 hint handCursor bold' onClick={() => {
                            let a = { ...this.props.userLogin }
                            if (a.language === 0) { a.language = 1 } else { a.language = 0 };
                            var result = G.getData('switchLanguage', [['username', this.props.userLogin.username], ['language', a.language]])
                            this.props.saveUserData(a)
                        }}> {this.props.userLogin.language !== 0 ? 'Français' : 'English'} </div>
                    </OverlayTrigger>
                    <li className="nav-item ">
                        <a className="nav-link" data-widget="fullscreen" href="#" role="button">
                            <i className="fas fa-expand-arrows-alt" /> <span className=' bold showRightHeaderText'> {ts('Full Screen', this.props.userLogin.language)} </span>
                        </a>
                    </li>

                    <div onClick={this.logOut} className="nav-link handCursor whiteColor ml-3">
                        <i className="nav-icon fas fa-sign-out-alt " /> <span className='bold showRightHeaderText'>{ts('Log Out', this.props.userLogin.language)}</span>
                    </div>

                </ul>
            </nav>

        )
    }
}

const mapDispachToProps = dispatch => {
    return {

        saveUserData: (data) => { dispatch({ type: '', payLoad: data }) },

    };
};
const mapStateToProps = state => {

    return {
        userLogin: state.SaveLogin.userData,
    };
};
export default connect(
    mapStateToProps, mapDispachToProps
)(Header)