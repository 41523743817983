import React, { Component } from 'react';
import { Button, Container, Form, Col, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
import '../styles/glob.css'
import * as G from '../components/global';
//import { country_list as CTRY } from '../components/constants';
import { connect } from 'react-redux';
import logo from '../components/assets/logo.png'
import france from '../components/assets/france.png'
import english from '../components/assets/english.png'

import { translateScreen as ts , translateScreen2 as ts2 , translateDate as td} from '../components/dictionary'



class regitrationRequest extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fields: {},
            errors: {},
            result: [],
            sent: false,
            submitError: '',
            loadingSave: false,
            language: 0
        }
        this.handleChange = this.handleChange.bind(this);
    }
componentDidMount(){
     
    if (sessionStorage.getItem("language")=='1')this.setState({language:1})
}
    //#region Form Validation
    handleChange(e) {
        let { fields, errors } = this.state;
        fields[e.target.name] = e.target.value;
        delete errors[e.target.name];
        this.setState({ fields });
    }
    handleValidation = async () => {
        const { email, companyname, contactperson, phonenumber } = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if (!email) { formIsValid = false; errors["email"] =  ts2("Email cannot be empty",this.state.language); } else {
            if (!G.isValideEmail(email)) { errors["email"] =  ts2('This is not a valid Email',this.state.language); }
        }
        if (!companyname) { formIsValid = false; errors["companyname"] =  ts2("Company name cannot be empty",this.state.language); }
        if (!phonenumber) { formIsValid = false; errors["phonenumber"] =  ts2("Phone number name cannot be empty",this.state.language); }
        if (!contactperson) { formIsValid = false; errors["contactperson"] =  ts2("Contact Person name cannot be empty",this.state.language); }
        this.setState({ errors: errors });
        if (formIsValid) {
            G.log('Form is valid. pushing now values...')
            var result = await G.getData('registrationRequet',
                [
                    ['companyname', this.state.fields['companyname']],
                    ['contactperson', this.state.fields['contactperson']],
                    ['phonenumber', this.state.fields['phonenumber']],
                    ['email', this.state.fields['email']],
                    ['log', '']
                ]

            )
            return (result.status === 200)
        }
        else {
            return formIsValid;
        }
    }
    submitForm = async (e) => {
        e.preventDefault();

        this.setState({ submitError: '', loadingSave: true })
        var a = await this.handleValidation();
        if (a === true) {

            this.setState({ sent: true })
        } else { this.setState({ submitError: 'Error appeared while trying to submit.' }) }
        this.setState({ loadingSave: false })
    }
    //#endregion

    render() {
        const { errors, fields } = this.state;
        return (
            <Container className='flexcolumn  fullWidth miniContentHeight verticalcenter   '>
                {/* <div className=' slideshow-wrapper col-12 '>
                <img style={{ objectFit: 'cover', width: '60%', marginBottom:75 }} src={logo} />
                    <p className='title '>Welcome</p>

                </div> */}

                <Container className='  flexcolumn col-12  cwhite boxBOttomRadius10' style={{ borderRadius: 15, marginTop: 15 }}>
                    <div className='text-center'>
                        <img style={{ objectFit: 'cover', width: '60%', marginTop: 45 }} src={logo} />
                    </div>
                    <Container className=' mt-5 mb-5 '>
                        {!this.state.sent &&

                            <Form name="form" onSubmit={this.submitForm.bind(this)} >
                                <div className=' text-center sstitle'>{ts2('Please enter the required info bellow and send your registration request.',this.state.language)}  </div>
                                <div className=' text-center mb-5 ssstitle'>{ts2('Our team will reply as soon as possible. Usually within 4 hours',this.state.language)} </div>
                                <Form.Row>
                                    <Col>
                                        {G.renderTextField("Company Name", 'text', ts2("Enter your company name",this.state.language), this.handleChange, fields, errors, '','',false,this.state.language)}
                                    </Col>

                                </Form.Row>
                                <Form.Row>
                                    <Col>
                                        {G.renderTextField("Contact Person", 'text', ts2("Enter your contact person",this.state.language), this.handleChange, fields, errors, '','',false,this.state.language)}
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col>
                                        {G.renderTextField("Phone Number", 'text', ts2("Enter your mobile/Phone number",this.state.language), this.handleChange, fields, errors, '','',false,this.state.language)}
                                    </Col>
                                    <Col>
                                        {G.renderTextField("Email", 'email', ts2("Enter email address",this.state.language), this.handleChange, fields, errors)}
                                    </Col>
                                </Form.Row>


                                <div className='flexrow center fullWidth text-right mt-5 mb-3 wrap '>

                                    <Button variant='success' className='cprim m-1 pl-5 pr-5' type="submit"> {this.state.loadingSave && <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />}{ts2(this.state.submitError === '' ? 'Send Registration' : 'Retry', this.state.language)}</Button>
                                    <Button variant='warning' className=' m-1  pr-5 text-center' onClick={() => { this.props.history.push(G.websiteRoute.signIN); }}>

                                   { ts('Cancel',this.state.language)}</Button>
                                </div>
                                <div className='flexrow center warningColor remarks text-center mb-3'>{this.state.submitError}</div>

                                

                            </Form>
                        }
                        <div className='flexrow verticalcenter pr-2 center handCursor '>
                            <img width={12} height={12} src={this.state.language  === 0 ? english : france} />
                            <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip id="button-tooltip" > {this.state.language === 0 ? 'Cliquez pour passer en français' : 'Click to switch to English'}</Tooltip>}
                            >
                                <div className=' ml-2 remarks hint' onClick={() => {
                                    this.setState({ language: this.state.language === 0 ? 1 : 0 },()=>{sessionStorage.setItem('language',this.state.language)});
                                    
                                }}>Language:  {this.state.language !== 0 ? 'Francais' : 'English'} </div>
                            </OverlayTrigger>

                        </div>

                        {this.state.sent &&
                            <div className='text-center'>
                                <div>{ts2('Thank you for registering in our platform. The registration request has been sent.',this.props.userLogin.language)}</div>
                                <div>{ts2('We will contact you soon. Usually within 4 hours',this.props.userLogin.language)}</div>
                                <Button variant='success' onClick={() => { this.props.history.push(G.websiteRoute.signIN); }}>{ts2('Quit',this.props.userLogin.language)}</Button>
                            </div>}
                    </Container>
                </Container>
            </Container>
        )
    }
}
//#region Redux-Component Link
const mapDispachToProps = dispatch => {
    return {

        saveUserData: (data) => { G.log('dispatch', data); dispatch({ type: '', payLoad: data }) },

    };
};

const mapStateToProps = state => {
    return {
        userLogin: state.SaveLogin,
    };
};

//#endregion


export default connect(
    mapStateToProps,
    mapDispachToProps
)(regitrationRequest);
