import React, { Component } from 'react';
import { Button, Container, Form, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
import '../styles/glob.css'
import * as G from '../components/global';
import { connect } from 'react-redux';
import logo from '../components/assets/logo.png'
import { translateScreen as ts , translateScreen2 as ts2 , translateDate as td} from '../components/dictionary'
import france from '../components/assets/france.png'
import english from '../components/assets/english.png'

class SignInForm extends Component {


	constructor(props) {
		super(props);
		this.state = {
			fields: {},
			errors: {},
			result: [],
			load: false,
			language:0,
			showPasswordReset: false,
			successemail: false,
			loginFailed: false,
			failureMessage: 'Your username and password are not available'
		}
		this.handleChange = this.handleChange.bind(this);
	}

	componentDidMount() {
		const token = sessionStorage.getItem('UserData') // your saved token in localstorage

		console.log('params', this.props.params)
		if (token && token !== 'undefined') {            // check for not undefined
			this.props.history.push('/')               // now you can redirect your desired route
		}
		if(sessionStorage.getItem("language")=='1')this.setState({language:1})
	}

	//#region Form Validation
	handleChange(e) {
		let { fields, errors } = this.state;
		fields[e.target.name] = e.target.value;
		delete errors[e.target.name];
		this.setState({ loginFailed: false })
		this.setState({ fields });
	}

	signInSubmit = async (e) => {
		e.preventDefault();
		this.setState({ load: true })
		this.setState({ submitted: true });
		let errors = {};
		let formIsValid = true;
		const { email, password } = this.state.fields;
		if (!email) { formIsValid = false; errors["email"] =  ts2("Email cannot be empty",this.state.language); } else {
			if (!G.isValideEmail(email)) { formIsValid = false; errors["email"] = ts2('This is not a valid Email',this.state.language); }
		}


		if (!this.state.showPasswordReset) if (!password) { formIsValid = false; errors["password"] = ts2("Password cannot be empty",this.state.language); }

		this.setState({ errors });
		console.log(errors)
		if (!formIsValid) {
			this.setState({ load: false })
			return
		}



		if (!this.state.showPasswordReset) {
			G.log('Form is valid. fetching username and password...')
			var result = await G.getData('SignIn', [['username', email], ['password', password]])
			if (result.status === 200) {
				this.setState({ result: await result.data })
				if (this.state.result.length === 0) {
					this.setState({ loginFailed: true, failureMessage:  ts2('Your username and password are not available',this.state.language) })

				} else {
					if (this.state.result[0].active == 0) {
						this.setState({ loginFailed: true, failureMessage: ts2( 'Your username is now deactivated. Please contact the Givenmine',this.state.language) })
					}
					else {
						if (this.state.result[0].active == 0) {
							this.setState({ loginFailed: true, failureMessage:  ts2('Your username is now deactivated. Please contact the Givenmine',this.state.language) })
						} else {
							this.props.saveUserData(this.state.result[0])
							const tokenString = { token: this.state.result[0].Token };
							sessionStorage.setItem(G.sessionStorageVariables.userData, JSON.stringify(tokenString))
							//alert("Welcome " + this.state.result[0].name + ' (Group: ' + G.userType[this.state.result[0].userType] + ')');
							this.setState({ fields: {} })
							//this.props.history.push( {pathname : '/ResetPassword', state: { rs : true }}  );
							this.props.history.push(this.state.result[0].rs ? { pathname: '/ResetPassword', state: { rs: true } } : G.websiteRoute.home);
						}
					}


				}
			}
		} else {
			G.log('Sending reset password email')
			var result = await G.getData('resetPasswordEmail', [['email', email], ['log', '']])
			console.log(result)
			if (result.status === 200) {
				this.setState({ successemail: true })

			}

		}

		this.setState({ load: false })
	}
	//#endregion

	render() {
		const { errors, fields } = this.state;
		return (
			<div className='flexcolumn center fullWidth miniContentHeight verticalcenter  '>
				{/* <div className=' slideshow-wrapper col-sm-10 col-md-8 col-lg-5 '>
					<img style={{ objectFit: 'cover', height: 180, width: '100%' }}
						src={logo} />
					
					<p className='title '>{this.state.showPasswordReset ? 'Reset Password' : 'Sign In'}</p>
				</div> */}
				<div className='  flexcolumn col-sm-10 col-md-8 col-lg-5  cwhite boxBOttomRadius10' style={{borderRadius:15}}>
					<div className=' p-5'>
						<div className="text-center">
							<img style={{ objectFit: 'cover', width: '60%', marginBottom:75 }} src={logo} />
							<div style={{width:'100%',  textAlign:'left', marginBottom: 15}}><p className='titleCopy'>{ts2(this.state.showPasswordReset ? 'Reset Password' : 'Sign In',this.state.language)}</p></div>
						</div>
						<Form name="form" onSubmit={this.signInSubmit.bind(this)} style={{marginLeft:5}} >
							<Form.Row>
								{G.renderTextField("Email", 'email', ts2("Enter your Email address",this.state.language), this.handleChange, fields, errors,'','',false,this.state.language)}
							</Form.Row>
							{!this.state.showPasswordReset && <Form.Row>
								{G.renderTextField("Password", 'password', ts2("Enter password",this.state.language), this.handleChange, fields, errors,'','',false,this.state.language)}
							</Form.Row>}
							{this.state.showPasswordReset && (!this.state.successemail) &&
								<div className='midremarks hint mt-5'>{ts2('Enter your email and tap on Change password. A reset password email will be sent to your inbox',this.state.language)} </div>

							}
							{this.state.showPasswordReset && this.state.successemail &&
								<div className='flexcolumn titles text-center successColor'>
									<i class="far fa-check-circle"></i>
									<div className='midremarks hint mt-5'>{ts2('A password reset email has been sent to your email. Please check your inbox',this.state.language)}</div>
								</div>
							}

							{this.state.loginFailed && !this.state.showPasswordReset &&
								<div className='flexrow remarks warningYellow fullWidth mt-3 verticalcenter center'>
									<i class="fas fa-exclamation-circle"></i>
									<span className='midremarks warningColor ml-2'>{this.state.failureMessage}</span>
								</div>
							}
							<div className='flexrow center pt-3 '>
								{!this.state.successemail && <Button variant='success' className='mt-3 pl-5 pr-5 text-center' type="submit">
									{this.state.load && <Spinner
										as="span"
										animation="border"
										size="sm"
										role="status"
										aria-hidden="true"
										className='mr-2'
									/>}
									 {ts2(this.state.showPasswordReset ? 'Send Password Reset' : 'Log In',this.state.language)}</Button>}
								{this.state.showPasswordReset && <Button variant='warning' className=' ml-3 mt-3 pl-5 pr-5 text-center' onClick={() => { this.setState({ showPasswordReset: false, successemail: false }) }} > {ts(this.state.successemail ? 'Return' : 'Cancel',this.state.language)}</Button>}
							</div>

						</Form>
						{!this.state.showPasswordReset && <div onClick={() => { this.props.history.push(G.websiteRoute.registrationRequest) }}
							className='fullWidth text-center mt-5 mb-2  hint'>{ts2("Don't have an Account?",this.state.language)}<span className='primcolor bold handCursor'> {ts2('Sign Up',this.state.language)}</span></div>
						}
						{!this.state.showPasswordReset && <div className='fullWidth text-center handCursor bold' onClick={() => { this.setState({ showPasswordReset: true }) }}>{ts2('Forget Password',this.state.language)}</div>}

					</div>
					<div className='flexrow verticalcenter pr-2 center handCursor '>
                            <img width={12} height={12} src={this.state.language  === 0 ? english : france} />
                            <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip id="button-tooltip" > {this.state.language === 0 ? 'Cliquez pour passer en français' : 'Click to switch to English'}</Tooltip>}
                            >
                                <div className=' ml-2 remarks hint' onClick={() => {
                                    this.setState({ language: this.state.language === 0 ? 1 : 0 },()=>{sessionStorage.setItem('language',this.state.language)});
                                    
                                }}>Language:  {this.state.language !== 0 ? 'Francais' : 'English'} </div>
                            </OverlayTrigger>

                        </div>
					<p className='text-center hint sremarks mt-2'>Version {G.versionNumber}</p>
				</div>

			</div>
		)
	}
}
//#region Redux-Component Link
const mapDispachToProps = dispatch => {
	return {

		saveUserData: (data) => { G.log('dispatch', data); dispatch({ type: '', payLoad: data }) },

	};
};

const mapStateToProps = state => {
	return {
		userLogin: state.SaveLogin.userData,
	};
};

//#endregion


export default connect(
	mapStateToProps,
	mapDispachToProps
)(SignInForm);
