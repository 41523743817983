import React, { Component } from 'react'
import * as G from '../global';
import history from '../history';
import logo from '../assets/logo.png'
import { connect } from 'react-redux';
import { translateScreen as ts } from '../dictionary'
 class Menu extends Component {

    logOut = () => { this.props.logOut() }

    
    render() {
        const type = this.props.accountType;

        return (

            <aside className="main-sidebar sidebar-dark-primary elevation-4 ">
                {/* Brand Logo */}
                {type < 10
                    && <a href="index3.html" className="brand-link">
                        {/* { <img src={logo} alt="SOW Logo" className="  whitesvg ml-2 " width={160} />  } */}
                        {/* <span className="brand-text font-weight-light ml-2">GIVENMINE</span> */}
                    </a>}
                {/* Sidebar */}
                {type < 10
                    && <div className="sidebar">
                        {/* Sidebar user panel (optional) */}
                        <div className="user-panel mt-3 pb-3 pt-3 mb-3 d-flex">
                            {/* <div className="image">
                                <img src="dist/img/user2-160x160.jpg" className="img-circle elevation-2 " alt="User Image" />
                            </div> */}
                            <div className="info ">
                                <a className=" bold whiteColor">{this.props.userFullName}</a>
                                <p className=' midremarks whiteColor'><span className='bold'>{G.getUserType(type)}</span></p>

                            </div>
                        </div >
                        
                        {/* Sidebar Menu */}
                        <nav className="mt-2">
                            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                                <li className="nav-header mt-3">{ts('My Dashboards',this.props.userLogin.language  )}</li>
                                <li className="nav-item" onClick={() => { history.push("/") ;this.props.changePage("/")}}>
                                    <div className="nav-link  handCursor " style={this.props.currentPage==='/' ?{color:'white', backgroundColor:'#494e53'} : {}}>
                                        <i className="nav-icon far fa-chart-bar" />
                                        <p >
                                        {ts('Summary',this.props.userLogin.language  )}
                                        </p>
                                    </div>
                                </li>
                                {(type < 2 || type == 4) && <li className="nav-header mt-5">{ts('Third Parties',this.props.userLogin.language  )}</li>}


                                {type === 0 &&
                                    <li className="nav-item" onClick={() => { history.push("/ManageUserRegistration");this.props.changePage("/ManageUserRegistration") }}>
                                        <div className="nav-link mt-2 handCursor " style={this.props.currentPage==='/ManageUserRegistration' ?{color:'white', backgroundColor:'#494e53'} : {}}>
                                            <i className="nav-icon fas fa-user-tie " />
                                            <p >
                                            {ts('Client Registration',this.props.userLogin.language  )}
                                            </p>
                                        </div>
                                    </li>
                                }
                                {type < 2 &&
                                    <li className="nav-item">
                                        <div className="nav-link mt-1 handCursor " style={this.props.currentPage==='/userManagement' ?{color:'white', backgroundColor:'#494e53'} : {}}
                                        onClick={() => { history.push("/userManagement") ;this.props.changePage("/userManagement")}}>

                                            <i className="nav-icon fas fa-user-shield " />
                                            <p >
                                            {ts('User Management',this.props.userLogin.language  )}
                                            </p>
                                        </div>
                                    </li>
                                }
                                {(type < 2 || type == 4) &&
                                    <li className="nav-item">
                                        <div className="nav-link mt-1 handCursor " style={this.props.currentPage==='/ProfileManagement' ?{color:'white', backgroundColor:'#494e53'} : {}}
                                         onClick={() => { history.push("/ProfileManagement");this.props.changePage("/ProfileManagement") }}>
                                            <i className="nav-icon fas fa-address-book " />
                                            <p >
                                            {ts('Customer Management',this.props.userLogin.language  )}
                                            </p>
                                        </div>
                                    </li>
                                }

                                {(type < 4) && <li className="nav-header mt-5">{ts('Campaigns',this.props.userLogin.language  )}</li>}
                                {(type < 3) && <li className="nav-item" onClick={() => { history.push("/CampaignsTemplates") ;this.props.changePage("/CampaignsTemplates")}}>
                                    <div className="nav-link  handCursor " style={this.props.currentPage==='/CampaignsTemplates' ?{color:'white', backgroundColor:'#494e53'} : {}}>

                                        <i className="nav-icon fas fa-envelope-open-text" />
                                        <p >
                                        {ts('Campaigns Templates',this.props.userLogin.language  )}
                                        </p>
                                    </div>
                                </li>}
                                {(type < 2 || type == 3) && <li className="nav-item">

                                    <div className="nav-link  handCursor "  style={this.props.currentPage==='/CustomerCampaigns' ?{color:'white', backgroundColor:'#494e53'} : {}}
                                    onClick={() => { history.push("/CustomerCampaigns")  ;this.props.changePage("/CustomerCampaigns")}}>
                                        <i className="nav-icon fas fa-mail-bulk" />
                                        <p >
                                        {ts('Campaigns',this.props.userLogin.language  )}
                                        </p>
                                    </div>
                                </li>}
                                {type < 2 && <li className="nav-item">
                                    <div className="nav-link  handCursor " style={this.props.currentPage==='/CampaignStatistics' ?{color:'white', backgroundColor:'#494e53'} : {}}
                                     onClick={() => { history.push("/CampaignStatistics");this.props.changePage("/CampaignStatistics") }}>

                                        <i className="nav-icon fas fa-tachometer-alt" />
                                        <p >
                                        {ts('Campaigns Statistics',this.props.userLogin.language  )}
                                        </p>
                                    </div>
                                </li>}
                                {type <2 &&
                                    <li className="nav-item" onClick={() => { history.push("/HotspotCategories") ;this.props.changePage("/HotspotCategories") }}>
                                        <div className="nav-link mt-2 handCursor " style={this.props.currentPage==='/HotspotCategories' ?{color:'white', backgroundColor:'#494e53'} : {}}>
                                            <i className="nav-icon fab fa-hubspot " />
                                            <p >
                                            {ts('Hotspot Categories',this.props.userLogin.language  )} 
                                            </p>
                                        </div>
                                    </li>
                                }

                                {(type < 2 || type == 4) && <li className="nav-header mt-5">{ts('Data Import/Export',this.props.userLogin.language  )}</li>}
                                {(type < 2 || type == 4) && <li className="nav-item" onClick={() => { history.push("/ImportProfiles");this.props.changePage("/ImportProfiles") }}>

                                    <div className="nav-link handCursor " style={this.props.currentPage==='/ImportProfiles' ?{color:'white', backgroundColor:'#494e53'} : {}}>
                                        <i className="nav-icon fas fa-file-import" />
                                        <p >
                                        {ts('Import Customers',this.props.userLogin.language  )}
                                        </p>
                                    </div>
                                </li>}
                                {(type < 2) && <li className="nav-item" onClick={() => { history.push("/ExportProfiles");this.props.changePage("/ExportProfiles") }}>
                                    <div className="nav-link handCursor " style={this.props.currentPage==='/ExportProfiles' ?{color:'white', backgroundColor:'#494e53'} : {}}>

                                        <i className="nav-icon fas fa-file-export" />
                                        <p >
                                        {ts('Export Customers',this.props.userLogin.language  )}
                                        </p>
                                    </div>
                                </li>}


                                <li className="nav-header mt-5 ">{ts('Log',this.props.userLogin.language  )}</li>
                                <li className="nav-item" onClick={() => { history.push("/LogHistory");this.props.changePage("/LogHistory") }}>
                                    <div className="nav-link handCursor " style={this.props.currentPage==='/LogHistory' ?{color:'white', backgroundColor:'#494e53'} : {}}>
                                        <i className="nav-icon fas fa-history" />
                                        <p >
                                        {ts('Log History',this.props.userLogin.language  )}
                                        </p>
                                    </div>
                                </li>
                                <li className="nav-header mt-5 ">{ts('Password',this.props.userLogin.language  )}</li>
                                <li className="nav-item" onClick={() => { history.push("/ResetPassword") ;this.props.changePage("/ResetPassword")}}>
                                    <div className="nav-link handCursor " style={this.props.currentPage==='/ResetPassword' ?{color:'white', backgroundColor:'#494e53'} : {}}>
                                    <i className="nav-icon fas fa-key"></i>
                                        <p >
                                        {ts('Change My Password',this.props.userLogin.language  )}
                                        </p>
                                    </div>
                                </li>
                                
                                <li className="nav-header mt-5 mb-5"> </li>
                            </ul>
                        </nav>
                        {/* /.sidebar-menu */}
                    </div>
                }
                {/* /.sidebar */}
            </aside>


        )
    }
}
const mapStateToProps = state => {

    return {
        userLogin: state.SaveLogin.userData,
    };
};
export default connect(
    mapStateToProps
)(Menu)
