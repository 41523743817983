import React, { Component } from 'react'
import { Badge, Button, Container, Form, Col, Spinner } from 'react-bootstrap';
import * as G from '../components/global';
import history from '../components/history'
import MessageBox from '../components/MessageBox';
import { translateScreen as ts , translateScreen2 as ts2 , translateDate as td} from '../components/dictionary'

//#region Redux
import { connect } from 'react-redux';
//#endregion

class ManageUserRegistration extends Component {
    constructor(props) {
        super(props);

        this.state = {
            allList: [],
            clientList: [],
            noneActiveClients: [],
            registeredList: [],
            activeList: 0,
            previousList: 0,
            selectedClient: {},
            fields: {},
            errors: {},
            result: [],
            actionType: '',
            id: -1,
            loadingData: true,
            loadingSave: false,
            modalShow: false,
            modalBody: '',
            modalSubject: ''

        }
        this.handleChange = this.handleChange.bind(this);
    }
    dateFormat = require("dateformat");
    componentDidMount() {

        this.refreshData()
        history.listen((location, action) => {
            if(location.pathname == "/ManageUserRegistration" || location.pathname == "/ManageUserRegistration/")
            this.setState({ activeList: this.state.previousList });
            
            
          });
    }
    refreshData = async () => {
        let result = await G.getData('clientregistrationrequest/query', [['filter', ' CreationDate is null']]);
        this.setState({ registeredList: await result.data })

        let result1 = await G.getData('client/query', []);
        let records = await result1.data
        console.log(records);
        if (records) {
            this.setState({ clientList: records.filter(record => { return record.active == 1 }), noneActiveClients: await records.filter(record => { return record.active == 0 }) })
        }

        this.setState({ loadingData: false })
    }

    registerUser = (item) => {
        history.push("/ManageUserRegistration/register");
        
        this.setState({ previousList: this.state.activeList, selectedClient: item, activeList: 3 })
        this.setState({ id: -1 })
        let newField = {};
        newField.registrationdate = this.dateFormat(item.addDate, 'dddd, mmm dd yyyy h:MM tt')
        newField.companyname = item.companyName;
        newField.contactperson = item.contactPerson;
        newField.phonenumber = item.contactPersonNo;
        newField.email = item.email;
        newField.username = item.email;
        newField.usertype = G.userType[1]
        newField.fullname = item.contactPerson;
        newField.password = '';
        newField.confirmpassword = '';
        newField.senderemail = item.senderEmail;
        console.log(newField);
        this.setState({ fields: newField })
        this.setState({ actionType: 'register' })

    }
    editUser = (item) => {
        history.push("/ManageUserRegistration/edit");
        
        this.setState({ previousList: this.state.activeList, selectedClient: item, activeList: 3 })

        this.setState({ id: item.id })
        let newField = {};
        newField.companyname = item.companyName;
        newField.registrationdate = this.dateFormat(item.addDate, 'dddd, mmm dd yyyy h:MM tt')
        newField.contactperson = item.contactPerson;
        newField.phonenumber = item.contactPersonNo;
        newField.email = item.email;
        newField.clientid = item.slug;
        newField.username = item.email;
        newField.usertype = G.userType[1]
        newField.fullname = item.contactPerson;
        newField.senderemail = item.senderEmail;
        console.log(newField);
        this.setState({ fields: newField })
        this.setState({ actionType: 'edit' })
    }

    activeButton = (text, badgeCounter, active, onClick, validateBadge = false) => {
        return (
            <Button variant={(active ? 'success ' : "light ") + "mr-2"} onClick={onClick}>
                <span className={'xtitle bold' + ' p-2 pl-0'}>{text}
                    <Badge variant={validateBadge ? badgeCounter === 0 ? "success" : 'warning' : 'success'} className='ml-2'>
                        {badgeCounter}
                    </Badge>
                </span>
            </Button>)
    }

    //#region Form Validation
    handleChange(e) {
        let { fields, errors } = this.state;
        fields[e.target.name] = e.target.value;
        delete errors[e.target.name];

        this.setState({ fields });
    }
    handleValidation = async () => {

        const { email, companyname, contactperson, phonenumber, usertype, password, confirmpassword, username, fullname, senderemail, clientid } = this.state.fields;
        let errors = {};
        let formIsValid = true;
        var regexp = /^[a-zA-Z0-9-_]+$/;
        if (!email) { formIsValid = false; errors["email"] = ts2("Email cannot be empty", this.props.userLogin.language);; } else {
            if (!G.isValideEmail(email)) { formIsValid = false; errors["email"] = ts2('This is not a valid Email', this.props.userLogin.language); }
        }
        if (!senderemail) { formIsValid = false; errors["senderemail"] =ts2("Email cannot be empty", this.props.userLogin.language); } else {
            if (!G.isValideEmail(senderemail)) { formIsValid = false; errors["senderemail"] = ts2('This is not a valid Email', this.props.userLogin.language); }
        }
        if (!companyname) { formIsValid = false; errors["companyname"] = ts2("Company name cannot be empty",this.props.userLogin.language); }
        if (!phonenumber) { formIsValid = false; errors["phonenumber"] = ts2("Phone number name cannot be empty",this.props.userLogin.language); }
        if (!contactperson) { formIsValid = false; errors["contactperson"] = ts2("Contact Person name cannot be empty",this.props.userLogin.language); }
        
        if (this.state.actionType !== 'edit') {
            //if (!clientid || clientid.search(regexp) === -1){formIsValid = false; errors["clientid"] = ts2("This client ID can be only alpha-numeric, and not spaces",this.props.userLogin.language);}
            if (!username) { formIsValid = false; errors["username"] = ts2("Username cannot be empty",this.props.userLogin.language); }
            if (!fullname) { formIsValid = false; errors["fullname"] = ts2("Full name cannot be empty",this.props.userLogin.language); }
            if (!password) { formIsValid = false; errors["password"] = ts2("Password must be set",this.props.userLogin.language); } else {
                if (password.length < 8) { formIsValid = false; errors["password"] = ts2("Password is too short",this.props.userLogin.language); }
                else {
                    if (!confirmpassword) { formIsValid = false; errors["confirmpassword"] = ts2("Password does not match",this.props.userLogin.language); } else {
                        if (confirmpassword !== password) { formIsValid = false; errors["confirmpassword"] = ts2("Passwords does not match",this.props.userLogin.language); };

                    }
                }
            }
        }

        this.setState({ errors: errors });

        //const { registrationRequestID, companyName, contactPerson, contactPersonNo, email, addDate } = req.body
        //const { username, password, fullName, accountType, creationUser } = req.body
        let parameters = []
        if (this.state.actionType === 'edit') {
            parameters = [
                ['id', this.state.id],
                ['companyName', companyname],
                ['contactPerson', contactperson],
                ['contactPersonNo', phonenumber],
                ['email', email],
                ['senderemail', senderemail],
            ]
        } else {
            parameters = [
                ['registrationRequestID', this.state.selectedClient.serial],
                ['companyName', companyname],
                ['contactPerson', contactperson],
                ['contactPersonNo', phonenumber],
                ['email', email],
                ['senderemail', senderemail],
                ['addDate', this.state.selectedClient.addDate.slice(0, 19).replace('T', ' ')],
                ['username', username],
                ['password', password],
                ['fullName', fullname],
                ['accountType', 1],
                ['creationUser', this.props.userLogin.id],
                ['slug',clientid]
            ]
        }


        if (formIsValid) {
            G.log('Form is valid. pushing now values...')
            var result = await G.getData('registerClient', parameters)
            if (result.status === 601) {

                errors['username'] = "Username already available!"
                this.setState({ errors: errors });
            }
            return (result.status === 200)
        }
        else {
            return formIsValid;
        }
    }
    submitForm = async (e) => {
        e.preventDefault();

        this.setState({ loadingSave: true })
        G.log('Try to submit form')
        var a = await this.handleValidation();

        G.log('submit form is', a)
        if (a === true) {
            this.refreshData()
            this.setState({ activeList: this.state.previousList })

        } else { G.log('Form Is Not Valid') }
        this.setState({ loadingSave: false })
    }
    changeActivation = async () => {

        this.setState({
            modalShow: true,
            modalSubject: ts2(this.state.selectedClient.active == 1 ? 'Activate Client' : 'Deactivate Client',this.props.userLogin.language),
            modalBody: ts2("Are you sure you want to ",this.props.userLogin.language) + ts2( (this.state.selectedClient.active == 1 ? 'Activate Client' : 'Deactivate Client'),this.props.userLogin.language) + "?"
        })
    }
    applyChangeActivation = async () => {
        this.setState({ loadingSaveActivate: true })
        let parameters = [
            ['id', this.state.id],
            ['status', this.state.selectedClient.active == 1 ? 0 : 1]
        ]
        var result = await G.getData('ChangeClientStatus', parameters)
        if (result.status === 200) {
            this.refreshData()
            this.setState({ activeList: this.state.previousList, modalShow: false })

        }
        this.setState({ loadingSaveActivate: false })
    }
    //#endregion
    render() {
        const { errors, fields, actionType } = this.state;
        return (
            <Container fluid className='pt-5 flex center  ' >

                {this.state.activeList < 3 &&
                    <Container fluid >
                        <h4 className='mb-5 mt-4'>
                        {ts2(this.state.activeList === 0 ? 'List of unregistered clients' : this.state.activeList === 1 ? "List of registered clients" : 'List Of inactive Clients',this.props.userLogin.language)}
                            </h4>

                        <div className='flexrow mb-5'>
                            {this.activeButton(ts2('Unregistered Clients',this.props.userLogin.language),
                                this.state.registeredList.length,
                                this.state.activeList === 0,
                                () => { this.setState({ activeList: 0 }) }, true)}
                            {this.activeButton(ts2('Registered Clients',this.props.userLogin.language),
                                this.state.clientList.length,
                                this.state.activeList === 1, () => { this.setState({ activeList: 1 }) })}
                            {this.activeButton(ts2('None Active Clients',this.props.userLogin.language),
                                this.state.noneActiveClients.length,
                                this.state.activeList === 2, () => { this.setState({ activeList: 2 }) })}
                        </div>



                        <div className="table-responsive p-0" style={{ maxHeight: 900 }}>



                            <table className="table table-head-fixed text-nowrap table-hover ">
                                <thead>
                                    <tr>
                                        <th>Action</th>
                                        <th>ID</th>
                                         <th>{ts('Client ID', this.props.userLogin.language)}</th>
                                        <th>{ts('Client Name', this.props.userLogin.language)}</th>
                                        <th>{ts('Contact Person', this.props.userLogin.language)}</th>
                                        <th>{ts('Phone', this.props.userLogin.language)}</th>
                                        <th>{ts('Email Address', this.props.userLogin.language)}</th>
                                         <th>{ts2('Request Date',this.props.userLogin.language)}</th>
                                        {this.state.activeList !== 0 && <th>{ts2('Registration Date',this.props.userLogin.language)}</th>}


                                    </tr>
                                </thead>

                                {!this.state.loadingData &&
                                    <tbody>
                                        {
                                            (this.state.activeList === 0 ? this.state.registeredList : this.state.activeList === 1 ? this.state.clientList : this.state.noneActiveClients).map((item) => {

                                                return (<tr key={item.serial}>
                                                    <td className='xxxtitle shiteColor handCursor' onClick={() => { item.creationDate == null ? this.registerUser(item) : this.editUser(item) }}>
                                                        <Badge variant='info'>{ ts2(item.creationDate == null ? 'Register User' : 'Edit User',this.props.userLogin.language)}</Badge>
                                                    </td>
                                                    <td>{item.creationDate == null ? item.serial : item.id}</td>
                                                    <td>{item.slug}</td>
                                                    <td>{item.companyName}</td>
                                                    <td>{item.contactPerson}</td>
                                                    <td>{item.contactPersonNo}</td>
                                                    <td>{item.email}</td>
                                                    <td>{td(this.dateFormat(item.addDate, 'dddd, mmm dd yyyy h:MM tt'),this.props.userLogin.language)}</td>
                                                    {this.state.activeList !== 0 && <td>{ts2(item.creationDate == null ? 'Not Registred' : '',this.props.userLogin.language) + td(this.dateFormat(item.creationDate, 'dddd, mmm dd yyyy h:MM tt'),this.props.userLogin.language)}</td>}

                                                </tr>)
                                            })
                                        }

                                    </tbody>
                                }

                            </table>
                            {!this.state.loadingData &&


                                (this.state.activeList === 0 ? (this.state.registeredList.length > 0 ?
                                    '' :
                                    <div className='flexcolumn text-center mt-5 pt-5 ssstitle hint'><p className='sstitle'><i class="far fa-frown-open"></i></p>{ts2('No Registration Available',this.props.userLogin.language)}</div>
                                )
                                    : this.state.activeList === 1 ? ((this.state.clientList.length > 0 ? "" :
                                        <div className='flexcolumn text-center mt-5 pt-5 ssstitle hint'><p className='sstitle'><i class="far fa-frown-open"></i></p>{ts2('No Clients Available',this.props.userLogin.language)}</div>)) :
                                        ((this.state.noneActiveClients.length > 0 ? "" :
                                            <div className='flexcolumn text-center mt-5 pt-5 ssstitle hint'><p className='sstitle'><i class="far fa-frown-open"></i></p>{ts2('No Deactivated Clients Are Available',this.props.userLogin.language)}</div>))
                                )


                            }
                            {this.state.loadingData &&
                                <div className='flexcolumn center verticalcenter p-5 fullWidth hint '>
                                    {ts2('Loading Clients Info',this.props.userLogin.language)}
                                    <Spinner animation="border" className='mt-3' variant="warning" />

                                </div>
                            }
                        </div>
                    </Container>}


                {this.state.activeList === 3 && this.state.selectedClient &&
                    <Container fluid className='pt-3  ' >
                        <div className='flexrow start pb-5 '>
                            <Button variant='warning' onClick={() => { this.setState({ activeList: this.state.previousList }); history.go(-1);}}>{ts('Cancel / Return',this.props.userLogin.language  )}</Button>
                        </div>
                        <h4 className='mb-5 fullWidth'>{actionType === 'edit' ? ts2(' Edit client info of ',this.props.userLogin.language) : ts2(' Register client ',this.props.userLogin.language)}
                            <span className='bold themeColor '>{this.state.selectedClient.companyName}</span></h4>

                        <Form name="form" className='pt-5 fullWidth' onSubmit={this.submitForm.bind(this)} >
                            <h4 className='mb-5 pageDivision'>{ts2('Company Information',this.props.userLogin.language)}</h4>
                            <Form.Row>
                                <Col>
                                    {G.renderTextField("Registration Date", 'text', "", this.handleChange, fields, errors, null, '', true,this.props.userLogin.language)}
                                </Col>

                            </Form.Row>

                            <Form.Row>
                                <Col>
                                    {G.renderTextField("Company Name", 'text', ts2( "Enter your company name",this.props.userLogin.language), this.handleChange, fields, errors, null,'', false,this.props.userLogin.language)}
                                </Col>

                            </Form.Row>
                            <Form.Row>
                                <Col>
                                    {G.renderTextField("Client ID", 'text', ts2( "Your client id will be generated automatically",this.props.userLogin.language), this.handleChange, fields, errors, null,'',true,this.props.userLogin.language)}
                                </Col>

                            </Form.Row>
                            <Form.Row>
                                <Col>
                                    {G.renderTextField("Contact Person", 'text',  ts2("Enter your contact person",this.props.userLogin.language), this.handleChange, fields, errors, null,'', false,this.props.userLogin.language)}
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col>
                                    {G.renderTextField("Phone Number", 'text',  ts2("Enter your mobile/Phone number",this.props.userLogin.language), this.handleChange, fields, errors, null,'', false,this.props.userLogin.language)}
                                </Col>
                                <Col>
                                    {G.renderTextField("Email", 'email',  ts2("Enter email address",this.props.userLogin.language), this.handleChange, fields, errors, null,'', false,this.props.userLogin.language)}
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col>
                                    {G.renderTextField("Sender Email", 'email',  ts2("Enter your email that will be used as sender Email",this.props.userLogin.language), this.handleChange, fields, errors, null,'', false,this.props.userLogin.language)}
                                </Col>
                               
                            </Form.Row>
                            {this.state.actionType !== 'edit' &&
                                <div>
                                    <h4 className='mb-5 pageDivision mt-5'>{ts2('Admin User Setup',this.props.userLogin.language)}</h4>


                                    <Form.Row>
                                        <Col>
                                            {G.renderTextField("Username", 'email',  ts2("Enter customer registered email",this.props.userLogin.language), this.handleChange, fields, errors, null,'', false,this.props.userLogin.language)}
                                        </Col>

                                    </Form.Row>
                                    <Form.Row>
                                        <Col>
                                            {G.renderTextField("Full Name", 'text',  ts2("Enter user full name",this.props.userLogin.language), this.handleChange, fields, errors, null,'', false,this.props.userLogin.language)}
                                        </Col>

                                    </Form.Row>


                                    {G.renderDropDown('User Type', G.userType.filter(value => value !== G.userType[0]), this.handleChange, fields, errors, ts2('Select from list',this.props.userLogin.language), true,this.props.userLogin.language)}


                                    <Form.Row>
                                        <Col>
                                            {G.renderTextField("Password", 'password',  ts2("Enter default username Password",this.props.userLogin.language), this.handleChange, fields, errors, '(Minimum 8 characters)','',false,this.props.userLogin.language)}
                                        </Col>
                                        <Col>
                                            {G.renderTextField("Confirm Password", 'password',  ts2("Confirm default Password",this.props.userLogin.language), this.handleChange, fields, errors, '','',false,this.props.userLogin.language)}
                                        </Col>
                                    </Form.Row>
                                </div>
                            }
                            {
                                this.state.actionType === 'edit' &&
                                <div>{ts2('Users can be edited in the',this.props.userLogin.language)} <span onClick={() => { history.push('/UserManagement') }} className='link'>{ts2('Users Management Screen',this.props.userLogin.language)}</span>.  </div>
                            }

                            <div className='flexrow pt-5 '>
                                <Button variant='success' type='submit' className='mr-3 '>
                                    {this.state.loadingSave && <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />}
                                    {ts2(this.state.actionType === 'edit' ? 'Save Changes' : 'Register Client',this.props.userLogin.language)}

                                </Button>
                                <Button variant='warning' className='mr-3' onClick={() => { this.setState({ activeList: this.state.previousList }); }}>{ts('Cancel / Return',this.props.userLogin.language  )}</Button>
                                {this.state.actionType === 'edit' && <Button variant={this.state.selectedClient.active == 0 ? 'info' : 'danger'} onClick={() => { this.changeActivation() }}>{ts2(this.state.selectedClient.active == 1 ? 'Deactivate Account' : 'Activate Account',this.props.userLogin.language)}</Button>}

                            </div>

                        </Form>



                    </Container>


                }
                <MessageBox
                    show={this.state.modalShow}
                    title={this.state.modalSubject}
                    body={this.state.modalBody}
                    onHide={() => { this.setState({ modalShow: false }) }}
                    middle={{ action: () => { this.applyChangeActivation() }, text: ts2(this.state.selectedClient.active == 1 ? 'Deactivate Client' : 'Activate Client',this.props.userLogin.language), variant: this.state.selectedClient.active == 0 ? 'info' : 'danger' }}
                    right={{ action: () => { this.setState({ modalShow: false }) }, text: ts('Cancel',this.props.userLogin.language), variant: 'success' }}


                />
            </Container>
        )
    }
}
const mapStateToProps = state => {
    return {
        userLogin: state.SaveLogin.userData,
    };
};

export default connect(
    mapStateToProps
)(ManageUserRegistration);